import React, {useState} from 'react';

import {APPROVAL_STATUS} from './contants';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import ModalNoteReject from './ModalNoteReject';
import ModalReopen from './ModalReopen';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';

function ApprovalAction({
  value,
  onApproveStep,
  effValue,
  steps,
  column,
  approvals,
  createdBy,
  members,
  item,
  currentStepItem,
  itemValue,
}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalReopen, setShowModalReopen] = useState(false);
  const [allowEnd, setAllowEnd] = useState(false);
  const createBy = item?.created_by?.value;
  const approval = effValue?.find?.((approval) => approval?.assignee_role === value?.assignee_role);
  const assigneeRoleUser = column?.assignee_role_users?.find(
    (approval) => approval?.id === value?.assignee_role
  );

  const handleApprove = () => {
    setShowModalApprove(false);
    const params = {
      name: 'approve_step',
      stepId: value?.id,
    };

    onApproveStep(params);
  };

  const handleApproveAndEnd = () => {
    setShowModalApprove(false);
    const params = {
      name: 'approve_and_end_step',
      stepId: value?.id,
    };

    onApproveStep(params);
  };

  const handleRejected = (note) => {
    const params = {
      name: 'reject_step',
      note: note,
      stepId: value?.id,
    };
    onApproveStep(params);
    setShowModalReject(false);
  };

  const handleReopen = (stepId, note) => {
    const params = {
      name: 'reopen_step',
      stepId: stepId,
      note: note,
    };
    onApproveStep(params);
    setShowModalReopen(false);
  };

  const hasAllowApprove = () => {
    let isUserInColumnPermitted = false;

    let member = approval?.assignees?.find((member) => {
      if (member?.type === 'team') {
        let userTeams = user?.teams ?? [];
        return userTeams.find((team) => team?.id === member?.id);
      } else {
        return member?.id === user?._id;
      }
    });

    for (let i = 0; i < assigneeRoleUser?.columns?.length; i++) {
      const column = assigneeRoleUser.columns?.[i];
      const listPeople = item?.[column?.value]?.value;
      if (listPeople) {
        isUserInColumnPermitted = listPeople.find((people) => people?.id === user?.id);
        break;
      }
    }

    const isUserCreate = createBy?._id === user?._id && !itemValue?.steps;
    if (member || isUserCreate || isUserInColumnPermitted) {
      return true;
    }
    return false;
  };

  if (itemValue?.value?.length > 1 && approval?.status === APPROVAL_STATUS.APPROVED) {
    return (
      <div className='d-flex align-items-center'>
        <div className='bg-success w-100px me-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_approve')}
        </div>
      </div>
    );
  }

  if (!hasAllowApprove() && itemValue?.status === APPROVAL_STATUS.PENDING && !value?.actionReject) {
    return (
      <div className='d-flex align-items-center'>
        <div className='bg-warning w-100px me-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_pending_process')}
        </div>
      </div>
    );
  }

  if (!hasAllowApprove() && itemValue?.status === APPROVAL_STATUS.PENDING) {
    return (
      <div className='d-flex align-items-center'>
        <div className='bg-warning w-100px me-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_pending')}
        </div>
      </div>
    );
  }

  if (itemValue?.status === APPROVAL_STATUS.REJECTED) {
    return (
      <div className='d-flex align-items-center'>
        <div className='bg-danger w-100px me-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_rejected')}
        </div>
      </div>
    );
  }

  if (
    (!hasAllowApprove() ||
      itemValue?.status === APPROVAL_STATUS.APPROVED ||
      itemValue?.status === APPROVAL_STATUS.REJECTED ||
      !steps?.length) &&
    itemValue?.status !== APPROVAL_STATUS.REOPEN
  ) {
    return (
      <div className='d-flex align-items-center'>
        <div className='bg-success w-100px me-1 p-1 rounded text-white'>
          {t('sheet_table_column_approval_approve')}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        {!itemValue?.steps?.length && value.actionCreateApproval && (
          <div
            className='bg-primary w-100px ms-1 p-1 rounded text-white cursor-pointer'
            onClick={handleApprove}
          >
            {value?.titleActionCreateApproval || t('sheet_table_column_approval_create_approve')}
          </div>
        )}
        {itemValue?.steps?.length ? (
          <>
            {value?.actionReject && (
              <div
                className='bg-secondary w-80px me-1 p-1 rounded cursor-pointer'
                onClick={() => setShowModalReject(true)}
              >
                {value.titleActionReject || t('sheet_table_column_approval_reject')}
              </div>
            )}
            {value?.actionReopen && (
              <div
                className='bg-warning w-80px ms-1 p-1 rounded text-white cursor-pointer'
                onClick={() => setShowModalReopen(true)}
              >
                {value.titleActionReopen || t('sheet_table_column_approval_reopen')}
              </div>
            )}
            {value?.actionApprove && (
              <div
                className='bg-primary w-80px ms-1 p-1 rounded text-white cursor-pointer'
                onClick={() => {
                  if (value?.id === column?.allowEnd) {
                    setShowModalApprove(true);
                  } else {
                    handleApprove();
                  }
                }}
              >
                {value.titleActionApprove || t('sheet_table_column_approval_approve_action')}
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
      <ModalConfirm
        show={showModalApprove}
        onHide={() => setShowModalApprove(false)}
        title={t('sheet_table_column_approval_approve_title')}
        handleExcute={() => {
          if (allowEnd) {
            handleApproveAndEnd();
          } else {
            handleApprove();
          }
        }}
        children={
          <>
            <div>{t('sheet_table_column_approval_approve_content')}</div>
            <div className='mt-2'>
              <label className={`checkbox checkbox-lg checkbox-single`}>
                <input type='radio' checked={allowEnd} onChange={() => setAllowEnd(true)} />
                <span />
                &nbsp;{t('sheet_table_column_approval_approve_alow_end')}
              </label>
            </div>
            <div className='mt-2'>
              <label className={`checkbox checkbox-lg checkbox-single`}>
                <input type='radio' checked={!allowEnd} onChange={() => setAllowEnd(false)} />
                <span />
                &nbsp;{t('sheet_table_column_approval_approve_next_step')}
              </label>
            </div>
          </>
        }
      />
      <ModalNoteReject
        show={showModalReject}
        onHide={() => setShowModalReject(false)}
        handleExcute={handleRejected}
      />
      {showModalReopen && (
        <ModalReopen
          show={showModalReopen}
          onHide={() => setShowModalReopen(false)}
          steps={steps}
          step={value}
          stepsValue={itemValue?.steps}
          handleExcute={handleReopen}
        />
      )}
    </>
  );
}

export default ApprovalAction;
