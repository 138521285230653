import './styles.scss';

import {effectiveValue} from 'app/modules/work/helpers';
import {RichText} from 'app/common/_partials/controls';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {stripTag} from 'app/common/_helpers';

function CustomAIPromptField({
  value,
  item,
  column,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
  mode = 'inline',
}) {
  const {t} = useTranslate();
  const effValue = effectiveValue(value);
  const field = column?.field;

  const [content, setContent] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [inlineContent, setInlineContent] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const onInputFocus = () => {
    setIsFocus(true);
  };

  useEffect(() => {
    setContent(effValue);
    setInlineContent(effValue?.replace(/<\/?[^>]+(>|$)/g, '') || '');
  }, [value]);

  const dialogRef = useRef(null);

  const onBlur = () => {
    setIsFocus(false);
  };

  const saveContent = () => {
    if (content !== effValue) {
      onChange && onChange(item, field, effValue, {value: content, is_manual_edited: !!stripTag(content)});
    }
    setShowDialog(false);
  };

  const onHide = () => {
    setShowDialog(false);
    setContent(effValue);
  };

  const onToggle = () => {
    setShowDialog(!showDialog);
  };

  const popover = () => <div className='border-bottom w-400px'>{renderEditor()}</div>;
  const renderEditor = () => {
    if (fieldEditable && !readOnly) {
      return (
        <div
          className={`w-100 long-richtext-field-input ${
            isFocus ? 'long-richtext-field-input-active' : 'long-richtext-field-input-inactive'
          }`}
        >
          <RichText
            defaultValue={content}
            onBlur={onBlur}
            onChange={setContent}
            className='w-100'
            onFocus={onInputFocus}
          />
        </div>
      );
    }
    return <div className={'custom-ai-prompt-field'} dangerouslySetInnerHTML={{__html: content}} />;
  };

  if (mode === 'detail' && fieldEditable && !readOnly) {
    return renderEditor();
  }

  return (
    <>
      {mode === 'inline' && (
        <>
          <Modal show={showDialog} onHide={onHide} centered size={'xl'}>
            <Modal.Header closeButton>
              <Modal.Title>{column?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderEditor()}</Modal.Body>
            <Modal.Footer>
              <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                {t('common_cancel')}
              </button>
              {fieldEditable && !readOnly && (
                <button type='submit' className='btn btn-primary btn-elevate' onClick={saveContent}>
                  {t('common_save')}
                </button>
              )}
            </Modal.Footer>
          </Modal>

          <div className='custom-ai-prompt-field-container'>
            <DropdownPopover
              onToggle={saveContent}
              renderPopover={popover}
              ref={dialogRef}
              triggerClassName='overflow-hidden ps-1 custom-ai-prompt-field-container--content'
            >
              {inlineContent}
            </DropdownPopover>

            <span className='icon-expand me-2 cursor-pointer' onClick={onToggle}></span>
          </div>
        </>
      )}
    </>
  );
}

export default CustomAIPromptField;
