import React from 'react';
import TextField from './text/TextField';
import './itemFormField.scss';
import ColorField from './color/ColorField';
import DateField from './date/DateField';
import NumberField from './number/NumberField';
import LinkField from './link/LinkField';
import TimeLineField from './timeline/TimeLineField';
import FileField from './file/FileField';
import clsx from 'clsx';
import LongTextField from './longText/LongTextField';
import DependencyField from './dependency/DependencyField';
import DepartmentField from './department/DepartmentField';
import FormulaField from './formula/FormulaField';
import {ApprovalField} from './approval/ApprovalField';
import GeoLocationField from './geoLocation/GeoLocationField';
import PeopleField from './people/PeopleField';
import CustomAIPromptField from './custom-ai-prompt/CustomAIPromptField';
import ReferenceField from './reference/ReferenceField';

function ItemFormField({
  column,
  value,
  onChange,
  mode = 'edit',
  hiddenColumns = [],
  sheet,
  fieldEditable,
}) {
  const type = column?.type ?? '';
  const field = column?.field ?? '';

  const renderField = () => {
    if (type === 'text') {
      return (
        <div className='form-field-item shadow-field'>
          <TextField
            column={column}
            onChange={onChange}
            value={value}
            fieldEditable={fieldEditable}
          />
        </div>
      );
    }
    if (type === 'status' || type === 'color' || type === 'priority') {
      return (
        <div className='form-field-item shadow-field form-field-item-color'>
          <ColorField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'date') {
      return (
        <div className='form-field-item shadow-field display-flex justify-content-center'>
          <DateField
            column={column}
            onChange={onChange}
            value={value}
            fieldEditable={fieldEditable}
            placeholderText={'dd-mm-yyyy'}
            containerClassName={`w-100`}
          />
        </div>
      );
    }
    if (type === 'number') {
      return (
        <div className='form-field-item shadow-field'>
          <NumberField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'link') {
      return (
        <div className='form-field-item shadow-field'>
          <LinkField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'timeline') {
      return (
        <div className='shadow-field'>
          <TimeLineField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'file') {
      return (
        <div className='form-field-item shadow-field'>
          <FileField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'longtext') {
      return (
        <div className='form-field-item shadow-field bg-body'>
          <LongTextField
            column={column}
            onChange={onChange}
            value={value}
            fieldEditable={fieldEditable}
            mode='detail'
          />
        </div>
      );
    }
    if (type === 'dependency') {
      return (
        <div className='form-field-item shadow-field'>
          <DependencyField
            sheet={sheet}
            value={value}
            column={column}
            onChange={onChange}
            fieldEditable={fieldEditable}
          />
        </div>
      );
    }
    if (type === 'department') {
      return (
        <div className='form-field-item shadow-field'>
          <DepartmentField value={value} column={column} onChange={onChange} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'formula') {
      return (
        <div className='form-field-item shadow-field'>
          <FormulaField value={value} column={column} onChange={onChange} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'approval') {
      return (
        <div className='form-field-item shadow-field'>
          <ApprovalField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'geolocation') {
      return (
        <div className='form-field-item shadow-field'>
          <GeoLocationField
            column={column}
            onChange={onChange}
            value={value}
            fieldEditable={fieldEditable}
            enableDetectGeoLocation={true}
          />
        </div>
      );
    }
    if (type === 'people') {
      return (
        <div className='form-field-item shadow-field'>
          <PeopleField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
    if (type === 'custom_ai_prompt') {
      return (
        <div className='form-field-item shadow-field'>
          <CustomAIPromptField
            column={column}
            onChange={onChange}
            value={value}
            fieldEditable={fieldEditable}
          />
        </div>
      );
    }
    if (type === 'reference') {
      return (
        <div className='form-field-item shadow-field'>
          <ReferenceField column={column} onChange={onChange} value={value} fieldEditable={fieldEditable} />
        </div>
      );
    }
  };

  if (hiddenColumns.includes(type) || field === 'complete') {
    return null;
  }

  return (
    <div>
      <div className={`form-field-container ${clsx({edit: mode === 'edit'})}`}>
        <div className='label-form-field  py-2'>
          <input
            type='text'
            className='w-100 label-form-field-input fw-bold text-capitalize'
            value={column?.name}
            readOnly={mode === 'view'}
          />
        </div>
        {renderField()}
      </div>
    </div>
  );
}

export default ItemFormField;
