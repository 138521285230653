import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {Modal} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {shallowEqual, useSelector} from 'react-redux';

import PageAddForm from './PageAddForm';
import {ModalProgressBar} from 'app/common/_partials/controls';
import {useEffect} from 'react';
import {boardType, subType as newBoardSubType} from '../constant';

function PageAddDialog({
  initialParentId,
  type,
  subType,
  workspace,
  show,
  onHide,
  savePage,
  onSaveSuccess,
  options
}) {
  const intl = useIntl();
  const snackNotification = useSnackNotification();

  const actionsLoading = useSelector((state) => state?.work?.actionsLoading, shallowEqual);

  let initPage = {
    name: '',
    code: '',
    type,
    parent: initialParentId || '',
    workspace: workspace,
    confidential: 'allow_search',
    privacy: 'private',
  };

  useEffect(() => {
    if (type === 'sheet') {
      initPage.terminology = 'task';
    }
    if (type === boardType.DOC) {
      initPage.privacy = 'public';
    }
  }, [type]);

  const handleSavePage = (page) => {
    if (!actionsLoading) {
      if (subType === newBoardSubType.DOC_BLOCK) {
        page.docBlock = true;
      }
      savePage({
        page,
        onSuccess: (res) => {
          snackNotification.showSuccess(intl.formatMessage({id: 'common_save_success'}));
          onHide && onHide();
          onSaveSuccess && onSaveSuccess(res);
        },
        onError: (message) => {
          snackNotification.showError(
            intl.formatMessage({id: 'common_save_error'}) + ': ' + intl.formatMessage({id: message})
          );
        },
      });
    }
  };

  return (
    <>
      <Modal size='lg' show={show} onHide={onHide} aria-labelledby='example-modal-sizes-title-lg'>
        {actionsLoading && <ModalProgressBar />}
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            {intl.formatMessage({id: 'page_edit_add'})}
          </Modal.Title>
        </Modal.Header>
        <PageAddForm savePage={handleSavePage} initPage={initPage} onHide={onHide} options={options}/>
      </Modal>
    </>
  );
}

export default PageAddDialog;
