import React, {useState} from 'react';
import ItemDetailDrawer from './ItemDetailDrawer';
import ItemDetailModal from './ItemDetailModal';

const ItemDetailView = ({
  key,
  open,
  item,
  view,
  sheet,
  permissions,
  onHide,
  onEditItem,
  onDelete,
  onSaveItemAction,
  onUpdateItem,
  onMoveItem,
  readOnly,
  allowChangeGroup,
  defaultDisplayMode = 'drawer',
}) => {
  const [displayMode, setDisplayMode] = useState(defaultDisplayMode);
  const onToggleDisplayMode = () => {
    if (displayMode === 'drawer') {
      setDisplayMode('modal');
    } else {
      setDisplayMode('drawer');
    }
  };

  if (displayMode === 'modal') {
    return (
      <ItemDetailModal
        open={open}
        item={item}
        onEditItem={onEditItem}
        sheet={sheet}
        view={view}
        onHide={onHide}
        onDelete={onDelete}
        onUpdateItem={onUpdateItem}
        onSaveItemAction={onSaveItemAction}
        onMoveItem={onMoveItem}
        allowChangeGroup={allowChangeGroup}
        readOnly={readOnly}
        permissions={permissions}
        onToggleDisplayMode={onToggleDisplayMode}
      />
    );
  }
  return (
    <ItemDetailDrawer
      open={open}
      item={item}
      onEditItem={onEditItem}
      sheet={sheet}
      view={view}
      onHide={onHide}
      onDelete={onDelete}
      onUpdateItem={onUpdateItem}
      onSaveItemAction={onSaveItemAction}
      onMoveItem={onMoveItem}
      allowChangeGroup={allowChangeGroup}
      readOnly={readOnly}
      permissions={permissions}
      onToggleDisplayMode={onToggleDisplayMode}
    />
  );
};

export default ItemDetailView;
