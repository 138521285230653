import React from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import FormulaField from './FormulaField';

const CustomAIPrompt = ({className, column, metaColumns}) => {
  const {t} = useTranslate();
  const promptPreview = t('sheet_edit_column_no_preview');
  const referenceColumns = metaColumns.filter(
    (item) => !item?.hidden && !item?.deleted && column.field !== item?.field
  );

  return (
    <div className={className}>
      <div className='form-group row mb-2 '>
        <div className='d-flex align-items-center justify-content-end col-3'>
          <label className='mb-2'>{t('sheet_edit_column_custom_ai_prompt_template')}</label>
        </div>
        <div class='col-md-9'>
          <FormulaField referenceColumns={referenceColumns} name='prompt_template' />
        </div>
      </div>
    </div>
  );
};

export default CustomAIPrompt;
