import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import React, {useEffect, useMemo, useState} from 'react';
import './dependencyField.scss';
import {useDispatch} from 'react-redux';
import {useSession} from 'core/store/hooks';
import {useSheetViewData} from '../../view/SheetViewHook';
import {cloneDeep, debounce} from 'lodash';
import {getSheetItemsAction} from 'app/modules/work/sheet/stores/sheetActions';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
import {useTranslate} from 'core/i18n/i18nProvider';
function DependencyField({
  value,
  item,
  column,
  sheet,
  onChange,
  fieldEditable,
  readOnly,
  containerClass,
}) {
  const {t} = useTranslate();
  const field = column?.field;
  const groups = useMemo(() => {
    if (!sheet) return [];
    return [...sheet?.groups, {title: t('sheet_empty_group'), id: 'empty'}];
  }, [sheet?.groups]);
  const dispatch = useDispatch();
  const {user} = useSession();
  const sheetId = useMemo(() => sheet?._id, [sheet]);
  const [expandedOnce, setExpandedOnce] = useState(false);
  const [listDependency, setListDependency] = useState([]);
  const [listDependencyId, setListDependencyId] = useState([]);
  const [sheetItemList, setSheetItemList] = useState([]);

  const viewData = useSheetViewData({user: user});

  //Prevent loading data too much, only load after the first toggle
  useEffect(() => {
    if (expandedOnce) {
      const listDependencyId = {};
      cloneDeep(listDependency).forEach((dependency) => {
        listDependencyId[dependency.id] = dependency;
      });
      setListDependencyId(listDependencyId);
      searchValue('');
    }
  }, [expandedOnce]);

  const searchValue = (e) => {
    const searchText = e?.target?.value || '';
    viewData.applySearch(searchText);
  };

  const selectValue = (value) => {
    if (!fieldEditable) {
      return;
    }
    if (Object.keys(listDependencyId).includes(value?._id)) {
      const newListDependencyId = {};
      const newListDependency = cloneDeep(listDependency).filter((dependency) => {
        if (dependency.id !== value?._id) {
          newListDependencyId[dependency.id] = dependency;
          return true;
        } else {
          return false;
        }
      });
      setListDependencyId(newListDependencyId);
      setListDependency(newListDependency);
      onChange && onChange(item, field, value, newListDependency);
      return;
    }

    const newListDependency = cloneDeep(listDependency) || [];
    const newListDependencyId = cloneDeep(listDependencyId);
    const params = {
      id: value._id,
      title: effectiveValue(value.title),
      type: 'finish-start',
      offset: 0,
    };
    newListDependency.push(params);
    newListDependencyId[params.id] = params;
    setListDependencyId(newListDependencyId);
    setListDependency(newListDependency);
    onChange && onChange(item, field, value, newListDependency);
  };

  const selectType = (e, item) => {
    if (!fieldEditable) {
      return;
    }
    const value = e.target.value;
    const newListDependency = cloneDeep(listDependency).map((dependency) => {
      if (item._id === dependency.id) {
        return {
          ...dependency,
          type: value,
        };
      }
      return dependency;
    });
    setListDependency(newListDependency);
    onChange && onChange(item, field, value, newListDependency);
  };

  useEffect(() => {
    if (expandedOnce) {
      const fetchParams = {
        id: sheetId,
        paging: {pageSize: 1000, pageNumber: 1},
      };
      dispatch(getSheetItemsAction(fetchParams)).then((result) => {
        if (result?.data?.data) {
          const items = result.data.data;
          setSheetItemList(items);
        }
      });
    }
  }, [dispatch, sheetId, expandedOnce]);

  useEffect(() => {
    setListDependency(effectiveValue(value) || []);
  }, [value]);

  useEffect(() => {
    viewData.setItems(sheetItemList);
  }, [sheetItemList]);

  const popover = () => {
    return (
      <div className='m-4 w-400px'>
        <div className='mb-2 d-flex'>
          <input
            type='text'
            placeholder='Tìm kiếm'
            className='form-control form-control-xs flex-gitem flex-shrink'
            onChange={debounce(searchValue, 500)}
          ></input>
        </div>
        <hr className='border-start-1 mt-1' />
        {groups?.map((group, index) => {
          return (
            <div key={index}>
              <div
                key={index}
                style={{color: group?.settings?.color || '#3699FF'}}
                className='fw-bold mb-2'
              >
                {group?.title}
              </div>
              <div>
                {viewData?.viewItemsByGroups?.[group?.id]?.map((itemByGroup, index) => {
                  if (item?._id !== itemByGroup?._id) {
                    const title = effectiveValue(itemByGroup.title);
                    return (
                      <div className='d-flex' key={index}>
                        <div
                          className={`rounded p-2 mb-2 cursor-pointer w-100 item-dependency-select ${clsx(
                            {
                              'active-item': Object.keys(listDependencyId).includes(
                                itemByGroup?._id
                              ),
                            }
                          )}`}
                          style={{
                            borderLeft: group?.settings?.color
                              ? `4px solid ${group?.settings?.color}`
                              : '4px solid #3699FF',
                          }}
                          onClick={() => selectValue(itemByGroup)}
                        >
                          {title}
                        </div>
                        <div
                          className='border rounded mb-2 cursor-pointer w-200px d-flex align-items-center ms-2'
                          key={itemByGroup?._id}
                          onChange={(e) => selectType(e, itemByGroup)}
                        >
                          <select
                            className='form-control form-control-xs mb-0 cursor-pointer border-0'
                            value={listDependencyId?.[itemByGroup?._id]?.type}
                          >
                            <option value='finish-start'>Finish-To-Start</option>
                            <option value='start-start'>Start-To-start</option>
                            <option value='start-finish'>Start-To-Finish</option>
                            <option value='finish-finish'>Finish-To-Finish</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  return <React.Fragment key={index} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <DropdownPopover onToggle={() => setExpandedOnce(true)} renderPopover={popover}>
        <div className='d-flex align-items-center list-dependency h-100 min-h-30px'>
          {listDependency?.map((dependency, index) => {
            return (
              <div key={index} className='rounded item-dependency px-1'>
                <span className=' rounded cursor-pointer'>{dependency.title}</span>
              </div>
            );
          })}
        </div>
      </DropdownPopover>
    );
  }

  return (
    <div className='w-100 border-0 overflow-hidden text-start'>
      <div className='d-flex align-items-center list-dependency h-100'>
        {listDependency?.map((dependency, index) => {
          return (
            <div key={index} className='rounded item-dependency px-1'>
              <span className=' rounded cursor-pointer'>{dependency.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DependencyField;
