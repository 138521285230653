import React from 'react';
import {effectiveValue} from '../../helpers';
import {ItemFormField} from 'app/modules/shared/component-provider';
import {useTranslate} from 'core/i18n/i18nProvider';
import './formShare.scss';

function FormShare({form, onChange, onSubmit, values, listField, hiddenColumns}) {
  const {t} = useTranslate();

  return (
    <div className='form-submit-container border'>
      <div className='content-form'>
        <div className='form-name'>
          <div className='text-form-name text-dark'>{form?.name}</div>
        </div>
        <div className='form-description'>
          <div className='text-form-description text-dark'>
            <div dangerouslySetInnerHTML={{__html: form?.description}}></div>
          </div>
        </div>
        <div className='border-bottom my-6 mx-3'></div>
        <div className='forms-edit'>
          {listField?.map((column) => {
            if (!column.hidden) {
              const value = effectiveValue(values[column.field]);
              return (
                <ItemFormField
                  key={column.field}
                  column={column}
                  onChange={onChange}
                  sheet={form?.sheet_info}
                  value={value}
                  mode='view'
                  fieldEditable={true}
                  hiddenColumns={hiddenColumns}
                />
              );
            } else {
              return <React.Fragment key={column.field} />;
            }
          })}
        </div>
        <div className='d-flex justify-content-end mt-5'>
          <div className='btn btn-primary cursor-pointer' onClick={onSubmit}>
            {form?.settings?.buttonSubmit?.label || t('form_button_submit')}
          </div>
        </div>
      </div>
      <div className='h-100px'></div>
    </div>
  );
}

export default FormShare;
