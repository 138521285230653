import React, {useEffect, useMemo, useState} from 'react';
import {Modal} from 'react-bootstrap';
import AppStoreView from '../view/AppStoreView';
import {useTranslate} from 'core/i18n/i18nProvider';
import {removeSign} from 'app/common/_helpers';
import appStoreDataJson from './appStoredata.json';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchStoreAppsAction} from '../../stores/storeApp/action';
import AppStoreAppView from '../view/AppStoreAppView';
import useApp from '../../AppHook';
import {useNavigate} from 'react-router-dom';
import {debounce} from 'lodash';
const AppStoreDialog = ({show, onHide}) => {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {storeApps} = useSelector((state) => state.storeApp, shallowEqual);
  const [apps, setApps] = useState([]);
  const [appStoreData, setAppStoreData] = useState(appStoreDataJson);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const navigate = useNavigate();

  const appCategories = useMemo(() => {
    return appStoreData?.categories ?? [];
  }, [appStoreData]);

  const getListApp = () => {
    const params = {
      category_id: selectedCategories,
      keywords: searchText,
    };
    dispatch(fetchStoreAppsAction(params));
  };

  useEffect(() => {
    if (show) {
      getListApp();
    }
  }, [show]);

  useEffect(() => {
    setApps(storeApps);
  }, [storeApps]);

  const handleAppInstalled = (app) => {
    onHide?.();
    navigate(`/application/view/${app?._id}`, {replace: true});
  };

  useEffect(() => {
    if (show) {
      getListApp();
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (show) {
      const search = debounce(getListApp, 300);
      search();
    }
  }, [searchText]);

  const renderSelectedApp = () => {
    if (selectedApp) {
      return (
        <AppStoreAppView
          app={selectedApp}
          onClose={() => setSelectedApp(null)}
          onInstalled={handleAppInstalled}
        />
      );
    }
    return null;
  };

  return (
    <Modal show={show} onHide={onHide} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title className='w-250px'>
          {t('app_manager_create_from_app_store_title')}
        </Modal.Title>
        <div className='d-flex align-items-center position-relative ms-2'>
          <i className='la fab la-sistrix position-absolute ms-2' />
          <input
            type='search'
            data-kt-user-table-filter='search'
            className='form-control ps-10 w-400px'
            placeholder={t('app_store_app_search_by_name')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </Modal.Header>
      <Modal.Body className='h-700px'>
        <div className='d-flex gap-2 h-100'>
          <div className='mw-250px w-100'>
            <h3 className='cursor-pointer' onClick={() => setSelectedCategories(null)}>
              {t('app_store_app_categories')}
            </h3>
            <div className='d-flex flex-column gap-2'>
              {appCategories.map((category, index) => (
                <div
                  className='btn btn-light bg-white p-2 text-start'
                  onClick={() => setSelectedCategories(category?.id)}
                  key={index}
                >
                  {category?.name}
                </div>
              ))}
            </div>
          </div>
          <div className='w-100 h-100'>
            {selectedApp && renderSelectedApp()}
            {!selectedApp && (
              <AppStoreView
                apps={apps}
                onAppSelect={(app) => {
                  setSelectedApp(app);
                }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AppStoreDialog;
