import './sheetCalendarView.scss';

import {Card, CardBody} from 'app/common/_partials/controls';
import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import FullCalendar from '@fullcalendar/react';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import dayGridPlugin from '@fullcalendar/daygrid';
import {effectiveValue} from 'app/modules/work/helpers';
import {get, isEmpty} from 'lodash';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import {useIntl} from 'react-intl';
import {useSheetData} from '../../../SheetDataHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import viLocales from '@fullcalendar/core/locales/vi';
import ModalAddNewItem from '../table/modal/ModalAddNewItem';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useSession} from 'core/store/hooks';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';
import {useSheetPermission} from '../../../SheetPermissionHook';
import ItemDetailView from '../../item-detail/ItemDetailView';
import {getLabelByField} from './helper';

export default function SheetCalendarView({
  sheetId,
  sheet,
  view,
  setSheet,
  options,
  onEditView,
  onSaveFilter,
  renderToolbar,
  editable,
}) {
  const {t} = useTranslate();
  const intl = useIntl();
  const {getValidationErrorMessage} = useValidation({});

  const [startDateColumn, setStartDateColumn] = useState(null);
  const [endDateColumn, setEndDateColumn] = useState(null);
  const [itemDisplayFields, setItemDisplayFields] = useState(null);
  const {itemId} = useParams();
  const {user, getDomain} = useSession();

  const [editItem, setEditItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [events, setEvents] = useState([]);
  const [showModalAddNewItem, setShowModalAddNewItem] = useState(false);
  const [initialNewItem, setInitialNewItem] = useState(null);
  const addingItem = useRef(false);

  const snackNotification = useSnackNotification();
  const {userSheetPermissions} = useSheetPermission({sheetId});
  const {
    fetchSheetItems,
    sheetItemList: items,
    viewItems,
    addItemQuick: onAddItem,
    updateItem: onUpdateItem,
    deleteItem: onDeleteItem,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    addItemFull,
  } = useSheetData({id: sheetId, sheet, view, setSheet, user, userSheetPermissions});

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
    }
  }, [sheetId]);

  useEffect(() => {
    if (itemId && items) {
      let foundItems = items.filter((x) => x._id === itemId);
      if (foundItems.length > 0) {
        setEditItem(foundItems[0]);
      }
    }
  }, [itemId, items]);

  useEffect(() => {
    //Should move this logic inside hook

    const columns = sheet?.columns || [];
    let columnStartDate = get(view, 'column_start_date');
    let columnEndDate = get(view, 'column_end_date');

    if (columnEndDate) {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field === columnEndDate.field) {
          if (!columnStartDate) {
            setStartDateColumn(columns[i]);
          }
          setEndDateColumn(columns[i]);
          break;
        }
      }
    }

    if (columnStartDate) {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field === columnStartDate.field) {
          if (!columnEndDate) {
            setEndDateColumn(columns[i]);
          }
          setStartDateColumn(columns[i]);
          break;
        }
      }
    }

    if (view.display_fields) {
      let displayFields = view.display_fields;
      displayFields = displayFields.map((field) => {
        let column = columns.find((column) => column.field === field.field);
        return {
          ...field,
          ...column,
        };
      });
      setItemDisplayFields(displayFields);
    } else {
      //Build default display fields
      let displayFields = [];
      //Priotize builtin field
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field === 'title') {
          displayFields.push(columns[i]);
        }
      }

      setItemDisplayFields(displayFields);
    }
  }, [sheet, view]);

  const onShowItemDetail = (item) => {
    //navigate(`/work/sheet/${sheet._id}/views/${view.code}/items/${item._id}`, {replace: false});
    setEditItem(item);
  };

  const onHideItemDetail = () => {
    //navigate(`/work/sheet/${sheet._id}/views/${view.code}`, {replace: false});
    setEditItem(null);
  };

  const onDeleteItemInit = (item) => {
    setItemToDelete(item);
  };

  const handleAddNewItem = (newItem) => {
    if (newItem?.title?.value === '') {
      snackNotification.showError(t('sheet_table_title_required'));
    } else if (!addingItem.current) {
      addingItem.current = true;
      addItemFull({...newItem, group: newItem?.group?.value ?? 'default'}, user, (result) => {
        if (result.success) {
          setShowModalAddNewItem(false);
        } else {
          if (result.code === validationErrorCode) {
            snackNotification.showError(
              result?.message
                ? getValidationErrorMessage(result?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              result?.message ? t(result?.message) : t('common_update_error')
            );
          }
        }
        addingItem.current = false;
      });
    }
  };

  const onDeleteItemConfirm = () => {
    onDeleteItem(itemToDelete, () => {
      setItemToDelete(null);
      setEditItem(null);
    });
  };

  useEffect(() => {
    if (startDateColumn && endDateColumn && itemDisplayFields && !isEmpty(itemDisplayFields)) {
      const _events = [];

      viewItems &&
        viewItems.forEach((item) => {
          let startDateValue = effectiveValue(get(item, startDateColumn.field));
          let endDateValue = effectiveValue(get(item, endDateColumn.field));

          if (startDateValue?.startDate || startDateValue) {
            let title = '';
            const startDateOrStartTimeline = startDateValue?.startDate || startDateValue;
            const endDateOrEndTimeline = startDateValue?.endDate || endDateValue;
            itemDisplayFields.forEach((field) => {
              const itemValue = effectiveValue(get(item, field.field));
              const itemLabel = getLabelByField(t, itemValue, field.field, item);
              if (itemLabel) {
                title += `${title ? '\n' : ''}${itemLabel}`;
              }
            });

            _events.push({
              id: item._id,
              title,
              className: 'fc-event-danger',
              start: moment(startDateOrStartTimeline * 1000).format('YYYY-MM-DDTHH:mm:ss'),
              end: moment(endDateOrEndTimeline * 1000).format('YYYY-MM-DDTHH:mm:ss'),
              item: item,
            });
          }
        });

      setEvents(_events);
    }
  }, [viewItems, startDateColumn, endDateColumn, itemDisplayFields]);

  const handleEventClick = (clickInfo) => {
    if (!clickInfo) {
      return null;
    }
    onShowItemDetail(clickInfo.event._def.extendedProps.item);
  };

  const handleSelect = (selectInfo) => {
    if (!selectInfo) {
      return null;
    }
    const _initialNewItem = {
      sheetId: sheetId,
    };

    if (startDateColumn?.field) {
      _initialNewItem[startDateColumn?.field] = moment(selectInfo?.start).unix();
    }

    if (_initialNewItem[endDateColumn?.field]) {
      _initialNewItem[endDateColumn?.field] = moment(selectInfo?.end).unix(); //Add 30 minutes
    }

    setInitialNewItem(_initialNewItem);
    setShowModalAddNewItem(true);
  };

  const handleDateClick = (clickInfo) => {
    const _initialNewItem = {
      sheetId: sheetId,
    };
    _initialNewItem[startDateColumn.field] = moment(clickInfo.date).unix();
    _initialNewItem[endDateColumn.field] = moment(clickInfo.date).unix() + 60 * 30; //Add 30 minutes
    setInitialNewItem(_initialNewItem);
    setShowModalAddNewItem(true);
  };

  const handleShowModalAddNewItem = () => {
    setShowModalAddNewItem(true);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <div className={'title-event'}>{eventInfo.event.title}</div>
      </>
    );
  };

  return (
    <>
      {options?.showToolbar && (
        <>
          {renderToolbar({
            sheet: sheet,
            view: view,
            onSetting: () => onEditView(view),
            onAddNew: handleShowModalAddNewItem,
            onFilterApply: applyFilter,
            onSearch: applySearch,
            saveFilter: onSaveFilter,
            onFilterReset: resetFilters,
            options: options?.userActions,
            activeFilters: activeFilters,
            filterActive: filterActive,
            sheetEditable: editable,
            showAddButton: true,
            userSheetPermissions,
          })}
        </>
      )}
      <Card className='bg-white'>
        <CardBody className='p-0'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{
              right: 'prev,next today',
              center: 'title',
              left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            }}
            initialView='timeGridWeek'
            buttonIcons={false} // show the prev/next text
            weekNumbers={true}
            navLinks={true} // can click day/week names to navigate views
            editable={true}
            eventLimit={true}
            dayMaxEventRows
            events={events}
            locales={[viLocales]}
            locale={intl.locale}
            scrollTime='07:00:00'
            selectable={true}
            eventClick={handleEventClick}
            dateClick={handleDateClick}
            select={handleSelect}
            eventContent={renderEventContent}
            height='70vh'
          />
        </CardBody>
      </Card>

      <ItemDetailView
        open={editItem !== null}
        item={editItem}
        permissions={userSheetPermissions}
        sheet={sheet}
        onHide={() => onHideItemDetail()}
        onDelete={onDeleteItemInit}
        onUpdateItem={onUpdateItem}
      />

      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
      {showModalAddNewItem && (
        <ModalAddNewItem
          view={view}
          sheet={sheet}
          sheetItemPermissions={userSheetPermissions}
          currentView={view}
          show={showModalAddNewItem}
          initialValues={initialNewItem}
          //configurable={configurable}
          onSave={handleAddNewItem}
          onHide={() => setShowModalAddNewItem(false)}
        />
      )}
    </>
  );
}
