import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import './sheetTableView.scss';
import {
  HeaderAddColumnFormatter,
  HeaderCheckboxColumnFormatter,
  HeaderFormatter,
} from './ColumnHeader';
import SheetEditColumn from '../../sheet-config/column/SheetEditColumn';

import {useNavigate, useParams} from 'react-router-dom';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import SheetAddColumn from '../../sheet-config/column/SheetAddColumn';
import {Drawer} from '@material-ui/core';
import {cloneDeep, isEmpty, isNil, remove} from 'lodash';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {
  hasSheetPermission,
  hasViewSheetColumnPermission,
  hasEditItemColumnPermission,
} from '../../../permission/permissionHelper';
import ColumnUserPermittedEdit from '../../sheet-config/column/ColumnUserPermittedEdit';
import {OGridViewV2} from 'app/modules/work/components/OGridViewV2';
import {useSheetData} from '../../../SheetDataHook';
import {useSheetMeta} from '../../../SheetMetaHook';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';
import ConfigUsersCanViewColumnDialog from 'app/modules/work/sheet/permission/ConfigUsersCanViewColumnDialog';
import ItemField from '../../field/ItemField';
import ModalFormulaEditor from '../../formula-editor/ModalFormulaEditor';
import {useSheetViewEvent} from '../SheetViewEventHook';
import useSheetTableHook from './SheetTableHook';
import ItemDetailView from '../../item-detail/ItemDetailView';

//Sheet table view not using redux and could be standalone anywhere
export default function SheetSubitemsTableView({
  sheetId,
  parentSheetId,
  parentItemId,
  members,
  parentView,
  onSelectItem: selectItem,
  subSelectItems,
  setSubSelectItems,
  editable,
  configurable,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
  },
  onOpenSubItemDetail,
}) {
  const {t} = useTranslate();
  const {user, getDomain} = useSession();
  const [sheet, setSheet] = useState(null);
  const {
    updateColumn: onUpdateColumn,
    addColumn: onAddColumn,
    deleteColumn: onDeleteColumn,
    getColumnsByView,
  } = useSheetMeta({sheetId: sheetId, user, sheet, setSheet});

  const {
    fetchSheetItems,
    sheetItemsByParent,
    addItemQuick: onAddItem,
    addItems: onAddItems,
    updateItem: onUpdateItem,
    updateItems: onUpdateItems,
    addValueToItems,
    removeValueInItems,
    deleteItem: onDeleteItem,
    userViewableColumns,
    addValueToItem,
    removeValueInItem,
    requestFetchSheetItem,
  } = useSheetData({id: sheetId, sheet, setSheet, user});

  const {onSaveItem} = useSheetTableHook({
    onUpdateItem,
    onUpdateItems,
    selectedItems: subSelectItems,
    setSelectedItems: setSubSelectItems,
  });

  const {view} = useParams();
  const navigate = useNavigate();
  const snackNotification = useSnackNotification();

  const [editColumn, setEditColumn] = useState(null);
  const [userEditColumn, setUserEditColumn] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [columnToDelete, setColumnToDelete] = useState(null);
  const [showAddColumn, setShowAddColumn] = useState(false);
  const addingItem = useRef(false);
  const inputAddItemRef = useRef();
  const [newItems, setNewItems] = useState([]);
  const [editFormulaColumn, setEditFormulaColumn] = useState(null);
  const {getValidationErrorMessage} = useValidation({});
  const [editItem, setEditItem] = useState(null);
  const [isShowConfigUsersCanViewColumnDialog, setIsShowConfigUsersCanViewColumnDialog] =
    useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheet && isEmpty(subSelectItems)) {
      fetchSheetItems();
      const sheetId = sheet?._id;
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheet?._id, subSelectItems]);

  useEffect(() => {
    return () => {
      setSubSelectItems({});
    };
  }, []);

  const onEditColumn = (column) => {
    setEditColumn(column.column);
  };

  const onEditFormula = (column) => {
    setEditFormulaColumn(column);
  };

  const onSaveFormula = (column, formula) => {
    onSaveColumn({...column, formula: formula});
    setEditFormulaColumn(null);
  };

  const onToggleColumnPermitted = (column) => {
    const _column = {
      ...column.column,
    };
    if (!column?.column?.is_permitted) {
      _column.is_permitted = true;
      setUserEditColumn(column.column);
    } else {
      delete _column?.is_permitted;
    }
    onSaveColumn(_column);
  };

  const onOpenPermittedEditColumn = (column) => {
    setUserEditColumn(column.column);
  };

  const onOpenUsersCanViewColumnDialog = (column) => {
    setCurrentColumn(column?.column);
    setIsShowConfigUsersCanViewColumnDialog(true);
  };

  const onDeleteColumnClick = (column) => {
    setColumnToDelete(column.column);
  };

  const onDeleteColumnConfirm = () => {
    onDeleteColumn && onDeleteColumn(columnToDelete);
    setColumnToDelete(null);
  };

  const onAddItemInputBlur = (group, e) => {
    if (e.target.value !== '' && !addingItem.current) {
      handleAddItemEvent(group, e);
    }
  };

  const onAddItemInputPasted = (group, e) => {
    const pastedText = e.clipboardData.getData('text');
    const input = e.target;

    let lines = pastedText.split('\r\n');
    lines = lines.filter((x) => {
      return x !== '';
    });
    if (lines.length > 1) {
      handleAddItems(group, lines, () => {
        setTimeout(() => (input.value = ''), 0);
      });
    }
  };

  const onAddItemInputKeyDown = (group, e) => {
    if (e.key === 'Enter') {
      handleAddItemEvent(group, e);
    }
  };

  const handleAddItems = (group, items, callback) => {
    if (items.length > 0 && !addingItem.current) {
      addingItem.current = true;
      if (onAddItems) {
        onAddItems(group, items, (res) => {
          const newItems = res.data?.data?.map((item) => item._id);
          setNewItems(newItems);
          callback && callback();
          addingItem.current = false;
        });
      } else {
        callback && callback();
        addingItem.current = false;
      }
    }
  };

  //Prevent duplicate blur and key down event
  const handleAddItemEvent = (group, e) => {
    if (e.target.value !== '' && !addingItem.current) {
      addingItem.current = true;
      onAddItem &&
        onAddItem(
          {
            group,
            title: e.target.value,
            parentItemId: parentItemId,
            user,
          },
          (newItem) => {
            e.target.value = '';
            addingItem.current = false;
            setNewItems([newItem._id]);
          }
        );
    }
  };

  const onShowItemDetail = (item) => {
    onOpenSubItemDetail && onOpenSubItemDetail();
    if (editItem?._id === item._id) {
      onHideItemDetail(item);
    } else {
      if (options?.navigateToItem) {
        navigate(`/work/sheet/${parentSheetId}/views/${parentView.code}/items/${item._id}`, {
          replace: true,
        });
      }

      setEditItem(item);
    }
  };

  const onHideItemDetail = () => {
    if (options?.navigateToItem) {
      navigate(`/work/sheet/${parentSheetId}/views/${parentView.code}`, {replace: true});
    }
    setEditItem(null);
  };

  const onSelectItem = (e, item) => {
    const selectedItemsClone = cloneDeep(subSelectItems);
    const checked = e.target.checked;
    if (checked) {
      selectedItemsClone[parentItemId] = selectedItemsClone[parentItemId]
        ? selectedItemsClone[parentItemId]
        : [];
      selectedItemsClone[parentItemId].push(item._id);
    } else {
      const newListSelectedItems = remove(selectedItemsClone[parentItemId], (id) => {
        return id !== item._id;
      });
      if (newListSelectedItems?.length > 0) {
        selectedItemsClone[parentItemId] = newListSelectedItems;
      } else {
        delete selectedItemsClone[parentItemId];
      }
    }
    selectItem(selectedItemsClone);
    // setSelectedItems(selectedItemsClone);
  };

  const onSelectAllClick = (e) => {
    const checked = e.target.checked;
    const selectedItemsClone = cloneDeep(subSelectItems);
    if (checked) {
      const listIdCheck = sheetItemsByParent?.[parentItemId]
        ? sheetItemsByParent?.[parentItemId]?.map((item) => item._id)
        : [];
      if (listIdCheck.length > 0) {
        selectedItemsClone[parentItemId] = listIdCheck;
      }
    } else {
      delete selectedItemsClone[parentItemId];
    }
    selectItem(selectedItemsClone);
    // setSelectedItems(selectedItemsClone);
  };

  const onUpdateValidation = (column, rules) => {
    onUpdateColumn({...column, rules: rules});
  };

  const sheetColumns = () => {
    const columns = userViewableColumns;

    if (columns) {
      const sheetCols = [];
      if (editable) {
        sheetCols.push({
          accessor: 'checkbox',
          header: '',
          text: '',
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center',
          headerClasses: 'sheet-column-header text-center w-40px d-flex align-items-center',
          headerFormatter: (column, colIndex) =>
            HeaderCheckboxColumnFormatter(column, colIndex, {
              selectAll: (e) => onSelectAllClick(e),
              totalSelectedItems: subSelectItems[parentItemId]?.length,
              totalItemInGroup: sheetItemsByParent?.[parentItemId]?.length,
              checked:
                subSelectItems[parentItemId]?.length > 0 &&
                subSelectItems[parentItemId]?.length === sheetItemsByParent?.[parentItemId]?.length,
            }),
          footer: '',
          formatter: (cell, row, rowIndex) => (
            <ItemField
              value={subSelectItems[parentItemId]?.includes(row._id) || false}
              onChange={(e) => onSelectItem(e, row)}
              column={{type: 'checkbox'}}
            />
          ),
        });
      }

      columns.forEach((column) => {
        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column.name,
            text: column.name,
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter
                column={column}
                colIndex={colIndex}
                editable={hasSheetPermission(sheet, user, 'board.edit')}
                onEdit={() => onEditColumn(column)}
                onToggleColumnPermitted={() => onToggleColumnPermitted(column)}
                onOpenPermittedEditColumn={() => onOpenPermittedEditColumn(column)}
                onSaveColumn={onSaveColumn}
                onDelete={() => onDeleteColumnClick(column)}
                sheet={sheet}
                user={user}
                onEditFormula={() => onEditFormula(column?.column)}
                onUpdateValidation={onUpdateValidation}
                onOpenUsersCanViewColumnDialog={onOpenUsersCanViewColumnDialog}
                style={style}
              />
            ),
            column: column,
            footer: '',
            formatExtraData: {
              field: column.field,
              onChange: onSaveItem,
            },
            editable: false,
          };
          col.formatter = (cell, row, rowIndex, onToggleSubitem) => {
            let props = {
              value: cell,
              item: row,
              column: column,
              sheet: sheet,
              onChange: onSaveItem,
              fieldEditable:
                hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission,
            };

            if (column.type === 'people') {
              props.onAddValue = onAddValueToItems;
              props.onRemoveValue = onRemoveValueInItems;
            }

            if (column.type === 'formula') {
              props.onEditFormula = () => onEditFormula(column);
            }

            if (column.type === 'date') {
              props.scrollDomSelector = `tab-${parentSheetId}`;
            }

            if (column?.field === 'title') {
              props.showDetail = onShowItemDetail;
              props.showExpand = false;
              props.onToggleSubitem = onToggleSubitem;
              props.onChange = onUpdateItem;
            }

            return <ItemField {...props} />;
          };

          if (editable && column?.field === 'title') {
            col.footerFormatter = () => (
              <input
                ref={inputAddItemRef}
                type='text'
                className='w-100 form-control  form-control-xs item-text-input'
                placeholder='+ Thêm dòng con'
                onBlur={(e) => onAddItemInputBlur(null, e)}
                onKeyDown={(e) => onAddItemInputKeyDown(null, e)}
                onPasteCapture={(e) => onAddItemInputPasted(null, e)}
              ></input>
            );
          }

          col.editable = (cell, row) => {
            return row?.userHasEditItemPermission;
          };
          col.userItemEditable = (cell, row) => {
            return hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission;
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
          if (!hasViewColumnPermission) {
            col.formatter = () => null;
            col.classes = 'bg-gray-300';
          }

          sheetCols.push(col);
        }
      });
      if (configurable) {
        sheetCols.push({
          accessor: '+',
          Header: '+',
          dataField: '',
          text: '+',
          headerClasses: 'sheet-column-header text-left',
          headerFormatter: (column, colIndex) => (
            <HeaderAddColumnFormatter
              column={column}
              colIndex={colIndex}
              onAdd={onAddColumnSubmit}
            />
          ),
          footer: '',
          editable: false,
        });
      } else {
        sheetCols.push({
          accessor: 'fake_column',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header flex-grow-1',
          headerFormatter: () => null,
          footer: '',
          editable: false,
        });
      }

      return sheetCols;
    } else {
      return [];
    }
  };

  const showMessageResult = (res) => {
    if (res?.data?.success) {
      snackNotification.showSuccess(t('common_update_success'));
    } else {
      if (res.data.code === validationErrorCode) {
        snackNotification.showError(
          res?.data?.message
            ? getValidationErrorMessage(res?.data?.message)
            : t('common_update_error')
        );
      } else {
        snackNotification.showError(
          res?.data?.message ? t(res?.data?.message) : t('common_update_error')
        );
      }
    }
  };

  const getUpdateItemIds = (item) => {
    let selectedIds = Object.values(subSelectItems).flat();

    // if current item not in selectedIds => update one.
    if (!subSelectItems?.[item?.parent_item_id]?.includes(item?._id)) {
      selectedIds = [item?._id];
    }
    return selectedIds;
  };

  const onAddValueToItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      addValueToItem && addValueToItem(item, field, value, callback ?? showMessageResult);
    } else {
      addValueToItems && addValueToItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onRemoveValueInItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      removeValueInItem && removeValueInItem(item, field, value, callback ?? showMessageResult);
    } else {
      removeValueInItems &&
        removeValueInItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onDeleteItemInit = (item) => {
    setItemToDelete(item);
  };

  const onDeleteItemConfirm = () => {
    onDeleteItem(itemToDelete, () => {
      setItemToDelete(null);
      setEditItem(null);
    });
  };

  const onAddColumnClick = () => {
    setShowAddColumn(true);
  };

  const onAddColumnSubmit = (data) => {
    onAddColumn && onAddColumn(data);
    setShowAddColumn(false);
  };

  const onSaveColumn = (column) => {
    onUpdateColumn && onUpdateColumn(column);
    setEditColumn(null);
  };

  useEffect(() => {
    let timeOut;
    if (newItems?.length) {
      timeOut = setTimeout(() => {
        setNewItems([]);
      }, 5000);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [newItems]);

  const isReadOnly = () => {
    return !editable;
  };

  return (
    <>
      <OGridViewV2
        classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1'
        keyField='_id'
        data={sheetItemsByParent[parentItemId] || []}
        columns={sheetColumns()}
        showFooter={editable}
      ></OGridViewV2>

      <ItemDetailView
        open={editItem !== null}
        item={editItem}
        sheet={sheet}
        view={view}
        onHide={() => onHideItemDetail()}
        onDelete={onDeleteItemInit}
        onUpdateItem={onUpdateItem}
        readOnly={isReadOnly()}
      />
      {editColumn && (
        <SheetEditColumn
          column={editColumn}
          onSubmit={onSaveColumn}
          show={!isNil(editColumn)}
          onHide={() => setEditColumn(null)}
          sheet={sheet}
        />
      )}
      <Modal size='md' centered show={showAddColumn} onHide={() => setShowAddColumn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_column_add_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SheetAddColumn onSubmit={onAddColumnSubmit}></SheetAddColumn>
        </Modal.Body>
      </Modal>
      <Modal
        size='md'
        centered
        show={userEditColumn !== null}
        onHide={() => setUserEditColumn(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('sheet_column_user_edit_title') + ' ' + userEditColumn?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          {userEditColumn && (
            <ColumnUserPermittedEdit
              column={userEditColumn}
              sheetColumns={sheet.columns}
              onSubmit={onSaveColumn}
            ></ColumnUserPermittedEdit>
          )}
        </Modal.Body>
      </Modal>

      <ConfigUsersCanViewColumnDialog
        column={currentColumn}
        show={isShowConfigUsersCanViewColumnDialog}
        onHide={() => setIsShowConfigUsersCanViewColumnDialog(false)}
        onSubmit={onSaveColumn}
      />

      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={columnToDelete !== null}
        onHide={() => setColumnToDelete(null)}
        title={t('sheet_column_delete_confirm_title')}
        content={t('sheet_column_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteColumnConfirm}
      />
      <ModalFormulaEditor
        show={editFormulaColumn !== null}
        onHide={() => setEditFormulaColumn(null)}
        onSave={onSaveFormula}
        metaColumns={getColumnsByView(view)}
        column={editFormulaColumn}
      />
    </>
  );
}
