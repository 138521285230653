import React, {useEffect, useState} from 'react';
import FormEdit from './components/form-edit/FormEdit';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getFormDetailAction, updateFormAction} from '../stores/form/action';

export const hiddenColumns = [
  'approval',
  'people',
  'dependency',
  'department',
  'formula',
  'reference',
  'complete',
  'last_updated_date',
  'boolean',
  'index',
];

function FormEditView({formId}) {
  const dispatch = useDispatch();
  const {form} = useSelector((state) => state.storeForm, shallowEqual);

  const handUpdateForm = (updatedFormPage) => {
    dispatch(updateFormAction(form._id, updatedFormPage)).then((res) => {
      dispatch(getFormDetailAction(form._id));
    });
  };

  useEffect(() => {
    if (formId && !form) {
      dispatch(getFormDetailAction(formId));
    }
  }, [formId, form]);

  return (
    <FormEdit
      isEditMode={true}
      listField={form?.settings?.columns || form?.sheet_info?.columns}
      form={form}
      onUpdate={handUpdateForm}
      hiddenColumns={hiddenColumns}
    />
  );
}

export default FormEditView;
