import React, {useState} from 'react';
import {Drawer} from '@material-ui/core';
import ItemDetail from './ItemDetail';
import './itemDetailDrawer.scss';
import clsx from 'clsx';
const ItemDetailDrawer = ({
  key,
  open,
  item,
  view,
  sheet,
  permissions,
  onHide,
  onEditItem,
  onDelete,
  onSaveItemAction,
  onUpdateItem,
  onMoveItem,
  readOnly,
  allowChangeGroup,
  onToggleDisplayMode,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isAlignBottom, setIsAlignBottom] = useState(false);

  const onToggleExpanded = () => {
    setIsFullScreen(!isFullScreen);
  };

  const onToggleAlign = (value) => {
    setIsAlignBottom(value);
  };

  return (
    <Drawer
      anchor='right'
      variant='persistent'
      key={key}
      open={open}
      onClose={onHide}
      PaperProps={{
        className: clsx('bg-body', isAlignBottom && 'dialog-align-bottom'),
        style: {color: 'unset'},
      }}
    >
      <div
        className={clsx(
          'item-detail-dialog',
          isFullScreen && 'full-width',
          open && 'active-drawer'
        )}
        key={`item-detail-${item?._id}`}
      >
        <ItemDetail
          item={item}
          view={view}
          sheet={sheet}
          permissions={permissions}
          onHide={onHide}
          onEditItem={onEditItem}
          onDelete={onDelete}
          onUpdateItem={onUpdateItem}
          onSaveItemAction={onSaveItemAction}
          onMoveItem={onMoveItem}
          readOnly={readOnly}
          allowChangeGroup={allowChangeGroup}
          showDialogControl={true}
          displayProps={{isFullScreen, onToggleDisplayMode, onToggleExpanded, onToggleAlign}}
        />
      </div>
    </Drawer>
  );
};

export default ItemDetailDrawer;
