import {isEmpty, isNumber, isObject} from 'lodash';
import {FormattedNumber} from 'react-intl';
import { formulaParser } from '../../formula-editor/FormulaParser';

function FormulaField({value, item, column, sheet, onChange, fieldEditable, readOnly, containerClass}) {
  const metaColumns = sheet?.columns ?? [];
  const getFormulaValue = () => {
    let formula = column?.formula ?? '';
    let parseFormula = formula;
    //for each column replace column field in formula with actual value
    metaColumns.forEach((column) => {
      let fieldValue = column?.type === 'text' ? '' : 0;
      if (item[column.field] && !isObject(item[column.field]) && !isEmpty(item[column.field])) {
        fieldValue = item[column.field];
      } else if (
        isObject(item[column.field]) &&
        item[column.field]?.value &&
        !isEmpty(item[column.field]?.value)
      ) {
        fieldValue = item[column.field]?.value;
      }
      let text = `{${column?.field}}`;
      if (column?.type === 'text') {
        parseFormula = parseFormula.replaceAll(text, `"${fieldValue ?? ''}"`);
      } else {
        parseFormula = parseFormula.replaceAll(text, fieldValue ?? 0);
      }
    });
    const result = formulaParser.parse(parseFormula);
    const displayValue = result?.result ?? result?.error;
    if (isNumber(displayValue)) {
      return <FormattedNumber value={displayValue} />;
    }
    return <span>{String(displayValue)}</span>;
  };

  return (
    <div className='w-100 text-truncate' style={{...column?.styles, flex: 1}}>
      {item && getFormulaValue()}
    </div>
  );
}

export default FormulaField;
