import '../../assets/scss/_sheet.scss';

import {
  hasSheetBoardPermission,
  hasSheetPermission,
  sheetActions,
} from 'app/modules/work/sheet/permission/permissionHelper';

import ConnectedDashboard from './connected-dashboard/ConnectedDashboard';
import ModalConfigValidation from '../modal/ModalConfigValidation';
import SheetCalendarView from './calendar/SheetCalendarView';
import SheetDashboardView from './dashboard/SheetDashboardView';
import SheetKanbanView from './kanban/SheetKanbanView';
import SheetTableView from './table/SheetTableView';
import {useSession} from 'core/store/hooks';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useSheetPermission} from '../../SheetPermissionHook';
import {hasEditSheetDataPermission} from '../../permission/permissionHelper';
import {useEffect, useState} from 'react';
import SheetPagingTableViewV2 from './table/SheetPagingTableViewV2';
import SheetPagingTableView from './table/SheetPagingTableView';

const viewTypes = {
  TABLE: 'table',
  TABLE_PAGING: 'table-paging',
  TABLE_PAGING_WITH_SUBITEMS: 'table-paging-with-subitems',
  CALENDAR: 'calendar',
  DASHBOARD: 'dashboard',
  CONNECTED_DASHBOARD: 'connected-dashboard',
  KANBAN: 'kanban',
  FORM: 'form',
};

export default function SheetViewView(props) {
  const {
    view,
    interfacePageSelect,
    setEditView,
    sheetMeta,
    options = {userActions: {}, showToolbar: true},
    renderToolbar,
    sheet,
    setSheet,
    interfaceMode,
    linkableSheets,
    connectedSheets,
    forceConfigurable,
    forceEditable,
  } = props;

  const {
    sheetId,
    updateView,
    updateInterfacePageView,
    updateColumn,
    addColumn,
    deleteColumn,
    saveViewFilter,
    addGroup,
    removeGroup,
    updateGroup,
    moveGroupToSheet,
    moveGroupToTop,
  } = sheetMeta;

  const {t} = useTranslate();
  const {user} = useSession();
  const snackNotification = useSnackNotification();
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const {userSheetPermissions} = useSheetPermission({sheetId});

  const viewType = view?.type;
  const hasConfigPermission =
    forceConfigurable || hasSheetPermission(sheet, user, sheetActions.config);

  useEffect(() => {
    setHasEditPermission(
      forceEditable || hasEditSheetDataPermission(userSheetPermissions, user, sheetActions.edit)
    );
  }, [forceEditable, userSheetPermissions]);

  const showEditView = (view) => {
    setEditView(view);
  };

  const handleSaveFilter = (view, filter) => {
    saveViewFilter(view, filter, () => {
      snackNotification.showSuccess(t('common_save_success'));
    });
  };

  const renderView = () => {
    if (viewType === viewTypes.TABLE) {
      return (
        <SheetTableView
          view={view}
          sheet={sheet}
          sheetId={sheetId}
          sheetMeta={sheetMeta}
          editable={hasEditPermission}
          configurable={hasConfigPermission}
          setSheet={setSheet}
          onAddGroup={addGroup}
          onEditView={showEditView}
          removeGroup={removeGroup}
          onUpdateGroup={updateGroup}
          onSaveFilter={handleSaveFilter}
          moveGroupToTop={moveGroupToTop}
          onMoveGroupToSheet={moveGroupToSheet}
          options={options}
          renderToolbar={renderToolbar}
          linkableSheets={linkableSheets}
        ></SheetTableView>
      );
    }

    if (viewType === viewTypes.TABLE_PAGING) {
      return (
        <SheetPagingTableViewV2
          view={view}
          sheet={sheet}
          sheetId={sheetId}
          sheetMeta={sheetMeta}
          editable={hasEditPermission}
          configurable={hasConfigPermission}
          setSheet={setSheet}
          onSaveFilter={handleSaveFilter}
          onEditView={showEditView}
          options={{
            ...options,
            resizableColumns:
              options?.resizableColumns ||
              (!interfaceMode &&
                hasSheetBoardPermission(userSheetPermissions, user, sheetActions.edit)),
          }}
          renderToolbar={renderToolbar}
          linkableSheets={linkableSheets}
        ></SheetPagingTableViewV2>
      );
    }

    if (viewType === viewTypes.TABLE_PAGING_WITH_SUBITEMS) {
      return (
        <SheetPagingTableView
          view={view}
          sheet={sheet}
          sheetId={sheetId}
          sheetMeta={sheetMeta}
          editable={hasEditPermission}
          configurable={hasConfigPermission}
          setSheet={setSheet}
          onSaveFilter={handleSaveFilter}
          onEditView={showEditView}
          options={options}
          renderToolbar={renderToolbar}
          linkableSheets={linkableSheets}
        ></SheetPagingTableView>
      );
    }

    if (viewType === viewTypes.CALENDAR) {
      const calendarOptions = {
        showToolbar: true,
        userActions: {
          ai_assistant: options?.userActions?.ai_assistant,
          search: options?.userActions?.search,
          filter: options?.userActions?.filter,
          add: options?.userActions?.add,
        },
      };

      return (
        <SheetCalendarView
          sheetId={sheetId}
          sheet={sheet}
          sheetMeta={sheetMeta}
          setSheet={setSheet}
          view={view}
          onSaveFilter={handleSaveFilter}
          options={calendarOptions}
          renderToolbar={renderToolbar}
          editable={hasEditPermission}
          onEditView={showEditView}
        ></SheetCalendarView>
      );
    }

    if (viewType === viewTypes.DASHBOARD) {
      const dashboardOptions = {
        ...options,
        userActions: {
          ...options?.userActions,
          export_excel: false,
        },
      };

      return (
        <SheetDashboardView
          sheet={sheet}
          sheetId={sheetId}
          view={view}
          onUpdateView={updateView}
          setSheet={setSheet}
          onEditView={showEditView}
          onSaveFilter={handleSaveFilter}
          configurable={hasConfigPermission}
          editable={hasEditPermission}
          options={dashboardOptions}
          renderToolbar={renderToolbar}
          initMode={interfaceMode !== 'edit' ? 'view' : 'edit'}
        ></SheetDashboardView>
      );
    }

    if (viewType === viewTypes.CONNECTED_DASHBOARD) {
      const connectedDashboardOptions = {
        ...options,
        userActions: {
          ...options?.userActions,
          export_excel: false,
        },
      };

      return (
        <ConnectedDashboard
          sheet={sheet}
          initMode={interfaceMode !== 'edit' ? 'view' : 'edit'}
          sheetId={sheetId}
          view={view}
          connectedSheets={connectedSheets}
          onUpdateView={updateView}
          setSheet={setSheet}
          onEditView={showEditView}
          onSaveFilter={handleSaveFilter}
          configurable={hasConfigPermission}
          editable={hasEditPermission}
          options={connectedDashboardOptions}
          renderToolbar={renderToolbar}
        ></ConnectedDashboard>
      );
    }

    if (viewType === viewTypes.KANBAN) {
      if (!options.userActions) {
        options.userActions = {};
      }

      return (
        <SheetKanbanView
          sheetId={sheetId}
          sheet={sheet}
          sheetMeta={sheetMeta}
          view={view}
          onUpdateColumn={updateColumn}
          setSheet={setSheet}
          onAddColumn={addColumn}
          onDeleteColumn={deleteColumn}
          onAddGroup={addGroup}
          removeGroup={removeGroup}
          onUpdateGroup={updateGroup}
          onEditView={showEditView}
          onMoveGroupToSheet={moveGroupToSheet}
          onSaveFilter={handleSaveFilter}
          moveGroupToTop={moveGroupToTop}
          configurable={hasConfigPermission}
          editable={hasEditPermission}
          updateView={updateView}
          options={options}
          renderToolbar={renderToolbar}
        ></SheetKanbanView>
      );
    }
    return (
      <SheetTableView
        sheetId={sheetId}
        sheet={sheet}
        sheetMeta={sheetMeta}
        view={view}
        onUpdateColumn={updateColumn}
        onAddColumn={addColumn}
        onDeleteColumn={deleteColumn}
        onAddGroup={addGroup}
        removeGroup={removeGroup}
        onUpdateGroup={updateGroup}
        onEditView={showEditView}
        onMoveGroupToSheet={moveGroupToSheet}
        onSaveFilter={handleSaveFilter}
        moveGroupToTop={moveGroupToTop}
        configurable={hasConfigPermission}
        setSheet={setSheet}
        editable={hasEditPermission}
        options={options}
        renderToolbar={renderToolbar}
      ></SheetTableView>
    );
  };

  return (
    <>
      {view && sheet && renderView()}
      <ModalConfigValidation />
    </>
  );
}
