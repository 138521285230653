import * as requestFromServer from './sheetApi';
import {sheetSlice, callTypes} from './sheetSlice';

const {actions} = sheetSlice;

export const fetchSheetsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheets(params)
    .then((res) => {
      const {data} = res;
      dispatch(actions.sheetsFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet";
    });
};

export const fetchSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheet(params)
    .then((res) => {
      const {data} = res;
      dispatch(actions.sheetFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet";
    });
};

export const getMySheetsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheets(params)
    .then((res) => {
      const {data} = res;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet";
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const getSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getSheet(params)
    .catch((error) => {
      error.clientMessage = "Can't get sheet";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const addSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addSheet(params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updateSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateSheet(params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getSheetItems(params)
    .catch((error) => {
      error.clientMessage = "Can't get sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const addSheetItemAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.addSheetItem(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const addQuickSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addQuickSheetItems(params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const addMultipleSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addMultipleSheetItems(params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const addItemsAndSubItemsAction = (sheetId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addItemsAndSubItems(sheetId, params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteSheetItemAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteSheetItem(params)
    .catch((error) => {
      error.clientMessage = "Can't delete sheet item";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteSheetItems(params)
    .catch((error) => {
      error.clientMessage = "Can't delete sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getSheetItemAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheetItem(params)
    .catch((error) => {
      error.clientMessage = "Can't get sheet item";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const updateSheetItemAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateSheetItem(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const updateSheetItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateSheetItems(params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updateMultipleItemsAction = (sheetId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updateMultipleItems(sheetId, params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const moveItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .moveItems(params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet items";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const searchPeopleAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .searchPeople(params)
    .then((response) => {
      if (response.data?.success) {
        return response.data.data;
      } else {
        Promise.reject();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't search people";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const searchTeamsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .searchTeams(params)
    .then((response) => {
      if (response.data?.success) {
        return response.data.data;
      } else {
        Promise.reject();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't search people";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const searchItemsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .searchItem(params)
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      } else {
        Promise.reject();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't search items";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const addSheetItemUpdateAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .addSheetItemUpdate(params)
    .catch((error) => {
      error.clientMessage = "Can't add sheet item update";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getSheetItemUpdatesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheetItemUpdates(params)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet item updates";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const saveSheetItemUpdateAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .saveSheetItemUpdate(params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet item update";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteSheetItemUpdateAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .deleteSheetItemUpdate(params)
    .catch((error) => {
      error.clientMessage = "Can't update sheet item update";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getPage(params)
    .catch((error) => {
      error.clientMessage = "Can't get page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updatePageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .updatePage(params)
    .catch((error) => {
      error.clientMessage = "Can't update page";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const uploadFileAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .uploadFile(params)
    .then((response) => {
      if (response.data.result) {
        return response.data.result;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't upload file";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getSheetItemAuditsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheetItemAudits(params)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't get sheet item updates";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const moveGroupAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .moveGroup(params)
    .catch((error) => {
      error.clientMessage = "Can't move group";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const copyColumnAction = (sheetId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .copyColumn(sheetId, params)
    .catch((error) => {
      error.clientMessage = "Can't copy column";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const downloadLinkFileAction = (fileId, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .downloadLinkFile(fileId, params)
    .catch((error) => {
      error.clientMessage = "Can't get link file";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const downloadLinkExportExcelAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .downloadLinkExportExcel(id, params)
    .catch((error) => {
      error.clientMessage = "Can't download link export";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const getTeamsTreeAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getTeamsTree(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get team";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const getDepartmentsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getDepartments(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get departments";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const getSheetColumnValuesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getSheetColumnValues(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get reference list";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const doActionAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .doAction(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't update item";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
};
export const analyzeExcelFileAction = (file) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .analyzeExcelFile(file)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't analyze file";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};
