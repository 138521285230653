import {effectiveValue} from 'app/modules/work/helpers';
import clsx from 'clsx';
import {useLang} from 'core/i18n/Metronici18n';
import {useTranslate} from 'core/i18n/i18nProvider';
import {format, fromUnixTime, isValid} from 'date-fns';
import moment from 'moment';
import {useMemo} from 'react';
import DatePicker from 'react-datepicker';
import './dateField.scss';
import {createPortal} from 'react-dom';
import isNumber from 'lodash/isNumber';

function DateField({
  value,
  item,
  column,
  scrollDomSelector,
  onChange,
  fieldEditable,
  readOnly,
  containerClassName,
  placeholderText,
}) {
  const effValue = effectiveValue(value);
  const field = column?.field;
  const lang = useLang();
  const {t} = useTranslate();

  const locale = useMemo(() => {
    if (lang === 'vi') {
      return 'vi';
    }
    if (lang === 'en') {
      return 'en';
    }
    return 'vi';
  }, [lang]);

  function validateDate(date) {
    //const regex = /^\d{1,2}[-/]\d{1,2}[-/]\d{4}(?:, \d{2}:\d{2})?$/;
    const regex =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return regex.test(date);
  }

  const dateTimeFormat = column?.settings?.hasTime ? 'dd-MM-yyyy, HH:mm' : 'dd-MM-yyyy';

  const elementTabView = useMemo(() => {
    if (scrollDomSelector) {
      return document.querySelector(`.${scrollDomSelector}`);
    }
    return null;
  }, [scrollDomSelector]);

  if (fieldEditable && !readOnly) {
    return (
      <div className={clsx('date-sheet-table-column', containerClassName)}>
        <DatePicker
          placeholderText={placeholderText}
          closeOnScroll={(e) => e.target === elementTabView}
          locale={locale}
          dateFormat={dateTimeFormat}
          className='w-100 bg-transparent'
          selected={effValue && isNumber(effValue) ? fromUnixTime(effValue) : null}
          todayButton={column?.settings?.hasTime ? null : t('sheet_column_date_button_today')}
          showTimeSelect={column?.settings?.hasTime}
          onChange={(val) => {
            onChange(item, field, effValue, {
              value: moment(val, 'dd-MM-yyyy, HH:mm').unix(),
            });
          }}
          popperContainer={({children}) =>
            createPortal(<div style={{zIndex: 1500}}>{children}</div>, document.body)
          }
        ></DatePicker>
      </div>
    );
  }

  return (
    <div className={clsx('d-flex align-items-center justify-content-center', containerClassName)}>
      {isNumber(effValue) && isValid(fromUnixTime(effValue)) && (
        <span className='px-2 date-limit-text-1'>
          {format(fromUnixTime(effValue), dateTimeFormat)}
        </span>
      )}
    </div>
  );
}

export default DateField;
