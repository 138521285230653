import Pusher from 'pusher-js';

export const useSheetViewEvent = () => {
  const initialize = ({domain, sheetId, onSheetItemUpdate, onSheetItemCreated, onSheetItemDeleted}) => {
    const pusherKey = window.PUSHER_KEY;
    const pusherCluster = window.PUSHER_CLUSTER || 'ap1';
    const pusherEncrypted = window.PUSHER_ENCRYPTED || true;
    const pusherChannelSheet = domain + '.sheet.' + sheetId;
    
    if (pusherKey) {
      const pusher = new Pusher(pusherKey, {
        cluster: pusherCluster,
        encrypted: pusherEncrypted
      });
      const channel = pusher.subscribe(pusherChannelSheet);
      channel.bind('sheet.item.updated', data => {
        onSheetItemUpdate && onSheetItemUpdate({itemId: data?.item?._id});
      });
      channel.bind('sheet.item.created', data => {
        onSheetItemCreated && onSheetItemCreated({itemId: data.item._id});
      });
      channel.bind('sheet.item.deleted', data => {
        onSheetItemDeleted && onSheetItemDeleted({itemId: data.item._id});
      });
      channel.bind('sheet.item.multipleDeleted', data => {
        data.items.forEach(itemId => {
          onSheetItemDeleted && onSheetItemDeleted({itemId: itemId});
        });
      });
    }
    
  }

  return {
    initialize
  };
};
