import {deleteApiV2, getApiV2, postApiV2} from 'core/store/api';

const endPoints = {
  searchSheet: '/api/sheet',
  getSheet: (id) => `/api/page/${id}`,
  createSheet: '/api/sheet',
  updateSheet: (id) => `/api/sheet/${id}`,
  analyzeExcelFile: '/api/analyze-excel',
  createItem: (id) => `/api/sheet/${id}/item`,
  createQuickItems: (id) => `/api/sheet/${id}/items`,
  createMultipleItems: (id) => `/api/sheet/${id}/create-multiple`,
  addItemsAndSubItems: (id) => `/api/sheet/${id}/create-multiple-with-subs`,
  getItems: (id) => `/api/sheet/${id}/item`,
  getItemsPaging: (id) => `/api/sheet/${id}/item-paging`,
  getSheetItems: `/api/sheet-items`,
  getSheetItem: (sheetId, itemId) => `/api/sheet/${sheetId}/item/${itemId}`,
  updateItem: (sheetId, itemId) => `/api/sheet/${sheetId}/item/${itemId}`,
  doAction: `/api/sheet-items/action`,
  deleteItem: (sheetId, itemId) => `/api/sheet/${sheetId}/item/${itemId}`,
  deleteItems: `/api/sheet-items/remove-batch`,
  createItemUpdate: (id) => `/api/sheet-item/${id}/update`,
  getItemUpdates: (id, type) => `/api/sheet-item/${id}/update?type=${type}`,
  saveItemUpdate: (itemId, itemUpdateId) => `/api/sheet-item/${itemId}/update/${itemUpdateId}`,
  deleteItemUpdate: (itemId, itemUpdateId) => `/api/sheet-item/${itemId}/update/${itemUpdateId}`,
  searchPage: '/api/page',
  getPage: (id) => `/api/page/${id}`,
  updatePage: (id) => `/api/page/${id}`,
  movePage: (id) => `/api/page/${id}/move`,
  deletePage: (id) => `/api/page/${id}`,
  searchPeople: '/api/user/search',
  uploadFile: '/api/file',
  getItemAudits: (id) => `/api/sheet-item/${id}/audits`,
  saveAsTemplate: (id) => `/api/page/${id}/save-as-template`,
  getWorkspaces: '/api/workspace',
  createWorkspace: '/api/workspace',
  moveGroup: '/api/sheet/group/move',
  moveToWorkspace: '/api/workspace/move',
  workspacePages: (id) => `/api/workspace/${id}/pages`,
  moveItems: '/api/sheet/items/move',
  updateItems: `/api/sheet-items/update-batch`,
  updateMultipleItems: (id) => `/api/sheet/${id}/update-multiple`,
  downloadLinkFile: (id) => `/api/download-link/${id}`,
  downloadLinkExportExcel: (id) => `/api/sheet-item-export/download/${id}`,
  exportExcel: `/api/sheet-item-export`,
  getTeams: '/api/team',
  searchTeams: '/api/team/search',
  getDepartments: '/api/department/search',
  getSheetColumnValues: (sheetId) => `/api/sheet/${sheetId}/values`,
};

export function getSheets(params) {
  return getApiV2(endPoints.searchSheet, params);
}

export function getSheet(params) {
  return getApiV2(endPoints.getSheet(params.id));
}

export function addSheet(params) {
  return postApiV2(endPoints.createSheet, params);
}

export function getSheetItems(params) {
  return postApiV2(endPoints.getItemsPaging(params.id), params);
}

export function addSheetItem(params) {
  return postApiV2(endPoints.createItem(params.sheetId), params);
}

export function addQuickSheetItems(params) {
  return postApiV2(endPoints.createQuickItems(params.sheetId), params);
}

export function addMultipleSheetItems(params) {
  return postApiV2(endPoints.createMultipleItems(params.sheetId), params);
}

export function addItemsAndSubItems(sheetId, params) {
  return postApiV2(endPoints.addItemsAndSubItems(sheetId), params);
}

export function deleteSheetItem(params) {
  return deleteApiV2(endPoints.deleteItem(params.sheetId, params.itemId), params);
}

export function deleteSheetItems(params) {
  return deleteApiV2(endPoints.deleteItems, params);
}

export function getSheetItem(params) {
  return getApiV2(endPoints.getSheetItem(params.sheetId, params.itemId));
}

export function updateSheetItem(params) {
  return postApiV2(endPoints.updateItem(params.sheetId, params.itemId), params);
}

export function moveItems(params) {
  return postApiV2(endPoints.moveItems, params);
}

export function updateSheet(params) {
  return postApiV2(endPoints.updateSheet(params.id), params);
}

export function addSheetItemUpdate(params) {
  return postApiV2(endPoints.createItemUpdate(params.itemId), params);
}

export function getSheetItemUpdates(params) {
  return getApiV2(endPoints.getItemUpdates(params.itemId, params.type));
}

export function saveSheetItemUpdate(params) {
  return postApiV2(endPoints.saveItemUpdate(params.itemId, params.itemUpdateId), params);
}

export function deleteSheetItemUpdate(params) {
  return deleteApiV2(endPoints.deleteItemUpdate(params.itemId, params.itemUpdateId), params);
}

export function searchItem(params) {
  return getApiV2(endPoints.getSheetItems, {q: params.searchText, sheetIds: params.sheetIds});
}



export function getPage(params) {
  return getApiV2(endPoints.getPage(params.id));
}

export function updatePage(params) {
  return postApiV2(endPoints.updatePage(params.id), params);
}


export function searchPeople(params) {
  return postApiV2(endPoints.searchPeople, params);
}

export function searchTeams(params) {
  return postApiV2(endPoints.searchTeams, params);
}

export function uploadFile(params) {
  var bodyFormData = new FormData();

  bodyFormData.append('file', params.file);

  return postApiV2(endPoints.uploadFile, bodyFormData);
}

export function getSheetItemAudits(params) {
  return getApiV2(endPoints.getItemAudits(params.itemId, params.type));
}

export function moveGroup(params) {
  return postApiV2(endPoints.moveGroup, params);
}

export function updateSheetItems(params) {
  return postApiV2(endPoints.updateItems, params);
}

export function updateMultipleItems(sheetId,params) {
  return postApiV2(endPoints.updateMultipleItems(sheetId), params);
}

export function copyColumn(sheetId,params) {
  return postApiV2(endPoints.updateMultipleItems(sheetId), params);
}

export function downloadLinkFile(fileId, params) {
  return getApiV2(endPoints.downloadLinkFile(fileId), params);
}

export function downloadLinkExportExcel(id, params) {
  return getApiV2(endPoints.downloadLinkExportExcel(id), params);
}

export function getTeamsTree(params) {
  return getApiV2(endPoints.getTeams, params);
}

export function getDepartments(params) {
  return postApiV2(endPoints.getDepartments, params);
}

export function getSheetColumnValues(params) {
  return postApiV2(endPoints.getSheetColumnValues(params.sheetId), params);
}

export function doAction(params) {
  return postApiV2(endPoints.doAction, params);
}

export function analyzeExcelFile(file) {
  var bodyFormData = new FormData();

  bodyFormData.append('file', file);

  return postApiV2(endPoints.analyzeExcelFile, bodyFormData);
}