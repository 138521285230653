import {useEffect, useMemo, useState} from 'react';

import {InterfaceThemeProvider} from './InterfaceThemeContext';
import SheetInterfaceToolbar from './SheetInterfaceToolbar';
import SheetViewToolbar from '../../toolbar/SheetViewToolbar';
import SheetViewView from '../../view/SheetViewView';
import {Spinner} from 'react-bootstrap';
import {cloneDeep} from 'lodash';
import clsx from 'clsx';
import {useSession} from 'core/store/hooks';
import {useSheetMeta} from '../../../SheetMetaHook';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function SheetInterfaceView({
  sheetId,
  interfacePage,
  backgroundColor,
  isConfigInterfaceView,
  handleEditInterfaceView,
  handUpdateInterfacePageView,
  interfaceMode,
  connectedSheets,
  saveFilter,
}) {
  const {user} = useSession();
  const [viewCode, setViewCode] = useState(null);
  const [viewIconBar, setViewIconBar] = useState(0);
  const snackNotification = useSnackNotification();
  const {t} = useTranslate();
  const isEditMode = interfaceMode === 'edit';
  const interfacePageViews = useMemo(() => {
    if (!interfacePage?.views) {
      return [];
    }

    return interfacePage.views?.map((view) => ({
      ...view,
      id: view.code,
      type: view.type,
      title: view.name,
    }));
  }, [interfacePage?.views]);

  const interfaceView = useMemo(() => {
    if (viewCode) {
      return interfacePageViews?.find((view) => String(view?.code) === viewCode);
    }
    return interfacePageViews?.find((view) => view?.is_default) ?? interfacePageViews?.[0];
  }, [interfacePageViews, viewCode]);

  const [sheet, setSheet] = useState(null);

  const sheetMeta = useSheetMeta({sheetId, user, sheet, setSheet});
  const {isLoading, fetchSheet} = sheetMeta;

  const interfaceThemeContextValue = {textColor: 'white', backgroundColor};

  const updateView = (oldView, newView) => {
    if (!oldView || !newView) {
      return;
    }

    const _interfacePage = cloneDeep(interfacePage);

    const _newInterfacePageViews = interfacePageViews.map((view) => {
      if (view.code === oldView.code) {
        return newView;
      } else {
        return view;
      }
    });

    const updatedInterfacePage = {
      ..._interfacePage,
      views: _newInterfacePageViews,
    };

    handUpdateInterfacePageView(updatedInterfacePage);
  };

  const updateInterfacePageView = (interfacePageUpdate) => {
    return handUpdateInterfacePageView({
      ...interfacePage,
      ...interfacePageUpdate,
    });
  };

  const handleSelectedViewChanged = (viewId) => {
    setViewCode(viewId);
  };

  const handleSelectedViewIconBarChanged = (selectedIndex) => {
    setViewIconBar(selectedIndex);
  };

  const handleSaveFilter = (view, filter) => {
    saveFilter(view, filter, () => {
      snackNotification.showSuccess(t('common_save_success'));
    });
  };

  useEffect(() => {
    setViewCode(null);
    fetchSheet();
  }, [interfacePage?.id]);

  const renderToolbar = ({
    sheetId,
    sheet,
    view,
    mode,
    onAddNewGroup,
    onSetting,
    onExportExcel,
    onAddNew,
    onFilterApply,
    onFilterReset,
    activeFilters,
    filterActive,
    onSearch,
    onCreateGroupTasks,
    addItemsAndSubItems,
    options,
    allowFilterByGroup,
    canAddGroup,
    showAddButton,
    changeMode,
    viewIconBar,
  }) => {
    return (
      <SheetViewToolbar
        sheetId={sheetId}
        sheet={sheet}
        view={view}
        mode={mode}
        onAddNewGroup={onAddNewGroup}
        onSetting={onSetting}
        onExportExcel={onExportExcel}
        onAddNew={onAddNew}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
        activeFilters={activeFilters}
        filterActive={filterActive}
        saveFilter={handleSaveFilter}
        onSearch={onSearch}
        onCreateGroupTasks={onCreateGroupTasks}
        addItemsAndSubItems={addItemsAndSubItems}
        options={options}
        allowFilterByGroup={allowFilterByGroup}
        canAddGroup={canAddGroup}
        showAddButton // TODO: Need to update this code
        changeMode={changeMode}
        viewsCanAdd={['table-paging']} // Hide edit mode view for both dashboard and connected-dashboard views.
        isEditMode={isEditMode}
      ></SheetViewToolbar>
    );
  };

  const onClickInterfaceView = (e) => {
    if (isEditMode) {
      handleEditInterfaceView(e, viewCode);
    }
  };

  return (
    <InterfaceThemeProvider value={interfaceThemeContextValue}>
      {isLoading ? (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Spinner animation='border' role='status' className='text-muted'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        sheetId && (
          <>
            {interfaceView?.type !== 'form' && (
              <SheetInterfaceToolbar
                views={interfacePageViews}
                selectedView={interfaceView}
                interfacePage={interfacePage}
                isConfigInterfaceView={isEditMode && isConfigInterfaceView}
                handleSelectView={handleSelectedViewChanged}
                selectedBarIcon={viewIconBar}
                handleSelectViewIconBar={handleSelectedViewIconBarChanged}
              />
            )}
            <div
              className={clsx(
                'px-2 d-flex flex-column flex-shrink-1 flex-grow-1 flex-basic-0 overflow-auto bg-body',
                {
                  'border-w2 border-primary rounded-end rounded-top-0':
                    isEditMode && isConfigInterfaceView,
                }
              )}
              onClick={onClickInterfaceView}
            >
              {interfaceView && (
                <SheetViewView
                  view={interfaceView}
                  interfacePageSelect={interfacePage}
                  interfaceMode={interfaceMode}
                  sheetMeta={{...sheetMeta, updateView, updateInterfacePageView}}
                  options={{
                    showToolbar: true,
                    showAddGroup: true,
                    userActions: interfacePage?.user_actions,
                    hideColumnEditor: true,
                    resizableColumns: false,
                  }}
                  renderToolbar={renderToolbar}
                  sheet={sheet}
                  setSheet={setSheet}
                  connectedSheets={connectedSheets}
                />
              )}
            </div>
          </>
        )
      )}
    </InterfaceThemeProvider>
  );
}
