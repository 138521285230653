import React, {useState} from 'react';
import {useTranslate} from 'core/i18n/i18nProvider';
import {OverlayTrigger} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import {useAppDispatch} from 'core/store/hooks';
import {useSession} from 'core/store/core/hooks';
import {actions} from 'core/store/auth';
import {ShowForGlobalPermission} from 'core/permissions/ShowForGlobalPermission';
import {globalPermissions} from 'core/permissions/globalPermissionHelper';
import ThemeModeSwitcher from '../theme-mode-switcher/ThemeModeSwitcher';

function UserMenuDialog({children}) {
  const dispatch = useAppDispatch();
  const {user} = useSession();
  const {t} = useTranslate();

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  const doLogout = () => {
    dispatch(actions.logout());
  };

  const popover = (
    <Popover className='app-config-popover'>
      <Popover.Body>
        <div
          className='menu menu-column menu-rounded menu-state-bg menu-state-primary fw-bold fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-2'>
              <div className='symbol symbol-50px me-5'>
                <div className='w-50px h-50px'>
                  <span className='symbol-label bg-light-danger text-danger fw-bold position-absolute'>
                    {user?.name?.[0] || ''}
                  </span>
                  {user?.avatar && (
                    <img
                      src={user.avatar}
                      alt='avatar'
                      className='position-relative w-100 h-100 rounded'
                      style={{zIndex: 10}}
                      onError={(e) => {
                        e.target.style.display = 'none';
                      }}
                    />
                  )}
                </div>
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {user?.name}
                  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'></span>
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  {user?.email}
                </a>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>
          <ShowForGlobalPermission permission={globalPermissions.userManagement}>
            <div className='menu-item'>
              <Link to='/admin' className='menu-link px-5'>
                {t('user_header_menu_account_setting')}
              </Link>
            </div>
            <div className='separator my-2'></div>
          </ShowForGlobalPermission>
          <div className='menu-item'>
            <Link to='/profile' className='menu-link px-5'>
              {t('user_header_menu_profile_setting')}
            </Link>
          </div>

          <div className='separator my-2'></div>
          <div className='menu-item'>
            <Link to='/app-manager/home' className='menu-link px-5'>
              {t('user_header_menu_app_setting')}
            </Link>
          </div>

          <div className='separator my-2'></div>
          <div className='menu-item'>
            <Link to='/developer' className='menu-link px-5'>
              {t('user_header_menu_developer')}
            </Link>
          </div>

          <div className='separator my-2'></div>
          <ThemeModeSwitcher className={'menu-item'}/>

          <div className='separator my-2'></div>
          <div className='menu-item'>
            <a onClick={doLogout} className='menu-link px-5'>
              {t('user_header_menu_log_out')}
            </a>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={popover}
        show={show}
        onToggle={toggleShow}
        rootClose
        rootCloseEvent={'click'}
      >
        {children}
      </OverlayTrigger>
    </>
  );
}

export default UserMenuDialog;
