import * as requestFromServer from './api';
import {callTypes, appSlice} from './slice';

const {actions} = appSlice;

export const getAppsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getApps(params)
    .then((res) => {
      const {data} = res;
      dispatch(actions.appsFetched({data}));
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get apps";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.list}));
    });
};

export const createAppAction = (params) => (dispatch) => {
  return requestFromServer
    .createApp(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't create apps";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const updateAppAction = (params) => (dispatch) => {
  return requestFromServer
    .updateApp(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't update apps";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const deleteAppAction = (appId) => (dispatch) => {
  return requestFromServer
    .deleteApp(appId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't update apps";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};

export const createSheetAction = (params) => (dispatch) => {
  return requestFromServer
    .createSheet(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't create sheet";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
};

export const getAppAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getApp(id)
    .then((res) => {
      const {data} = res;
      dispatch(actions.appFetched({data}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
};


export const promptAiAssistantAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .promptAiAssistant(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't prompt ai assistant";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
}


export const generateAppAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .generateApp(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't generate app";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
}


export const generateAppSheetAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .generateAppSheet(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't generate app";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
}

export const generateAppDataAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .generateAppData(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't generate app";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}));
    });
}

export const addSheetItemAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.addSheetItem(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const createTokenShareForm = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.createTokenShareForm(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const getShareFormByToken = (token) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getShareFormByToken(token).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const createSheetFormAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.createSheetForm(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const deleteSheetFormAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.deleteSheetForm(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const getFormByAppAction = (appId) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getFormByApp(appId).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const updateFormAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateForm(id, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const getKeyShareFormAction = (id, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getKeyShareForm(id, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const getFromByShareKeyAction = (key, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getFromByShareKey(key, params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};

export const submitFormAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.submitForm(params).finally(() => {
    dispatch(actions.endCall({callType: callTypes.action}));
  });
};