import './sheetTableView.scss';

import {Accordion, Dropdown, Modal, useAccordionButton} from 'react-bootstrap';
import {Card, CardBody, CardHeaderTitle} from 'app/common/_partials/controls';
import {
  HeaderAddColumnFormatter,
  HeaderCheckboxColumnFormatter,
  HeaderFormatter,
} from './ColumnHeader';
import {cloneDeep, isArray, isEmpty} from 'lodash';
import {
  hasSheetPermission,
  hasViewSheetColumnPermission,
  hasEditItemColumnPermission,
} from '../../../permission/permissionHelper';
import {useEffect, useRef, useState} from 'react';
import useUserStateHook, {ObjectTypes, UiActions} from 'core/userState';

import {ActionBar} from '../../action-bar/ActionBar';
import ColumnUserPermittedEdit from '../../sheet-config/column/ColumnUserPermittedEdit';
import ConfigUsersCanViewColumnDialog from 'app/modules/work/sheet/permission/ConfigUsersCanViewColumnDialog';
import {DialogColorGroup} from './dialog/DialogColorGroup';
import {DialogMoveToSheet} from './dialog/DialogMoveToSheet';
import {DropdownCustomToggler} from 'app/common/_partials/dropdowns';
import ItemField from '../../field/ItemField';
import ModalAddNewItem from './modal/ModalAddNewItem';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import ModalFormulaEditor from '../../formula-editor/ModalFormulaEditor';
import ModalMoveGroupToSheet from '../../modal/ModalMoveGroupToSheet';
import {OGridViewV2} from 'app/modules/work/components/OGridViewV2';
import SheetEditColumn from '../../sheet-config/column/SheetEditColumn';
import SheetSubitemsTableView from './SheetSubitemsTableView';
import {ShowForSheetPermission} from 'app/modules/work/sheet/permission/ShowForSheetPermission';
import {getSheetAction} from 'app/modules/work/sheet/stores/sheetActions';
import {getTranslationTextByField} from './helper';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useSession} from 'core/store/hooks';
import {useSheetData} from '../../../SheetDataHook';
import useSheetTableHook from './SheetTableHook';
import {useSheetViewEvent} from '../SheetViewEventHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';
import {useSheetPermission} from '../../../SheetPermissionHook';
import ItemDetailView from '../../item-detail/ItemDetailView';

export default function SheetTableView({
  sheetId,
  sheet,
  setSheet,
  view,
  onAddGroup,
  onUpdateGroup,
  removeGroup,
  onEditView,
  onMoveGroupToSheet,
  onSaveFilter,
  moveGroupToTop,
  sheetMeta,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
    hideColumnEditor: false,
  },
  configurable,
  editable,
  renderToolbar,
  linkableSheets,
}) {
  const {t} = useTranslate();
  const {handleUserAction, groupStateExpanded} = useUserStateHook();
  const dispatch = useDispatch();
  const {itemId} = useParams();

  const [showModalAddNewItem, setShowModalAddNewItem] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [selectedItemsByGroups, setSelectedItemsByGroups] = useState({});
  const [selectedItemsDataByGroups, setSelectedItemsDataByGroups] = useState({});
  const [moveToSheet, setMoveToSheet] = useState(null);
  const [moveGroup, setMoveGroup] = useState(null);
  const addingItem = useRef(false);
  const {user, getDomain} = useSession();
  const inputAddItemRef = useRef();
  const [subSelectItems, setSubSelectItems] = useState({});
  const enableActions = ['remove_item'];
  const viewChangeGroup = ['table'];
  const [selectedItems, setSelectedItems] = useState([]);

  const [idDialogChangeColorGroup, setIdDialogChangeColorGroup] = useState(null);
  const {moveGroupPosition} = sheetMeta;

  const {userSheetPermissions} = useSheetPermission({sheetId});
  const {
    requestFetchSheetItem,
    fetchSheetItems,
    sheetItemList,
    addItemQuick: onAddItem,
    addItems: onAddItems,
    updateItem: onUpdateItem,
    doAction: onDoAction,
    updateItems: onUpdateItems,
    addValueToItems,
    removeValueInItems,
    deleteItem: onDeleteItem,
    removeItems: onRemoveItems,
    removeSubItems: onRemoveSubItems,
    moveItems: onMoveItems,
    moveItem: onMoveItem,
    exportExcel: onExportExcel,
    addGroupWithItems,
    tools,
    addItemFull,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    viewItemsByGroups,
    sheetGroups,
    addItemsAndSubItems,
    userViewableColumns,
    addValueToItem,
    removeValueInItem,
  } = useSheetData({
    id: sheetId,
    sheet,
    setSheet,
    view,
    user,
    isRemote: false,
  });

  const {getValidationErrorMessage} = useValidation({});

  const {
    setNewItems,
    editColumn,
    setEditColumn,
    onEditColumn,
    onEditFormula,
    editFormulaColumn,
    setEditFormulaColumn,
    onSaveFormula,
    onSaveColumn,
    userEditColumn,
    onSaveItem,
    onSaveItemAction,
    setUserEditColumn,
    onOpenPermittedEditColumn,
    onToggleColumnPermitted,
    onOpenUsersCanViewColumnDialog,
    currentColumn,
    isShowConfigUsersCanViewColumnDialog,
    setIsShowConfigUsersCanViewColumnDialog,
    onDeleteColumnClick,
    onDeleteColumnConfirm,
    onExportExcelConfirm,
    onShowItemDetail,
    editItem,
    setEditItem,
    itemToDelete,
    setItemToDelete,
    columnToDelete,
    setColumnToDelete,
    onHideItemDetail,
    onUpdateValidation,
    onDeleteItemInit,
    onDeleteItemConfirm,
    onAddColumnSubmit,
    snackNotification,
    handleRemoveSubItems,
    handleAction,
    highlightKeys,
    indicatorStyle,
  } = useSheetTableHook({
    sheetMeta,
    onExportExcel,
    options,
    sheet,
    view,
    sheetItemList,
    onUpdateItem,
    onDoAction,
    onUpdateItems,
    addValueToItems,
    removeValueInItems,
    onDeleteItem,
    onMoveItems,
    onRemoveItems,
    onRemoveSubItems,
    selectedItems,
    setSelectedItems,
    subSelectItems,
    setSubSelectItems,
    tools,
  });

  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheetId]);

  useEffect(() => {
    let itemDetailId = itemId ?? editItem?._id;
    if (itemDetailId && sheetItemList) {
      let foundItems = sheetItemList.filter((x) => x._id === itemDetailId);
      if (foundItems.length > 0) {
        setEditItem(foundItems[0]);
      }
    }
  }, [itemId, sheetItemList]);

  useEffect(() => {
    if (selectedItemsByGroups) {
      let newSelectedItems = [];
      Object.keys(selectedItemsByGroups).forEach((key) => {
        newSelectedItems = newSelectedItems.concat(selectedItemsByGroups[key]);
      });
      setSelectedItems(newSelectedItems);
    }
  }, [selectedItemsByGroups]);

  const onDeleteGroupConfirm = () => {
    removeGroup(groupToDelete.id);
    setGroupToDelete(null);
  };
  const onAddItemInputBlur = (group, e) => {
    if (e.target.value !== '' && !addingItem.current) {
      handleAddItemEvent(group, e);
    }
  };

  const onAddItemInputPasted = (group, e) => {
    const pastedText = e.clipboardData.getData('text');
    const input = e.target;
    let lines = pastedText.replaceAll('\r', '').split('\n');
    lines = lines.filter((x) => {
      return x !== '';
    });
    if (lines.length > 1) {
      handleAddItems(group, lines, () => {
        setTimeout(() => (input.value = ''), 0);
      });
    }
  };

  const onAddItemInputKeyDown = (group, e) => {
    if (e.key === 'Enter') {
      handleAddItemEvent(group, e);
    }
  };

  const handleAddItems = (group, items, callback) => {
    if (items.length > 0 && !addingItem.current) {
      addingItem.current = true;
      if (onAddItems) {
        onAddItems(group, items, (res) => {
          const newItems = res.data?.data?.map((item) => item._id);
          setNewItems(newItems);
          callback && callback();
          addingItem.current = false;
        });
      } else {
        callback && callback();
        addingItem.current = false;
      }
    }
  };

  //Prevent duplicate blur and key down event
  const handleAddItemEvent = (group, e) => {
    if (e.target.value !== '' && !addingItem.current) {
      addingItem.current = true;
      const callback = (newItem) => {
        if (newItem) {
          e.target.value = '';
          addingItem.current = false;
          setNewItems([newItem._id]);
        }
      };

      onAddItem && onAddItem({group, title: e.target.value, user}, callback);
    }
  };

  const onAddGroupClick = () => {
    onAddGroup && onAddGroup('Nhóm');
  };

  const onSelectAllClick = (e, group) => {
    const checked = e.target.checked;
    const selectedItemsClone = cloneDeep(selectedItemsByGroups);
    const selectedItemsDataClone = cloneDeep(selectedItemsDataByGroups);
    if (checked) {
      const listIdCheck = viewItemsByGroups?.[group?.id]
        ? viewItemsByGroups?.[group?.id]?.map((item) => item._id)
        : [];
      if (listIdCheck.length > 0) {
        selectedItemsClone[group.id] = listIdCheck;
        selectedItemsDataClone[group.id] = viewItemsByGroups?.[group?.id];
      }
    } else {
      delete selectedItemsClone[group.id];
      delete selectedItemsDataClone[group.id];
    }
    setSelectedItemsByGroups(selectedItemsClone);
    setSelectedItemsDataByGroups(selectedItemsDataClone);
  };

  const onSelectItem = (e, item, groupId) => {
    const selectedItemsClone = cloneDeep(selectedItemsByGroups);
    const selectedItemsDataClone = cloneDeep(selectedItemsDataByGroups);

    const checked = e.target.checked;
    if (checked) {
      selectedItemsClone[groupId] = selectedItemsClone[groupId] ? selectedItemsClone[groupId] : [];
      selectedItemsDataClone[groupId] = selectedItemsDataClone[groupId]
        ? selectedItemsDataClone[groupId]
        : [];
      selectedItemsClone[groupId].push(item._id);
      selectedItemsDataClone[groupId].push(item);
    } else {
      const newListSelectedItems = [];
      const newListItemData = cloneDeep(selectedItemsDataClone[groupId])?.filter((_item) => {
        if (_item._id !== item._id) {
          newListSelectedItems.push(_item._id);
          return true;
        } else {
          return false;
        }
      });
      if (newListSelectedItems?.length > 0) {
        selectedItemsClone[groupId] = newListSelectedItems;
        selectedItemsDataClone[groupId] = newListItemData;
      } else {
        delete selectedItemsClone[groupId];
        delete selectedItemsDataClone[groupId];
      }
    }
    setSelectedItemsByGroups(selectedItemsClone);
    setSelectedItemsDataByGroups(selectedItemsDataClone);
  };

  const onOpenSubItemDetail = () => {
    onHideItemDetail();
  };

  const sheetColumns = (group, idxGroup) => {
    let columns = userViewableColumns;
    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }

    const groupColor = group?.settings?.color ?? 'var(--kt-primary)';
    const firstColumnStyle = {
      '--grid-indicator-left': groupColor,
      borderLeft: 0,
    };

    if (columns) {
      const sheetCols = [];
      if (editable) {
        sheetCols.push({
          accessor: 'checkbox',
          header: 'checkbox',
          text: 'checkbox',
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center justify-content-center',
          headerClasses:
            'sheet-column-header text-center w-40px d-flex align-items-center justify-content-center',
          style: firstColumnStyle,
          headerStyle: firstColumnStyle,
          headerFormatter: (column, colIndex) =>
            HeaderCheckboxColumnFormatter(column, colIndex, {
              selectAll: (e) => onSelectAllClick(e, group),
              totalSelectedItems: selectedItemsByGroups?.[group?.id]?.length,
              totalItemInGroup: viewItemsByGroups?.[group?.id]?.length,
              checked:
                selectedItemsByGroups?.[group?.id]?.length > 0 &&
                selectedItemsByGroups?.[group?.id]?.length ===
                  viewItemsByGroups?.[group?.id]?.length,
            }),
          footer: '',
          formatter: (cell, row, rowIndex) => (
            <ItemField
              value={selectedItemsByGroups[group?.id]?.includes(row._id) || false}
              onChange={(e) => onSelectItem(e, row, group.id)}
              column={{type: 'checkbox'}}
            />
          ),
        });
      }

      if (view?.settings?.show_line_number) {
        sheetCols.push({
          accessor: 'sheet_view_table_line_number',
          header: t('sheet_view_table_line_number'),
          text: t('sheet_view_table_line_number'),
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center justify-content-center',
          headerClasses:
            'sheet-column-header text-center w-40px d-flex align-items-center justify-content-center',
          footer: '',
          formatter: (_cell, _row, rowIndex) => rowIndex + 1,
        });
      }

      let visibleTitleColumn = columns.find(
        (column) =>
          !column?.deleted &&
          !column?.hidden &&
          (column?.field === 'title' || column?.is_title === true)
      );
      //Show action field if there is no visible title
      if (!visibleTitleColumn) {
        let actionColumnProps = {
          column: {type: 'action'},
          sheet: sheet,
        };
        actionColumnProps.showDetail = onShowItemDetail;

        if (sheet?.sub_boards?.length) {
          actionColumnProps.showExpand = true;
        }

        sheetCols.push({
          accessor: 'sheet_view_table_action',
          header: '',
          text: '',
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center',
          headerClasses: 'sheet-column-header text-center w-40px d-flex align-items-center',
          formatter: (cell, row, rowIndex, onToggleSubitem) => (
            <ItemField
              {...actionColumnProps}
              item={row}
              onToggleSubitem={onToggleSubitem}
              row={row}
            />
          ),
        });
      }

      // mapping columns
      columns.forEach((tableColumn) => {
        let column = {
          ...tableColumn,
        };

        // mapping columns values if it has type select
        if (column?.values && isArray(column?.values)) {
          column.values = column?.values?.map((item) => {
            return {...item, value: item?.value || 'default'};
          });
        }

        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column?.name || getTranslationTextByField(t, column?.field),
            text: column?.name || getTranslationTextByField(t, column?.field),
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap',
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter
                column={column}
                colIndex={colIndex}
                editable={
                  hasSheetPermission(sheet, user, 'board.edit') && !options?.hideColumnEditor
                }
                onEdit={() => onEditColumn(column)}
                onToggleColumnPermitted={() => onToggleColumnPermitted(column)}
                onOpenPermittedEditColumn={() => onOpenPermittedEditColumn(column)}
                onSaveColumn={onSaveColumn}
                onDelete={() => onDeleteColumnClick(column)}
                sheet={sheet}
                user={user}
                onEditFormula={() => onEditFormula(column?.column)}
                onUpdateValidation={onUpdateValidation}
                onOpenUsersCanViewColumnDialog={onOpenUsersCanViewColumnDialog}
                style={style}
              />
            ),

            column: column,
            footer: '',
            formatExtraData: {
              field: column.field,
              onChange: onSaveItem,
            },
            formatter: () => {},
            editable: false,
          };
          col.formatter = (cell, row, rowIndex, onToggleSubitem) => {
            let props = {
              value: cell,
              item: row,
              column: column,
              sheet: sheet,
              onChange: onSaveItem,
              onAction: onSaveItemAction,
              fieldEditable:
                hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission,
            };

            if (column.type === 'people') {
              props.onAddValue = onAddValueToItems;
              props.onRemoveValue = onRemoveValueInItems;
            }

            if (column.type === 'formula') {
              props.onEditFormula = () => onEditFormula(column);
            }

            if (column.type === 'date') {
              props.scrollDomSelector = `tab-${sheet?._id}`;
            }

            if (column?.field === 'title') {
              props.showDetail = onShowItemDetail;
              props.onToggleSubitem = onToggleSubitem;
              props.onChange = onUpdateItem;

              if (sheet?.sub_boards?.length) {
                props.showExpand = true;
              }
            }

            return <ItemField {...props} />;
          };

          if (editable && column?.field === 'title') {
            col.footerFormatter = () => (
              <input
                ref={idxGroup === 0 ? inputAddItemRef : null}
                type='text'
                className='w-100 form-control  form-control-xs item-text-input'
                placeholder='+ Thêm dòng'
                onBlur={(e) => onAddItemInputBlur(group?.id, e)}
                onKeyDown={(e) => onAddItemInputKeyDown(group?.id, e)}
                onPasteCapture={(e) => onAddItemInputPasted(group?.id, e)}
              ></input>
            );
          }

          col.editable = (_cell, row) => {
            return row?.userHasEditItemPermission;
          };
          col.userItemEditable = (cell, row) => {
            return hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission;
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
          if (!hasViewColumnPermission) {
            col.formatter = () => null;
            col.classes = 'bg-gray-300';
          }

          sheetCols.push(col);
        }
      });
      if (configurable && !options?.hideColumnEditor) {
        sheetCols.push({
          accessor: '+',
          Header: '+',
          dataField: '',
          text: '+',
          headerClasses: 'sheet-column-header text-left',
          headerFormatter: (column, colIndex) => (
            <HeaderAddColumnFormatter
              column={column}
              colIndex={colIndex}
              onAdd={onAddColumnSubmit}
            />
          ),
          footer: '',
          editable: false,
        });
      } else {
        sheetCols.push({
          accessor: 'fake_column',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header flex-grow-1',
          headerFormatter: () => null,
          footer: '',
          editable: false,
        });
      }

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          footer: '',
          editable: false,
        },
      ];
    }
  };

  const showMessageResult = (res) => {
    if (res?.data?.success) {
      snackNotification.showSuccess(t('common_update_success'));
    } else {
      if (res.data.code === validationErrorCode) {
        snackNotification.showError(
          res?.data?.message
            ? getValidationErrorMessage(res?.data?.message)
            : t('common_update_error')
        );
      } else {
        snackNotification.showError(
          res?.data?.message ? t(res?.data?.message) : t('common_update_error')
        );
      }
    }
  };

  const getUpdateItemIds = (item) => {
    let selectedIds = Object.values(selectedItemsByGroups).flat();

    // if current item not in selectedIds => update one.
    let itemGroup = item?.group;
    if (!sheet.groups.find((group) => group.id === itemGroup)) {
      itemGroup = 'empty';
    }
    if (!selectedItemsByGroups?.[itemGroup]?.includes(item?._id)) {
      selectedIds = [item?._id];
    }
    return selectedIds;
  };

  const onAddValueToItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      addValueToItem && addValueToItem(item, field, value, callback ?? showMessageResult);
    } else {
      addValueToItems && addValueToItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onRemoveValueInItems = (item, field, value, callback) => {
    const itemIds = getUpdateItemIds(item);
    if (itemIds && itemIds.length === 1) {
      removeValueInItem && removeValueInItem(item, field, value, callback ?? showMessageResult);
    } else {
      removeValueInItems &&
        removeValueInItems(itemIds, field, value, callback ?? showMessageResult);
    }
  };

  const onSaveGroup = (group, field, oldValue, newValue) => {
    if (oldValue !== newValue) {
      onUpdateGroup && onUpdateGroup(group, field, oldValue, newValue);
    }
  };

  const handleMoveItem = (group, sheet, columnsMapping) => {
    onMoveItems &&
      onMoveItems(group, sheet, selectedItemsByGroups, columnsMapping, () => {
        setSelectedItemsByGroups({});
        setSelectedItemsDataByGroups({});
        snackNotification.showSuccess(t('common_move_success'));
      });
  };

  const handleRemoveItems = () => {
    onRemoveItems &&
      onRemoveItems(selectedItemsByGroups, (res) => {
        setSelectedItemsByGroups({});
        setSelectedItemsDataByGroups({});
        if (res.data.success) {
          snackNotification.showSuccess(t('common_remove_success'));
        } else {
          snackNotification.showError(t('common_remove_fail'));
        }
      });
  };

  const onHideToolbar = () => {
    setSelectedItemsByGroups({});
    setSelectedItemsDataByGroups({});
    setSubSelectItems({});
  };

  const showMoveGroupToSheet = (sheet, group) => {
    dispatch(getSheetAction({id: sheet?._id})).then((result) => {
      if (result?.data?.data) {
        let _sheet = result.data.data;
        setMoveToSheet(_sheet);
      }
    });
    setMoveGroup(group);
  };

  const handleMoveGroupToSheet = (columnsMapping) => {
    onMoveGroupToSheet(moveToSheet, moveGroup, columnsMapping, () => {
      snackNotification.showSuccess(t('common_move_success'));
      setMoveToSheet(null);
      setMoveGroup(null);
    });
  };

  const handleAddNewItemFull = (newItem) => {
    if (newItem?.title?.value === '') {
      snackNotification.showError(t('sheet_table_title_required'));
    } else if (!addingItem.current) {
      addingItem.current = true;
      addItemFull({...newItem, group: newItem?.group?.value ?? 'default'}, user, (result) => {
        if (result.success) {
          let newItem = result.data;
          setNewItems([newItem._id]);
          setShowModalAddNewItem(false);
        } else {
          if (result.code === validationErrorCode) {
            snackNotification.showError(
              result?.message
                ? getValidationErrorMessage(result?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              result?.message ? t(result?.message) : t('common_update_error')
            );
          }
        }
        addingItem.current = false;
      });
    }
  };

  const handleAddNewItem = () => {
    const itemAdditionMode = view?.settings?.item_addition_mode ?? 'dialog';

    if (itemAdditionMode === 'dialog') {
      setShowModalAddNewItem(true);
      return;
    }

    if (inputAddItemRef.current) {
      inputAddItemRef.current.focus();
      inputAddItemRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  };

  const onChangeColor = (color, group) => {
    onSaveGroup(group, 'settings', group.settings, {color: color});
  };

  const onMoveGroupToTop = (groupId) => {
    moveGroupToTop && moveGroupToTop(groupId);
  };

  const onMoveGroup = (groupId, direction) => {
    moveGroupPosition && moveGroupPosition(groupId, direction);
  };

  const expandRow = {
    renderer: (item, settings, isExpanded) => {
      return (
        <>
          {sheetMeta?.hasSubitems() && isExpanded(item?._id) && (
            <CardBody className='d-flex'>
              <div
                className='sheet-group-table-indicator ms-2'
                style={settings.indicatorStyle}
              ></div>
              <SheetSubitemsTableView
                parentItemId={item._id}
                parentSheetId={sheet?._id}
                parentView={view}
                members={sheet.members}
                sheetId={sheet.sub_boards?.[0] || ''}
                subSelectItems={subSelectItems}
                setSubSelectItems={setSubSelectItems}
                onSelectItem={(subItems) => {
                  setSubSelectItems(subItems);
                }}
                editable={editable}
                configurable={configurable}
                onOpenSubItemDetail={onOpenSubItemDetail}
              ></SheetSubitemsTableView>
            </CardBody>
          )}
        </>
      );
    },
  };

  const isReadOnly = () => {
    return !editable;
  };

  const canAddGroup = () => {
    return configurable;
  };

  const showAddButton = () => {
    return editable;
  };

  return (
    <>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheetId: sheetId,
            sheet: sheet,
            view: view,
            onAddNewGroup: onAddGroupClick,
            onSetting: () => onEditView(view),
            onExportExcel: onExportExcelConfirm,
            onAddNew: handleAddNewItem,
            onFilterApply: applyFilter,
            onFilterReset: resetFilters,
            activeFilters: activeFilters,
            filterActive: filterActive,
            saveFilter: onSaveFilter,
            onSearch: applySearch,
            onCreateGroupTasks: addGroupWithItems,
            addItemsAndSubItems: addItemsAndSubItems,
            options: options?.userActions,
            allowFilterByGroup: true,
            canAddGroup: canAddGroup(),
            showAddButton: showAddButton(),
            userSheetPermissions,
          })}
        </>
      )}

      {sheetGroups?.map((group, idxGroup) => {
        const groupId = group?.id;
        return (
          <Accordion
            key={sheet?._id + groupId}
            defaultActiveKey={groupStateExpanded(sheet?._id, view?.code, groupId) ? groupId : null}
          >
            <Card className='mb-4 group-container'>
              <div className='pb-3 border-bottom-0 d-flex align-items-center bg-body rounded-0 position-relative'>
                <CardHeaderTitle className='w-100 mb-0'>
                  <GroupHeader
                    t={t}
                    showMoveGroupToSheet={showMoveGroupToSheet}
                    group={group}
                    sheet={sheet}
                    setGroupToDelete={setGroupToDelete}
                    onSaveGroup={onSaveGroup}
                    onShowDialogChangeColor={setIdDialogChangeColorGroup}
                    onMoveGroupToTop={onMoveGroupToTop}
                    idxGroup={idxGroup}
                    onToggle={(type, action, params) =>
                      handleUserAction(type, action, {
                        ...params,
                        sheetId: sheet?._id,
                        viewId: view.code,
                      })
                    }
                    onMoveGroup={onMoveGroup}
                    readOnly={group?.id === 'empty'}
                    groups={sheetGroups}
                    options={options}
                  />
                </CardHeaderTitle>
                {idDialogChangeColorGroup === group?.id && (
                  <DialogColorGroup
                    group={group}
                    onChangeColor={onChangeColor}
                    onShowDialogChangeColor={setIdDialogChangeColorGroup}
                  />
                )}
              </div>
              <Accordion.Collapse eventKey={group?.id}>
                <CardBody className='d-flex'>
                  <div
                    className='sheet-group-table-indicator ms-2'
                    style={indicatorStyle(group)}
                  ></div>
                  <OGridViewV2
                    classes='table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1 sheet-table'
                    keyField='_id'
                    data={viewItemsByGroups?.[groupId] || []}
                    dragDrop={true}
                    moveRow={(row, currentIndex, toIndex) => {
                      onMoveItem && onMoveItem(row, currentIndex, toIndex, group?.id);
                    }}
                    columns={sheetColumns(group, idxGroup)}
                    selectedKeys={selectedItemsByGroups[group?.id]}
                    highlightKeys={highlightKeys()}
                    expandRow={
                      sheetMeta?.hasSubitems() && {
                        renderer: expandRow.renderer,
                        settings: {indicatorStyle: indicatorStyle(group)},
                      }
                    }
                    rowIndicatorStyle={indicatorStyle(group)}
                    showFooter={editable}
                  ></OGridViewV2>
                </CardBody>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
      {options?.showAddGroup && (
        <ShowForSheetPermission user={user} sheet={sheet} permission='board.edit'>
          <div className='pt-2 mb-5'>
            <button className='btn btn-sm btn-outline' onClick={onAddGroupClick}>
              + {t('sheet_add_group')}
            </button>
          </div>
        </ShowForSheetPermission>
      )}
      <ItemDetailView
        open={editItem !== null}
        item={editItem}
        onEditItem={setEditItem}
        sheet={sheet}
        view={view}
        onHide={() => onHideItemDetail()}
        onDelete={onDeleteItemInit}
        onUpdateItem={onUpdateItem}
        onSaveItemAction={onSaveItemAction}
        onMoveItem={onMoveItem}
        allowChangeGroup={viewChangeGroup.includes(view?.type)}
        readOnly={isReadOnly()}
        permissions={userSheetPermissions}
      />
      {editColumn && (
        <SheetEditColumn
          show={editColumn !== null}
          onHide={() => setEditColumn(null)}
          column={editColumn}
          onSubmit={onSaveColumn}
          sheet={sheet}
          linkableSheets={linkableSheets}
        ></SheetEditColumn>
      )}

      <ConfigUsersCanViewColumnDialog
        column={currentColumn}
        show={isShowConfigUsersCanViewColumnDialog}
        onHide={() => setIsShowConfigUsersCanViewColumnDialog(false)}
        onSubmit={onSaveColumn}
      />
      <Modal
        size='md'
        centered
        show={userEditColumn !== null}
        onHide={() => setUserEditColumn(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('sheet_column_user_edit_title') + ' ' + userEditColumn?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          {userEditColumn && (
            <ColumnUserPermittedEdit
              column={userEditColumn}
              sheetColumns={sheet.columns}
              onSubmit={onSaveColumn}
            ></ColumnUserPermittedEdit>
          )}
        </Modal.Body>
      </Modal>
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={columnToDelete !== null}
        onHide={() => setColumnToDelete(null)}
        title={t('sheet_column_delete_confirm_title')}
        content={t('sheet_column_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteColumnConfirm}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={groupToDelete !== null}
        onHide={() => setGroupToDelete(null)}
        title={t('sheet_group_delete_confirm_title')}
        content={t('sheet_group_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteGroupConfirm}
      />
      {!isEmpty(moveToSheet) && (
        <ModalMoveGroupToSheet
          show={moveToSheet !== null}
          onHide={() => setMoveToSheet(null)}
          onMoveGroup={handleMoveGroupToSheet}
          newSheet={moveToSheet}
          oldSheet={sheet}
        />
      )}
      {!isEmpty(subSelectItems) && (
        <ActionBar
          handleRemoveItems={handleRemoveSubItems}
          sheet={sheet}
          view={view}
          selectedItems={subSelectItems}
          onHide={onHideToolbar}
          enableActions={enableActions}
        />
      )}
      {!isEmpty(selectedItemsByGroups) && (
        <ActionBar
          handleMoveItem={handleMoveItem}
          handleRemoveItems={handleRemoveItems}
          handleAction={handleAction}
          sheet={sheet}
          view={view}
          selectedItems={selectedItemsByGroups}
          selectedItemsData={selectedItemsDataByGroups}
          onHide={onHideToolbar}
        />
      )}
      <ModalFormulaEditor
        show={editFormulaColumn !== null}
        onHide={() => setEditFormulaColumn(null)}
        onSave={onSaveFormula}
        metaColumns={sheetMeta.getColumnsByView(view)}
        column={editFormulaColumn}
      />
      {showModalAddNewItem && (
        <ModalAddNewItem
          sheet={sheet}
          view={view}
          sheetItemPermissions={userSheetPermissions}
          currentView={view}
          sheetGroups={sheetGroups}
          show={showModalAddNewItem}
          configurable={configurable}
          onSave={handleAddNewItemFull}
          onHide={() => setShowModalAddNewItem(false)}
        />
      )}
    </>
  );
}

const GroupHeader = ({
  t,
  showMoveGroupToSheet,
  group,
  sheet,
  setGroupToDelete,
  onSaveGroup,
  onShowDialogChangeColor,
  idxGroup,
  onMoveGroupToTop,
  onMoveGroup,
  onToggle,
  types,
  actions,
  readOnly,
  groups,
  options,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDialogMoveToBoard, setShowDialogMoveToBoard] = useState(false);
  const {user} = useSession();
  const sheetEditable = hasSheetPermission(sheet, user, 'board.edit');

  const isShowMoveGroupDown = (index) => {
    if (index >= 0) {
      let nextGroup = groups[index + 1];
      if (nextGroup && nextGroup.id !== 'empty') {
        return true;
      }
    }
    return false;
  };

  const renderSettingDropdown = () => {
    if (readOnly) {
      return null;
    }

    return (
      <ShowForSheetPermission sheet={sheet} user={user} permission={'board.edit'}>
        <Dropdown
          className='dropdown-inline group-header-tool'
          align='end'
          autoclose='true'
          show={showMenu}
          onToggle={() => {
            setShowMenu(!showMenu);
          }}
        >
          <Dropdown.Toggle
            id='dropdown-toggle-top'
            as={DropdownCustomToggler}
            onMouseEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <i className='ki ki-bold-more-hor' />
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu dropdown-menu-xs dropdown-menu-right p-0 min-w-200px shadow-lg'>
            <div
              className='d-flex flex-column p-2'
              onMouseEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onMouseLeave={() => setShowMenu(false)}
            >
              <div className='d-flex align-items-center px-2 py-3 bg-hover-light rounded item-dropdown'>
                <span className='svg-icon svg-icon-md svg-icon-primary cursor-pointer ps-1 pe-3 w-30px'>
                  <i className='las la-arrow-circle-right fs-3'></i>
                </span>
                <span className='fw-normal'>{t('sheet_move_menu_item_move_to_group')}</span>
                <i className='fa-solid fa-angle-right icon-submenu'></i>
                <div className='sub-menu-dropdown shadow rounded min-w-200px menu-hover bg-body'>
                  {!showDialogMoveToBoard ? (
                    <div className='d-flex flex-column p-2 '>
                      <div
                        className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded item-dropdown fw-normal'
                        onClick={() => setShowDialogMoveToBoard(true)}
                      >
                        {t('sheet_move_menu_item_move_to_group_sub_menu')}
                      </div>
                      {idxGroup > 0 && (
                        <div>
                          <div
                            className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded item-dropdown fw-normal'
                            onClick={() => onMoveGroupToTop(group?.id)}
                          >
                            {t('sheet_move_menu_item_move_to_top')}
                          </div>
                          <div
                            className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded item-dropdown fw-normal'
                            onClick={() => onMoveGroup(group?.id, 'up')}
                          >
                            {t('sheet_move_menu_item_move_up')}
                          </div>
                        </div>
                      )}
                      {isShowMoveGroupDown(idxGroup) && (
                        <div
                          className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded item-dropdown fw-normal'
                          onClick={() => onMoveGroup(group?.id, 'down')}
                        >
                          {t('sheet_move_menu_item_move_down')}
                        </div>
                      )}
                    </div>
                  ) : (
                    <DialogMoveToSheet
                      onHide={() => setShowDialogMoveToBoard(false)}
                      onMoveGroupToSheet={showMoveGroupToSheet}
                      group={group}
                      sheet={sheet}
                    />
                  )}
                </div>
              </div>
              <div
                className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded'
                onClick={() => setGroupToDelete(group)}
              >
                <span className='svg-icon svg-icon-md svg-icon-primary cursor-pointer ps-1 pe-3 w-30px'>
                  <i className='las la-trash-alt fs-3'></i>
                </span>
                <span className='fw-normal'>{t('sheet_remove_group')}</span>
              </div>
              <Dropdown.Item as='div' className='p-0 bg-hover-none'>
                <div
                  className='d-flex align-items-center cursor-pointer px-2 py-3 bg-hover-light rounded'
                  onClick={() => {
                    onShowDialogChangeColor(group?.id);
                  }}
                >
                  <div className='svg-icon svg-icon-md svg-icon-primary cursor-pointer ps-2 pe-4 w-30px flex-shrink-0'>
                    <div
                      className='w-15px h-15px rounded-circle color-group-current'
                      style={{background: group?.settings?.color || '#3699FF'}}
                    ></div>
                  </div>
                  <span>{t('sheet_change_color_group')}</span>
                </div>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </ShowForSheetPermission>
    );
  };
  return (
    <div
      className='d-flex align-items-center group-header bg-body'
      onClick={(e) => e.stopPropagation()}
    >
      {!options?.hideColumnEditor && renderSettingDropdown()}
      <CustomToggle
        eventKey={group?.id}
        color={group?.settings?.color || '#3699FF'}
        onToggle={onToggle}
        types={types}
        actions={actions}
        sheetId={sheet?._id}
      ></CustomToggle>
      <input
        type='text'
        className='form-control form-control-xs group-text-input ms-2 flex-shrink'
        style={{
          color: group?.settings?.color || '#3699FF',
          caretColor: group?.settings?.color || '#3699FF',
        }}
        onClick={(e) => e.stopPropagation()}
        defaultValue={group?.title}
        placeholder='Nhóm'
        onBlur={(e) => {
          onSaveGroup(group, 'title', group?.title, e.target.value);
        }}
        disabled={!sheetEditable || readOnly}
      ></input>
    </div>
  );
};

function CustomToggle({eventKey, color, onToggle}) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    onToggle(ObjectTypes.board, UiActions.board.toggleGroup, {
      groupId: eventKey,
    });
  });

  return (
    <span onClick={decoratedOnClick}>
      <i className='fas fa-chevron-right group-icon-collapse' style={{color: color}}></i>
    </span>
  );
}
