import './sheetTableView.scss';
import './sheetTablePagingView.scss';

import {CardBody, PaginationLinks, PaginationToolbar} from 'app/common/_partials/controls';
import {
  HeaderAddColumnFormatter,
  HeaderCheckboxColumnFormatter,
  HeaderFormatter,
} from './ColumnHeader';
import {
  hasEditItemColumnPermission,
  hasEditItemPermission,
  hasSheetPermission,
  hasViewSheetColumnPermission,
} from '../../../permission/permissionHelper';
import {isArray, isEmpty} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';

import {ActionBar} from '../../action-bar/ActionBar';
import ColumnUserPermittedEdit from '../../sheet-config/column/ColumnUserPermittedEdit';
import ConfigUsersCanViewColumnDialog from 'app/modules/work/sheet/permission/ConfigUsersCanViewColumnDialog';
import ItemDetailView from '../../item-detail/ItemDetailView';
import ItemField from '../../field/ItemField';
import {Modal} from 'react-bootstrap';
import ModalAddNewItem from './modal/ModalAddNewItem';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import ModalFormulaEditor from '../../formula-editor/ModalFormulaEditor';
import {OGridViewV2} from 'app/modules/work/components/OGridViewV2';
import SheetAddColumn from '../../sheet-config/column/SheetAddColumn';
import SheetEditColumn from '../../sheet-config/column/SheetEditColumn';
import SheetSubitemsTableView from './SheetSubitemsTableView';
import {getTranslationTextByField} from './helper';
import {useParams} from 'react-router-dom';
import {useSession} from 'core/store/core/hooks';
import {useSheetData} from '../../../SheetDataHook';
import {useSheetPermission} from '../../../SheetPermissionHook';
import useSheetTableHook from './SheetTableHook';
import {useSheetViewEvent} from '../SheetViewEventHook';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../../validation/useValidation';
import {validationErrorCode} from '../../../validation/constants';

export default function SheetPagingTableView({
  sheetId,
  sheet,
  setSheet,
  view,
  onSaveFilter,
  sheetMeta,
  onEditView,
  options = {
    showToolbar: true,
    showAddGroup: true,
    navigateToItem: true,
    hideColumnEditor: false,
  },
  configurable,
  editable,
  renderToolbar,
  linkableSheets,
}) {
  const {t} = useTranslate();

  const {getValidationErrorMessage} = useValidation({});
  const {itemId} = useParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);

  const addingItem = useRef(false);
  const {user, getDomain} = useSession();
  const [subSelectItems, setSubSelectItems] = useState({});
  const [showModalAddNewItem, setShowModalAddNewItem] = useState(false);
  const enableActionsSubitems = ['remove_item'];
  const enableActions = ['remove_item', 'move_item', 'move_item:move_to_sheet', 'tools'];
  const {userSheetPermissions} = useSheetPermission({sheetId});
  const {
    requestFetchSheetItem,
    fetchSheetItems,
    sheetItemList,
    addItemFull,
    updateItem: onUpdateItem,
    doAction: onDoAction,
    updateItems: onUpdateItems,
    addValueToItems,
    removeValueInItems,
    deleteItem: onDeleteItem,
    removeItems: onRemoveItems,
    removeSubItems: onRemoveSubItems,
    moveItems: onMoveItems,
    exportExcel: onExportExcel,
    moveItem: onMoveItem,
    tools,
    totalItems,
    paging,
    sheetGroups,
    setPaging,
    applyFilter,
    resetFilters,
    activeFilters,
    filterActive,
    applySearch,
    addItemsAndSubItems,
    userViewableColumns,
    addValueToItem,
    removeValueInItem,
  } = useSheetData({id: sheetId, sheet, setSheet, view, user, isRemote: true});

  const {
    setNewItems,
    editColumn,
    setEditColumn,
    onEditColumn,
    onEditFormula,
    editFormulaColumn,
    setEditFormulaColumn,
    onSaveFormula,
    onSaveColumn,
    userEditColumn,
    setUserEditColumn,
    onOpenPermittedEditColumn,
    onToggleColumnPermitted,
    onOpenUsersCanViewColumnDialog,
    currentColumn,
    isShowConfigUsersCanViewColumnDialog,
    setIsShowConfigUsersCanViewColumnDialog,
    onDeleteColumnClick,
    onDeleteColumnConfirm,
    onExportExcelConfirm,
    onShowItemDetail,
    onSelectAllClick,
    onSelectItem,
    editItem,
    setEditItem,
    itemToDelete,
    setItemToDelete,
    columnToDelete,
    setColumnToDelete,
    showAddColumn,
    setShowAddColumn,
    onHideItemDetail,
    onUpdateValidation,
    onSaveItem,
    onSaveItemAction,
    onAddValueToItems,
    onRemoveValueInItems,
    onDeleteItemInit,
    onDeleteItemConfirm,
    onAddColumnClick,
    onAddColumnSubmit,
    snackNotification,
    handleMoveItem,
    handleRemoveItems,
    handleRemoveSubItems,
    onHideToolbar,
    handleAction,
    highlightKeys,
    indicatorStyle,
  } = useSheetTableHook({
    sheetMeta,
    onExportExcel,
    options,
    sheet,
    view,
    sheetItemList,
    onUpdateItem,
    onUpdateItems,
    onDoAction,
    addValueToItems,
    removeValueInItems,
    addValueToItem,
    removeValueInItem,
    onDeleteItem,
    onMoveItems,
    onRemoveItems,
    onRemoveSubItems,
    subSelectItems,
    setSubSelectItems,
    tools,
    selectedItems,
    setSelectedItems,
    selectedItemsData,
    setSelectedItemsData,
  });

  const eventHandler = useSheetViewEvent();

  useEffect(() => {
    if (sheetId) {
      eventHandler.initialize({
        domain: getDomain(),
        sheetId,
        onSheetItemUpdate: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemCreated: ({itemId}) => requestFetchSheetItem(itemId),
        onSheetItemDeleted: ({itemId}) => requestFetchSheetItem(itemId),
      });
    }
  }, [sheetId]);

  useEffect(() => {
    if (sheetId) {
      fetchSheetItems();
    }
  }, [sheetId, paging]);

  useEffect(() => {
    if (sheetId && activeFilters) {
      if (paging?.pageNumber === 1) {
        fetchSheetItems();
      } else {
        setPaging((prev) => ({
          ...prev,
          pageNumber: 1,
        }));
      }
    }
  }, [activeFilters]);

  useEffect(() => {
    let itemDetailId = itemId ?? editItem?._id;
    if (itemDetailId && sheetItemList) {
      let foundItems = sheetItemList.filter((x) => x._id === itemDetailId);
      if (foundItems.length > 0) {
        setEditItem(foundItems[0]);
      }
    }
  }, [itemId, sheetItemList]);

  const isSelectAll = useCallback(() => {
    if (sheetItemList.length <= 0) return false;
    return sheetItemList.every((item) => selectedItems.includes(item?._id));
  }, [sheetItemList, selectedItems]);

  const sheetColumns = () => {
    let columns = userViewableColumns;

    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }

    if (columns) {
      const sheetCols = [];
      if (editable) {
        sheetCols.push({
          accessor: 'checkbox',
          header: 'checkbox',
          text: 'checkbox',
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center justify-content-center',
          headerClasses:
            'sheet-column-header text-center justify-content-center w-40px d-flex align-items-center',
          headerFormatter: (column, colIndex) =>
            HeaderCheckboxColumnFormatter(column, colIndex, {
              selectAll: (e) => onSelectAllClick(e),
              totalSelectedItems: selectedItems?.length,
              totalItemInGroup: sheetItemList.length,
              checked: isSelectAll(),
            }),
          footer: '',
          formatter: (cell, row, rowIndex) => (
            <ItemField
              value={selectedItems?.includes(row._id) || false}
              onChange={(e) => onSelectItem(e, row)}
              column={{type: 'checkbox'}}
            />
          ),
        });
      }

      if (view?.settings?.show_line_number) {
        sheetCols.push({
          accessor: 'sheet_view_table_line_number',
          header: t('sheet_view_table_line_number'),
          text: t('sheet_view_table_line_number'),
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center',
          headerClasses: 'sheet-column-header text-center w-40px d-flex align-items-center',
          footer: '',
          formatter: (cell, row, rowIndex) => rowIndex + 1,
        });
      }

      let visibleTitleColumn = columns.find(
        (column) =>
          !column?.deleted &&
          !column?.hidden &&
          (column?.field === 'title' || column?.is_title === true)
      );
      //Show action field if there is no visible title
      if (!visibleTitleColumn) {
        let actionColumnProps = {
          column: {type: 'action'},
          sheet: sheet,
        };
        actionColumnProps.showDetail = onShowItemDetail;

        if (sheet?.sub_boards?.length) {
          actionColumnProps.showExpand = true;
        }

        sheetCols.push({
          accessor: 'sheet_view_table_action',
          header: '',
          text: '',
          dataField: '',
          classes: 'text-center w-40px d-flex align-items-center',
          headerClasses: 'sheet-column-header text-center w-40px d-flex align-items-center',
          formatter: (cell, row, rowIndex, onToggleSubitem) => (
            <ItemField
              {...actionColumnProps}
              item={row}
              onToggleSubitem={onToggleSubitem}
              row={row}
            />
          ),
        });
      }

      columns.forEach((tableColumn) => {
        let column = {
          ...tableColumn,
        };

        // mapping columns values if it has type select
        if (column?.values && isArray(column?.values)) {
          column.values = column.values.map((item) => {
            return {...item, value: item?.value || 'default'};
          });
        }

        if (!column.deleted && !column.hidden) {
          const col = {
            accessor: column.field,
            header: column?.name || getTranslationTextByField(t, column?.field),
            text: column?.name || getTranslationTextByField(t, column?.field),
            classes: '',
            headerClasses: 'sheet-column-header text-center text-nowrap ',
            headerFormatter: (column, colIndex, style) => (
              <HeaderFormatter
                column={column}
                colIndex={colIndex}
                editable={
                  hasSheetPermission(sheet, user, 'board.edit') && !options?.hideColumnEditor
                }
                onEdit={() => onEditColumn(column)}
                onToggleColumnPermitted={() => onToggleColumnPermitted(column)}
                onOpenPermittedEditColumn={() => onOpenPermittedEditColumn(column)}
                onSaveColumn={onSaveColumn}
                onDelete={() => onDeleteColumnClick(column)}
                sheet={sheet}
                user={user}
                onEditFormula={() => onEditFormula(column?.column)}
                onUpdateValidation={onUpdateValidation}
                onOpenUsersCanViewColumnDialog={onOpenUsersCanViewColumnDialog}
                style={style}
              />
            ),
            column: column,
            footer: '',
            formatExtraData: {
              field: column.field,
              onChange: onSaveItem,
            },
            formatter: (cell) => {},
            editable: false,
          };
          col.formatter = (cell, row, rowIndex, onToggleSubitem) => {
            let props = {
              value: cell,
              item: row,
              column: column,
              sheet: sheet,
              onChange: onSaveItem,
              onAction: onSaveItemAction,
              fieldEditable:
                hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission,
            };

            if (column.type === 'people') {
              props.onAddValue = onAddValueToItems;
              props.onRemoveValue = onRemoveValueInItems;
            }

            if (column.type === 'formula') {
              props.onEditFormula = () => onEditFormula(column);
            }

            if (column.type === 'date') {
              props.scrollDomSelector = `tab-${sheet?._id}`;
            }

            if (column?.field === 'title') {
              props.showDetail = onShowItemDetail;
              props.onToggleSubitem = onToggleSubitem;
              props.onChange = onUpdateItem;

              if (sheet?.sub_boards?.length) {
                props.showExpand = true;
              }
            }

            return <ItemField {...props} />;
          };

          col.editable = (cell, row) => {
            return row?.userHasEditItemPermission;
          };
          col.hasEditItemPermission = (cell, row) => {
            return hasEditItemColumnPermission(user, column, row) && row?.userHasEditItemPermission;
          };

          if (column.styles) {
            if (column.styles.width) {
              col.style = {
                width: column.styles.width,
              };
              col.headerStyle = {
                width: column.styles.width,
              };
            }
          }

          const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
          if (!hasViewColumnPermission) {
            col.formatter = () => null;
            col.classes = 'bg-gray-300';
          }

          sheetCols.push(col);
        }
      });
      if (configurable && !options?.hideColumnEditor) {
        sheetCols.push({
          accessor: '+',
          Header: '+',
          dataField: '',
          text: '+',
          headerClasses: 'sheet-column-header text-left',
          headerFormatter: (column, colIndex) => (
            <HeaderAddColumnFormatter
              column={column}
              colIndex={colIndex}
              onAdd={onAddColumnSubmit}
            />
          ),
          footer: '',
          editable: false,
        });
      } else {
        sheetCols.push({
          accessor: 'fake_column',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header flex-grow-1',
          headerFormatter: () => null,
          footer: '',
          editable: false,
        });
      }

      return sheetCols;
    } else {
      return [
        {
          accessor: '',
          Header: '',
          dataField: '',
          text: '',
          headerClasses: 'sheet-column-header text-left',
          footer: '',
          editable: false,
        },
      ];
    }
  };

  const handleShowModalAddNewItem = () => {
    setShowModalAddNewItem(true);
  };

  const handleAddNewItem = (newItem) => {
    if (newItem?.title?.value === '') {
      snackNotification.showError(t('sheet_table_title_required'));
    } else if (!addingItem.current) {
      addingItem.current = true;
      addItemFull({...newItem, group: newItem?.group?.value ?? 'default'}, user, (result) => {
        if (result.success) {
          let newItem = result.data;
          setNewItems([newItem._id]);
          setShowModalAddNewItem(false);
        } else {
          if (result.code === validationErrorCode) {
            snackNotification.showError(
              result?.message
                ? getValidationErrorMessage(result?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              result?.message ? t(result?.message) : t('common_update_error')
            );
          }
        }
        addingItem.current = false;
      });
    }
  };

  const paginationProps = {
    ...paging,
    custom: true,
    sizePerPageList: [
      {text: '10', value: 10},
      {text: '20', value: 20},
    ],
    pageSize: 10,
    pageNumber: 1,
    paginationSize: 3,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total'>
        Hiển thị {from} đến {to}/<b>{size}</b> Kết quả
      </span>
    ),
    totalSize: totalItems,
    sizePerPage: paging.pageSize,
    page: paging.pageNumber,
    dataSize: totalItems,
    pageStartIndex: 1,
    onPageChange: (page) => {
      setPaging((prev) => ({
        ...prev,
        pageNumber: page,
        page: page,
      }));
    },
  };

  const expandRow = {
    renderer: (item, settings, isExpanded) => {
      return (
        <>
          {sheetMeta?.hasSubitems() && isExpanded(item?._id) && (
            <CardBody className='d-flex'>
              <div
                className='sheet-group-table-indicator ms-2'
                style={settings.indicatorStyle}
              ></div>
              <SheetSubitemsTableView
                parentItemId={item._id}
                parentSheetId={sheet?._id}
                parentView={view}
                members={sheet.members}
                sheetId={sheet.sub_boards?.[0] || ''}
                subSelectItems={subSelectItems}
                setSubSelectItems={setSubSelectItems}
                onSelectItem={(subItems) => {
                  setSubSelectItems(subItems);
                }}
                editable={editable}
                configurable={configurable}
                editItem={editItem}
                setEditItem={setEditItem}
              ></SheetSubitemsTableView>
            </CardBody>
          )}
        </>
      );
    },
  };

  const isReadOnly = () => {
    return !editable;
  };

  const showAddButton = () => {
    return editable;
  };

  return (
    <>
      {options?.showToolbar && renderToolbar && (
        <>
          {renderToolbar({
            sheetId: sheetId,
            sheet: sheet,
            view: view,
            key: sheet?._id + view.id,
            onSetting: () => onEditView(view),
            onAddNew: handleShowModalAddNewItem,
            onFilterApply: applyFilter,
            onFilterReset: resetFilters,
            activeFilters: activeFilters,
            filterActive: filterActive,
            saveFilter: onSaveFilter,
            onSearch: applySearch,
            mode: 'edit',
            onExportExcel: onExportExcelConfirm,
            options: options?.userActions,
            addItemsAndSubItems: addItemsAndSubItems,
            showAddButton: showAddButton(),
            userSheetPermissions,
          })}
        </>
      )}

      <CardBody className='d-flex'>
        <OGridViewV2
          classes='data-grid-pagination table-bordered table-row-bordered table-sm sheet-group-table flex-grow-1 sheet-table'
          keyField='_id'
          data={sheetItemList || []}
          dragDrop={true}
          moveRow={(row, currentIndex, toIndex) => {
            onMoveItem && onMoveItem(row, currentIndex, toIndex);
          }}
          columns={sheetColumns()}
          selectedKeys={selectedItems}
          highlightKeys={highlightKeys()}
          expandRow={
            sheetMeta?.hasSubitems() && {
              renderer: expandRow.renderer,
              settings: {indicatorStyle: indicatorStyle()},
            }
          }
          showFooter={false}
          noDataText={t('sheet_table_default_no_data_text')}
          rowIndicatorStyle={indicatorStyle()}
        ></OGridViewV2>
      </CardBody>
      <div className='d-flex justify-content-between align-items-center flex-wrap position-sticky bottom-0 start-0 bg-body'>
        <PaginationLinks paginationProps={paginationProps} />
        <div></div>
        <PaginationToolbar isLoading={false} paginationProps={paginationProps} />
      </div>

      <ItemDetailView
        open={editItem !== null}
        item={editItem}
        sheet={sheet}
        permissions={userSheetPermissions}
        view={view}
        onHide={() => onHideItemDetail()}
        onDelete={onDeleteItemInit}
        onUpdateItem={onUpdateItem}
        onSaveItemAction={onSaveItemAction}
        readOnly={isReadOnly()}
      />
      {editColumn && (
        <SheetEditColumn
          show={editColumn !== null}
          onHide={() => setEditColumn(null)}
          column={editColumn}
          onSubmit={onSaveColumn}
          sheet={sheet}
          linkableSheets={linkableSheets}
        ></SheetEditColumn>
      )}
      <Modal size='md' centered show={showAddColumn} onHide={() => setShowAddColumn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sheet_column_add_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SheetAddColumn onSubmit={onAddColumnSubmit}></SheetAddColumn>
        </Modal.Body>
      </Modal>
      <Modal
        size='md'
        centered
        show={userEditColumn !== null}
        onHide={() => setUserEditColumn(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('sheet_column_user_edit_title') + ' ' + userEditColumn?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          {userEditColumn && (
            <ColumnUserPermittedEdit
              column={userEditColumn}
              sheetColumns={sheet.columns}
              onSubmit={onSaveColumn}
            ></ColumnUserPermittedEdit>
          )}
        </Modal.Body>
      </Modal>
      <ConfigUsersCanViewColumnDialog
        column={currentColumn}
        show={isShowConfigUsersCanViewColumnDialog}
        onHide={() => setIsShowConfigUsersCanViewColumnDialog(false)}
        onSubmit={onSaveColumn}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={itemToDelete !== null}
        onHide={() => setItemToDelete(null)}
        title={t('sheet_item_delete_confirm_title')}
        content={t('sheet_item_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteItemConfirm}
      />
      <ModalConfirm
        centered
        style={{zIndex: 1400}}
        show={columnToDelete !== null}
        onHide={() => setColumnToDelete(null)}
        title={t('sheet_column_delete_confirm_title')}
        content={t('sheet_column_delete_confirm')}
        confirmVariant='danger'
        handleExcute={onDeleteColumnConfirm}
      />
      {showModalAddNewItem && (
        <ModalAddNewItem
          view={view}
          sheet={sheet}
          sheetItemPermissions={userSheetPermissions}
          currentView={view}
          sheetGroups={sheetGroups}
          show={showModalAddNewItem}
          configurable={configurable}
          onSave={handleAddNewItem}
          onHide={() => setShowModalAddNewItem(false)}
        />
      )}

      {!isEmpty(subSelectItems) && (
        <ActionBar
          handleRemoveItems={handleRemoveSubItems}
          sheet={sheet}
          view={view}
          selectedItems={subSelectItems}
          onHide={onHideToolbar}
          enableActions={enableActionsSubitems}
        />
      )}
      {!isEmpty(selectedItems) && (
        <ActionBar
          handleMoveItem={handleMoveItem}
          handleRemoveItems={handleRemoveItems}
          handleAction={handleAction}
          sheet={sheet}
          view={view}
          selectedItems={selectedItems}
          selectedItemsData={selectedItemsData}
          onHide={onHideToolbar}
          enableActions={enableActions}
        />
      )}
      <ModalFormulaEditor
        show={editFormulaColumn !== null}
        onHide={() => setEditFormulaColumn(null)}
        onSave={onSaveFormula}
        metaColumns={sheetMeta.getColumnsByView(view)}
        column={editFormulaColumn}
      />
    </>
  );
}
