import {useTranslate} from 'core/i18n/i18nProvider';
import {useState} from 'react';
import './sheetAddColumn.scss';
import {removeSign} from 'app/common/_helpers';
import {listColumnEssentials, listColumnsSuperUseful} from '../../view/table/constants';

export default function SheetAddColumn({onSubmit}) {
  const {t} = useTranslate();
  const [columnsSuperUseful, setColumnsSuperUseful] = useState(listColumnsSuperUseful);
  const [columnsEssentials, setColumnsEssentials] = useState(listColumnEssentials);

  const initColumn = {
    name: '',
    type: 'text',
    styles: {
      width: '100px',
    },
    reference: {
      value: '',
      label: '',
    },
    steps: [
      {
        id: '1',
        step_type: 'serial',
        assignee_role: '1',
        name: 'Tạo',
        actionCreateApproval: true,
      },
      {
        id: '2',
        step_type: 'serial',
        assignee_role: '2',
        name: 'Bước duyệt',
        completion_time: 1,
        actionReject: true,
        actionReopen: true,
        actionApprove: true,
      },
    ],
    assignee_role_users: [
      {
        id: '1',
        title: '',
      },
      {
        id: '2',
        title: '',
      },
    ],
    timeline: 'date',
    settings: {
      hasTime: false,
    },
  };

  const onSearchColumn = (value) => {
    const searchText = removeSign(String(value).toLowerCase());

    if (!searchText) {
      setColumnsSuperUseful(listColumnsSuperUseful);
      setColumnsEssentials(listColumnEssentials);
      return;
    }

    let newColumnSuperUseful = [...columnsSuperUseful];
    newColumnSuperUseful = newColumnSuperUseful.filter((columns) => {
      let columnName = t(columns?.name).toLowerCase();
      columnName = removeSign(columnName);
      return columnName.includes(searchText);
    });

    let newColumnEssentials = [...columnsEssentials];
    newColumnEssentials = newColumnEssentials.filter((columns) => {
      let columnName = t(columns?.name).toLowerCase();
      columnName = removeSign(columnName);
      return columnName.includes(searchText);
    });

    setColumnsSuperUseful(newColumnSuperUseful);
    setColumnsEssentials(newColumnEssentials);
  };

  const onAddColumn = (column) => {
    let params = {
      ...initColumn,
      name: t(column?.name),
      type: column.value,
    };
    onSubmit && onSubmit(params);
  };

  return (
    <div className='w-450px p-5 d-flex flex-column'>
      <input
        className='form-control'
        placeholder={t('course_message_search_place_holder')}
        onChange={(e) => onSearchColumn(e.target.value)}
      />
      <div className='mt-4'>
        {columnsEssentials.length > 0 && (
          <>
            <p className='mb-2 ms-4 mt-2 text-gray-600'>{t('sheet_column_list_essentials')}</p>
            <div className='d-flex flex-wrap justify-content-between'>
              {columnsEssentials.map((column, index) => (
                <div
                  className='gap-2 p-2 btn-column'
                  onClick={() => onAddColumn(column)}
                  key={index}
                >
                  <div className='w-30 d-flex justify-content-center align-items-center p-2'>
                    <i className={column?.icon}></i>
                  </div>
                  <span className='text-wrap btn-column-text'>{t(`${column?.name}`)}</span>
                </div>
              ))}
            </div>
          </>
        )}
        {columnsSuperUseful.length > 0 && (
          <>
            <p className='mb-2 ms-4 mt-5 text-gray-600'>{t('sheet_column_list_super_useful')}</p>
            <div className='d-flex flex-wrap justify-content-between'>
              {columnsSuperUseful.map((column, index) => (
                <div
                  className='gap-2 p-2 btn-column'
                  onClick={() => onAddColumn(column)}
                  key={index}
                >
                  <div className='w-30 d-flex justify-content-center align-items-center p-2'>
                    <i className={column?.icon}></i>
                  </div>
                  <span className='text-wrap btn-column-text'>{t(`${column?.name}`)}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
