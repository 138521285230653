import {format, fromUnixTime, isValid} from 'date-fns';
import get from 'lodash/get';
import {vi} from 'date-fns/locale';

/**
 * @function getLabelByField
 * @description Get label by field type and field value
 * @param {any} fieldValue - value of the field
 * @param {string} fieldType - type of the field
 * @param {object} item - item object
 * @returns {string} label of the field
 */
export const getLabelByField = (t, fieldValue, fieldType, item) => {
  if (!fieldValue || !fieldType) {
    return '';
  }

  if (fieldType?.includes('status')) {
    return get(item, [fieldType, 'label'], '');
  }

  if (fieldType?.includes('priority')) {
    return get(item, [fieldType, 'label'], '');
  }

  if (fieldType?.includes('geolocation')) {
    const latitude = get(fieldValue, ['latitude'], '');
    const longitude = get(fieldValue, ['longitude'], '');
    if (latitude && longitude) {
      return `${t('sheet_view_calendar_item_latitude')}: ${latitude}\n${t(
        'sheet_view_calendar_item_longitude'
      )}: ${longitude}`;
    }

    return '';
  }

  if (fieldType?.includes('date')) {
    return isValid(fromUnixTime(fieldValue))
      ? format(fromUnixTime(fieldValue), 'dd-MM-yyyy, HH:mm')
      : '';
  }

  if (fieldType?.includes('timeline')) {
    if (!fieldValue?.startDate && !fieldValue?.endDate) {
      return '';
    }

    const formattedStartDate = isValid(fromUnixTime(fieldValue?.startDate))
      ? format(fromUnixTime(fieldValue?.startDate), 'd MMM', {locale: vi})
      : '';
    const formattedEndDate = isValid(fromUnixTime(fieldValue?.endDate))
      ? format(fromUnixTime(fieldValue?.endDate), 'd MMM', {locale: vi})
      : '';

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  if (Array.isArray(fieldValue)) {
    if (fieldType?.includes('people')) {
      return get(fieldValue, [0, 'name'], '');
    }

    if (fieldType?.includes('department')) {
      return get(fieldValue, [0, 'name'], '');
    }
  }

  return fieldValue;
};
