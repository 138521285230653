import './approvalField.scss';

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo} from 'react';

import {APPROVAL_STATUS} from './contants';
import ApprovalAction from './ApprovalAction';
import ApprovalDue from './ApprovalDue';
import ApprovalPeople from './ApprovalPeople';
import ApprovalStatus from './ApprovalStatus';
import clsx from 'clsx';
import {effectiveValue} from 'app/modules/work/helpers';
import { isArray } from 'lodash';

export const ApprovalField = ({
  value,
  item,
  column,
  members,
  onChange,
  onAction,
  fieldEditable,
  readOnly,
  containerClass,
}) => {
  const effValue = effectiveValue(value);
  const createdBy = effectiveValue(item?.created_by);
  const stepValue = useMemo(() => {
    if (value?.status === APPROVAL_STATUS.APPROVED) {
      return column.steps?.[value?.steps?.length - 1];
    }
    return column.steps?.[value?.steps?.length || 0] || column.steps?.at(-1);
  }, [column.steps, value?.status, value?.steps?.length]);

  const onApproveStep = (action) => {
    const params = {
      ...action,
      itemId: item._id,
      sheetId: item.sheetId,
      type: 'approval',
      column: column.field,
    };
    onAction(item, params);
  };

  const approvalNotDate =
    !stepValue?.completion_time ||
    value?.status !== APPROVAL_STATUS.REJECTED ||
    value?.status !== APPROVAL_STATUS.APPROVED;

  if (!effValue) return <></>;
  if (effValue?.length > 1) {
    return (
      <div className='list-approval-container'>
        {isArray(effValue) && effValue?.map((step, index) => {
          const stepValue = column.steps?.find((process) => process.id === step?.id);
          return (
            <div
              key={step?.id}
              className={`approval-container ${clsx({
                'approval-not-date': approvalNotDate,
              })}`}
            >
              <div className='approval-column'>
                <ApprovalStatus value={stepValue} effValue={effValue} />
              </div>
              <div className='approval-column'>
                <ApprovalPeople
                  value={stepValue}
                  assigneeRoleUsers={column?.assignee_role_users}
                  effValue={effValue}
                  item={item}
                  column={column}
                  itemValue={value}
                />
              </div>
              <div className='approval-column'>
                {approvalNotDate && <ApprovalDue value={stepValue} effValue={effValue} />}
              </div>
              <div className='approval-column'>
                <ApprovalAction
                  itemValue={value}
                  value={stepValue}
                  onApproveStep={onApproveStep}
                  effValue={effValue}
                  steps={column.steps}
                  currentStepItem={step}
                  column={column}
                  assigneeRoleUsers={column?.assignee_role_users}
                  createdBy={createdBy}
                  members={members}
                  item={item}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <div
        className={`approval-container ${clsx({
          'approval-not-date': approvalNotDate,
        })}`}
      >
        <div className='approval-column'>
          <ApprovalStatus value={stepValue} effValue={effValue} />
        </div>
        <div className='approval-column'>
          <ApprovalPeople
            value={stepValue}
            assigneeRoleUsers={column?.assignee_role_users}
            effValue={effValue}
            item={item}
            column={column}
            itemValue={value}
          />
        </div>
        <div className='approval-column'>
          {approvalNotDate && <ApprovalDue value={stepValue} effValue={effValue} />}
        </div>
        <div className='approval-column'>
          <ApprovalAction
            itemValue={value}
            value={stepValue}
            onApproveStep={onApproveStep}
            effValue={effValue}
            steps={column.steps}
            column={column}
            assigneeRoleUsers={column?.assignee_role_users}
            createdBy={createdBy}
            members={members}
            item={item}
          />
        </div>
      </div>
    </>
  );
};
