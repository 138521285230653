import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichText.css';

export const RichText = React.forwardRef((props, ref) => {
  const {
    type = 'text',
    onChange,
    value,
    defaultValue,
    placeholder,
    modules,
    theme = 'snow',
    style,
    className,
    onBlur,
    onFocus,
  } = props;
  return (
    <>
      <ReactQuill
        ref={ref}
        theme={theme}
        onChange={onChange}
        onBlur={onBlur}
        value={value || defaultValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        modules={modules}
        style={style}
        className={className}
        onFocus={onFocus}
      />
    </>
  );
});
