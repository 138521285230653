import React, {useEffect, useState} from 'react';
import './formEdit.scss';
import ItemFormField from '../../../sheet/component/field/ItemFormField';
import {useTranslate} from 'core/i18n/i18nProvider';
import {RichText} from 'app/common/_partials/controls';
import {newId} from 'app/common/_helpers/idHelpers';
import {
  hasEditItemColumnPermission,
  hasEditItemPermission,
  hasViewSheetColumnPermission,
} from 'app/modules/work/sheet/permission/permissionHelper';
import {useSession} from 'core/store/hooks';
import {useSheetPermission} from 'app/modules/work/sheet/SheetPermissionHook';
import {debounce, isEqual, isObject, set} from 'lodash';
import {useDebounce} from 'core/helpers';

function FormEdit({
  listField,
  form,
  onUpdate,
  onSubmit,
  isEditMode,
  hiddenColumns,
  buttonSubmitLabel,
}) {
  const {t} = useTranslate();
  const [newValue, setNewValue] = useState({});
  const [formId, setFormId] = useState(null);
  const {user} = useSession();
  const [itemEditable, setItemEditable] = useState(false);
  const [description, setDescription] = useState(form?.description);
  const debouncedChangeDescription = useDebounce(description, 500);

  const {userSheetPermissions: sheetFormItemPermissions} = useSheetPermission({
    sheetId: form?.sheet_info?._id,
  });

  const handleUpdateForm = (field, value) => {
    const newFormPage = {
      [field]: value,
    };
    if (!isEqual(form[field], value)) {
      onUpdate(newFormPage);
    }
  };

  const handleChangeItem = (item, field, oldValue, value) => {
    if (value.value || value.value === null) {
      setNewValue({
        ...newValue,
        [field]: value,
      });
    } else {
      setNewValue({
        ...newValue,
        [field]: {value},
      });
    }
  };

  const handleClearForm = () => {
    setNewValue({});
    setFormId(newId());
  };

  const handleSubmitForm = () => {
    onSubmit && onSubmit(newValue);
    setNewValue({});
    setFormId(newId());
  };

  useEffect(() => {
    setDescription(form?.description);
  }, [form?.description]);

  useEffect(() => {
    setItemEditable(hasEditItemPermission(sheetFormItemPermissions, user, newValue));
  }, [sheetFormItemPermissions, user, newValue]);

  useEffect(() => {
    if (debouncedChangeDescription !== undefined && description !== undefined) {
      handleUpdateForm('description', debouncedChangeDescription);
    }
  }, [debouncedChangeDescription]);

  useEffect(() => {
    let updateItemParams = {
      ...newValue,
    };
    form?.sheet_info?.columns?.forEach((column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'people') {
          if (column.default === 'current_user') {
            set(updateItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (column.default === 'default_users') {
            set(updateItemParams, column.field, {value: column.default_users});
          }
        }
        if (column.type === 'status') {
          if (column.default) {
            set(updateItemParams, column.field, {value: column.default});
          }
        }
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(updateItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
      } else {
        if (column.type === 'approval') {
          const currentStep = column.steps[0];
          const assignedRoleUser = column?.assignee_role_users?.find(
            (assignee) => assignee.id === currentStep?.assignee_role
          );
          set(updateItemParams, column.field, {
            value: [{...currentStep, assignees: assignedRoleUser.assignees}],
          });
        }
      }
    });
    setNewValue(updateItemParams);
  }, [form?.sheet_info?.columns]);

  return (
    <>
      <div className='form-view-container' key={formId}>
        <div className='content-form'>
          <div className='form-name'>
            <div className='text-form-name'>
              {isEditMode ? (
                <input
                  key={form?.name}
                  type='text'
                  className='w-100'
                  placeholder={t('form_page_name')}
                  defaultValue={form?.name}
                  readOnly={!isEditMode}
                  onBlur={(e) => handleUpdateForm('name', e.target.value)}
                />
              ) : (
                <span>{form?.name}</span>
              )}
            </div>
          </div>
          <div className='form-description'>
            <div className='text-form-description'>
              {isEditMode ? (
                <RichText
                  className='w-100 min-h-30px'
                  placeholder={t('form_page_desc')}
                  value={description}
                  onChange={(e) => setDescription(e)}
                  theme={'bubble'}
                />
              ) : (
                <div dangerouslySetInnerHTML={{__html: form?.description}}></div>
              )}
            </div>
          </div>
          <div className='border-bottom my-6 mx-3'></div>
          <div className='forms-edit'>
            {listField?.map((column) => {
              const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
              if (hasViewColumnPermission && !column.hidden) {
                const value = newValue[column.field];
                const fieldEditable =
                  itemEditable && hasEditItemColumnPermission(user, column, newValue);
                return (
                  <ItemFormField
                    key={column.field}
                    column={column}
                    onChange={handleChangeItem}
                    value={value}
                    sheet={form?.sheet_info}
                    hiddenColumns={hiddenColumns}
                    fieldEditable={fieldEditable}
                  />
                );
              } else {
                return <React.Fragment key={column.field} />;
              }
            })}
          </div>
        </div>
      </div>
      {!isEditMode && (
        <div className='footer-page-form border-top p-6'>
          <div className='d-flex justify-content-between'>
            <div
              className='cursor-pointer text-primary d-flex align-items-center'
              onClick={handleClearForm}
            >
              <i className='las la-undo-alt text-primary me-2'></i>
              <span>{t('form_button_clear')}</span>
            </div>
            <div className='btn btn-primary cursor-pointer' onClick={handleSubmitForm}>
              {buttonSubmitLabel || t('form_button_submit')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormEdit;
