import React, {useEffect, useRef, useState} from 'react';
import FormShare from './form-share/FormShare';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useValidation} from '../sheet/validation/useValidation';
import {useSession} from 'core/store/hooks';
import {useDispatch} from 'react-redux';
import {useTranslate} from 'core/i18n/i18nProvider';
import {set} from 'lodash';
import {validationErrorCode} from '../sheet/validation/constants';
import {useNavigate, useParams} from 'react-router-dom';
import {getFormDetailAction, getFromByShareKeyAction} from '../stores/actions';
import './formShareView.scss';
import {hiddenColumns} from './FormEditView';
import {submitFormAction} from '../stores/form/action';

function FormShareView() {
  const snackNotification = useSnackNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {getValidationErrorMessage} = useValidation({});
  const {user, getDomain} = useSession();
  const {key, formId} = useParams();
  const {t} = useTranslate();
  const addingItem = useRef();
  const [newValue, setNewValue] = useState({});
  const [form, setForm] = useState(null);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const hasFetchForm = useRef(false);

  useEffect(() => {
    if (key) {
      dispatch(getFromByShareKeyAction(key)).then((res) => {
        setForm(res.data?.data);
      });
    }
  }, [key]);

  useEffect(() => {
    if (formId) {
      dispatch(getFormDetailAction(formId)).then((res) => {
        setForm(res.data?.data);
        hasFetchForm.current = true;
      });
    }
  }, [formId]);

  const handleChangeItem = (item, field, oldValue, value) => {
    setNewValue({
      ...newValue,
      [field]: {value},
    });
  };

  const onSubmit = () => {
    handleAddNewItem(newValue);
  };

  const handleAddNewItem = (newItem) => {
    if (!addingItem.current) {
      addingItem.current = true;
      addItemFromApp({...newItem, group: null}, user, (result) => {
        if (result.success) {
          addingItem.current = false;
          setIsSubmitForm(true);
        } else {
          if (result.code === validationErrorCode) {
            snackNotification.showError(
              result?.message
                ? getValidationErrorMessage(result?.message)
                : t('common_update_error')
            );
          } else {
            snackNotification.showError(
              result?.message ? t(result?.message) : t('common_update_error')
            );
          }
        }
        addingItem.current = false;
        setNewValue({});
      });
    }
  };

  const addItemFromApp = (item, user, callback) => {
    let addItemParams = {
      sheetId: form?.sheetId,
      title: {value: item.title},
      group: null,
      domain: getDomain(),
    };

    const columns = form?.settings?.columns || form?.sheet_info?.columns;

    // populate default value
    columns.forEach((column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(addItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
        if (column.type === 'people') {
          if (column.default === 'current_users') {
            set(addItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (column.default === 'default_users') {
            set(addItemParams, column.field, {value: column.default_users});
          }
        }
        if (column.type === 'status') {
          if (column.default) {
            set(addItemParams, column.field, {value: column.default});
          }
        }
      }
    });

    columns.forEach((column) => {
      if (item[column.field]) {
        addItemParams[column.field] = {
          value: item[column.field].value ?? null,
          label: item[column.field].label ?? null,
        };
      }
    });

    dispatch(submitFormAction(addItemParams)).then((result) => {
      callback && callback(result.data);
    });
  };

  useEffect(() => {
    let updateItemParams = {
      ...newValue,
    };
    form?.sheet_info?.columns?.forEach((column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'people') {
          if (column.default === 'current_user') {
            set(updateItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (column.default === 'default_users') {
            set(updateItemParams, column.field, {value: column.default_users});
          }
        }
        if (column.type === 'status') {
          if (column.default) {
            set(updateItemParams, column.field, {value: column.default});
          }
        }
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(updateItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
      } else {
        if (column.type === 'approval') {
          const currentStep = column.steps[0];
          const assignedRoleUser = column?.assignee_role_users?.find(
            (assignee) => assignee.id === currentStep?.assignee_role
          );
          set(updateItemParams, column.field, {
            value: [{...currentStep, assignees: assignedRoleUser.assignees}],
          });
        }
      }
    });
    setNewValue(updateItemParams);
  }, [form?.sheet_info?.columns]);

  const navigationLogin = () => {
    navigate('/login');
  };

  if (isSubmitForm) {
    return (
      <div className='form-share-unauthorized rounded d-flex flex-column'>
        <span className='text-dark fw-bold'>
          {form?.settings?.notification?.content || t('form_submit_success')}
        </span>
        <span className='btn btn-light-primary mt-3' onClick={() => setIsSubmitForm(false)}>
          {t('form_share_submit')}
        </span>
      </div>
    );
  }

  if (!form && hasFetchForm) {
    return (
      <div className='bg-body form-share-unauthorized rounded d-flex flex-column'>
        <span className='text-dark fw-bold'>{t('form_share_unauthorized')}</span>
        {!user && (
          <>
            <span className='mt-3'>{t('form_share_required_login')}</span>
            <span className='btn btn-primary mt-3' onClick={navigationLogin}>
              {t('AUTH.LOGIN.BUTTON')}
            </span>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <FormShare
        form={form}
        listField={form?.settings?.columns || form?.sheet_info?.columns}
        onChange={handleChangeItem}
        onSubmit={onSubmit}
        values={newValue}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
}

export default FormShareView;
