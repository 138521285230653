export const listColumnsSuperUseful = [
  {
    name: 'sheet_column_type_priority',
    value: 'color_priority',
    icon: 'la la-list',
  },
  {
    name: 'sheet_column_type_reference',
    value: 'reference',
    icon: 'la la-link',
  },
  {
    name: 'sheet_column_type_timeline',
    value: 'timeline',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_dependency',
    value: 'dependency',
    icon: 'la la-code-fork',
  },
  {
    name: 'sheet_column_type_link',
    value: 'link',
    icon: 'la la-link',
  },
  {
    name: 'sheet_column_type_formula',
    value: 'formula',
    icon: 'la la-code',
  },
  {
    name: 'sheet_column_type_file',
    value: 'file',
    icon: 'la la-file',
  },
  {
    name: 'sheet_column_type_department',
    value: 'department',
    icon: 'la la-building',
  },
  {
    name: 'sheet_column_type_last_updated_date',
    value: 'last_updated_date',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_approval',
    value: 'approval',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_geolocation',
    value: 'geolocation',
    icon: 'la la-map-marked-alt',
  },
  {
    name: 'sheet_column_type_custom_ai_prompt',
    value: 'custom_ai_prompt',
    icon: 'bi bi-magic',
  },
  {
    name: 'sheet_column_type_longtext',
    value: 'longtext',
    icon: 'la la-file-alt',
  },
];

export const listColumnEssentials = [
  {
    name: 'sheet_column_type_text',
    value: 'text',
    icon: 'la la-file-alt',
  },
  {
    name: 'sheet_column_type_number',
    value: 'number',
    icon: 'la la-500px',
  },
  {
    name: 'sheet_column_type_status',
    value: 'status',
    icon: 'la la-list',
  },
  {
    name: 'sheet_column_type_people',
    value: 'people',
    icon: 'la la-user',
  },
  {
    name: 'sheet_column_type_date',
    value: 'date',
    icon: 'la la-calendar',
  },
  {
    name: 'sheet_column_type_label',
    value: 'color_label',
    icon: 'la la-list',
  },
];

export const defaultUserActions = {
  add: true,
  ai_assistant: true,
  export_excel: true,
  filter: true,
  search: true,
  settings: true,
  sort: true,
};
