import './sheetFilterView.scss';

import {Dropdown} from 'react-bootstrap';
import SheetCompletionFilter from './SheetCompletionFilter';
import {SheetDateFilter} from './SheetDateFilter';
import SheetDepartmentFilter from './SheetDepartmentFilter';
import SheetGroupFilter from './SheetGroupFilter';
import SheetPeopleFilter from './SheetPeopleFilter';
import SheetReferenceFilter from './SheetReferenceFilter';
import SheetApprovalFilter from './SheetApprovalFilter';
import SheetStatusFilter from './SheetStatusFilter';
import {ShowForSheetPermission} from '../../../permission/ShowForSheetPermission';
import {useSession} from 'core/store/hooks';
import {useTranslate} from 'core/i18n/i18nProvider';
const supportedColumnTypes = [
  'color',
  'status',
  'people',
  'date',
  'department',
  'reference',
  'approval',
];

export default function SheetFilterView({
  sheet,
  view,
  applyFilter,
  activeFilters,
  filterActive,
  resetFilter,
  saveFilter,
  allowFilterByGroup = false,
  isEditMode,
}) {
  const {t} = useTranslate();
  const {user} = useSession();
  const innerHeightWindow = window.innerHeight;
  const renderColumnFilter = (column) => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{column.name}</div>
        <div
          style={{maxHeight: `${innerHeightWindow - 340 > 300 ? 300 : innerHeightWindow - 340}px`}}
          className='overflow-auto'
        >
          {renderColumnFilterInner(column)}
        </div>
      </div>
    );
  };

  const renderCompletionColumnFilter = () => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{t('sheet_filter_by_completion')}</div>
        <SheetCompletionFilter
          filter={{field: 'complete'}}
          applyFilter={applyFilter}
          filterValue={activeFilters?.complete}
        />
      </div>
    );
  };
  const renderGroupFilter = () => {
    return (
      <div className='p-2'>
        <div className='text-capitalize mb-2'>{t('sheet_filter_by_group')}</div>
        <SheetGroupFilter
          applyFilter={applyFilter}
          groups={sheet?.groups ?? []}
          activeFilters={activeFilters}
        />
      </div>
    );
  };

  const filterColumns = sheet?.columns?.filter(
    (column) => !column.deleted && supportedColumnTypes.includes(column.type)
  );

  const renderColumnFilterInner = (column) => {
    if (column.type === 'date') {
      return (
        <SheetDateFilter
          key={column.column}
          filter={column}
          column={column}
          applyFilter={applyFilter}
          filterValue={activeFilters?.[column?.field]}
        />
      );
    } else if (column.type === 'status' || column.type === 'color') {
      return (
        <SheetStatusFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'people') {
      return (
        <SheetPeopleFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'department') {
      return (
        <SheetDepartmentFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'reference') {
      return (
        <SheetReferenceFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else if (column.type === 'approval') {
      return (
        <SheetApprovalFilter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          filterValue={activeFilters?.[column?.field]}
          applyFilter={applyFilter}
        />
      );
    } else {
      return (
        <Filter
          key={column.field}
          sheet={sheet}
          column={column}
          filter={column}
          applyFilter={applyFilter}
          filterValue={activeFilters?.[column?.field]}
        />
      );
    }
  };

  return (
    <Dropdown className='ms-2' autoClose='outside'>
      <Dropdown.Toggle
        className={`btn ${filterActive ? 'btn-light-primary' : 'btn-light'}`}
        variant=''
        size='sm'
      >
        Filter <i className='las la-filter'></i>
      </Dropdown.Toggle>

      <Dropdown.Menu className='shadow'>
        <div
          style={{height: `${innerHeightWindow - 230 < 460 ? innerHeightWindow - 230 : null}px`}}
        >
          <div className='m-2 d-flex justify-content-between'>
            <div className='text-capitalize fs-4 fw-bold'>
              {t('sheet.view.toolbar.filter.quick_filter')}
            </div>
            <div>
              <button className='btn btn-sm btn-outline' onClick={() => resetFilter({})}>
                {t('sheet.view.toolbar.filter.clear')}
              </button>
              {isEditMode && (
                <ShowForSheetPermission permission='board.edit' sheet={sheet} user={user}>
                  <button
                    className='btn btn-sm btn-outline btn-outline-primary ms-2'
                    onClick={() => saveFilter && saveFilter(view, activeFilters)}
                  >
                    {t('sheet.view.toolbar.filter.save_to_view')}
                  </button>
                </ShowForSheetPermission>
              )}
            </div>
          </div>
          <div className=' w-600px h-400px p-2 overflow-auto'>
            <div className='text-capitalize fs-6 fw-bold'>
              {t('sheet.view.toolbar.filter.all_columns')}
            </div>
            <div style={{width: `${(filterColumns?.length + 1) * 160}px`}}>
              <table>
                <tbody>
                  <tr>
                    <td className='w-150px align-top'>{renderCompletionColumnFilter()}</td>
                    {allowFilterByGroup && (
                      <td className='w-150px align-top'>{renderGroupFilter()}</td>
                    )}
                    {filterColumns?.map((column, index) => {
                      if (supportedColumnTypes.includes(column.type)) {
                        return (
                          <td className='w-150px align-top' key={index}>
                            {renderColumnFilter(column)}
                          </td>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Filter({filter, column}) {
  return <div className='btn btn-sm btn-secondary'>{column.name}</div>;
}
