import {CheckBoxField, Input, RadioField, SelectField} from 'app/common/_partials/controls';
import {Field, Formik} from 'formik';
import {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import {SelectSearchField} from 'app/common/_partials/controls/forms/SelectSearchField';
import SheetViewColumnEdit from './SheetViewColumnEdit';
import SheetViewPermitted from 'app/modules/work/sheet/component/sheet-config/SheetViewPermitted';
import {checkDataView} from './helpers';
import {upperFirst} from 'lodash';
import {useTranslate} from 'core/i18n/i18nProvider';

export default function SheetViewEditView({view, sheet, onSubmit, onDelete}) {
  const {t} = useTranslate();
  const [viewEdit, setViewEdit] = useState({
    name: '',
    type: '',
  });

  const [showDelete, setShowDelete] = useState(false);
  const [columns, setColumns] = useState([]);
  const [permittedUsers, setPermittedUsers] = useState([]);
  const [isPermitted, setIsPermitted] = useState(false);

  const isDataView = checkDataView(viewEdit?.type);

  useEffect(() => {
    let _viewEdit = {
      ...viewEdit,
      ...view,
      settings: {
        ...view?.settings,
        item_addition_mode: view?.settings?.item_addition_mode ?? 'dialog', // default adding mode
      },
    };
    if (view.filters?.length > 0) {
      _viewEdit.filters = view.filters.map((filter) => {
        return {
          label: upperFirst(filter.name),
          value: filter.field,
          filter: filter,
        };
      });
    }
    if (view.display_fields?.length > 0) {
      _viewEdit.display_fields = view.display_fields.map((field) => {
        return {
          label: upperFirst(field.name),
          value: field.field,
          field: field,
        };
      });
    }
    if (view.kanban_column) {
      _viewEdit.kanban_column = [
        {
          label: upperFirst(view.kanban_column.name),
          value: view.kanban_column.field,
          field: view.kanban_column,
        },
      ];
    }
    if (view.date_columns) {
      _viewEdit.date_columns = view.date_columns.map((field) => {
        return {
          label: upperFirst(field.name),
          value: field.field,
          field: field,
        };
      });
    }
    if (view.column_start_date) {
      _viewEdit.column_start_date = {
        label: upperFirst(view.column_start_date.name),
        value: view.column_start_date.field,
        field: view.column_start_date,
      };
    }
    if (view.column_end_date) {
      _viewEdit.column_end_date = {
        label: upperFirst(view.column_end_date.name),
        value: view.column_end_date.field,
        field: view.column_end_date,
      };
    }
    if (view.reference?.id) {
      _viewEdit.reference = {value: view.reference.id, label: view.reference.title};
    }
    setViewEdit(_viewEdit);

    let listColumns = sheet?.columns;
    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = listColumns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumnsBySheet = listColumns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumnsBySheet = listColumnsBySheet.map((column) => {
        return {...column, hidden: false};
      });
      listColumns = [...listColumnsByView, ...listColumnsBySheet];
    } else {
      listColumns = listColumns?.map((column) => {
        return {
          ...column,
          hidden: Boolean(column?.hidden),
        };
      });
    }
    setColumns(listColumns);

    if (view?.permitted_users) {
      setPermittedUsers(view?.permitted_users);
    }

    if (view?.is_permitted) {
      setIsPermitted(view?.is_permitted);
    }
  }, [view]);

  const showDeleteDialog = () => {
    setShowDelete(true);
  };

  const handleDeleteView = () => {
    onDelete && onDelete(view);
    setShowDelete(false);
  };

  return (
    <>
      <div className='h-100'>
        {viewEdit && (
          <Formik
            enableReinitialize={true}
            initialValues={viewEdit}
            onSubmit={(values) => {
              let params = {...values};

              params.is_permitted = isPermitted;
              if (permittedUsers) {
                params.permitted_users = permittedUsers;
              }

              params.columns = columns;
              if (values.filters) {
                params.filters = values.filters.map((option) => option.filter);
              }
              if (values.display_fields) {
                params.display_fields = values.display_fields.map((option) => option.field);
              }
              if (values.kanban_column?.length > 0) {
                params.kanban_column = values.kanban_column[0].field;
              }
              if (values.date_columns) {
                params.date_columns = values?.date_columns.map((dateColumn) => dateColumn?.field);
              }

              if (values.column_start_date) {
                params.column_start_date = values?.column_start_date.field;
              }

              if (values.column_end_date) {
                params.column_end_date = values?.column_end_date.field;
              }

              onSubmit && onSubmit(view, params);
            }}
          >
            {({handleSubmit}) => {
              return (
                <>
                  <Form className='h-100 d-flex flex-column w-100'>
                    <div style={{flex: 1, overflowY: 'scroll', overflowX: 'hidden'}}>
                      <div className='form-group row mb-2'>
                        <div className='col-4 d-flex align-items-center justify-content-start'>
                          <label>{t('sheet_view_setting_name')}:</label>
                        </div>
                        <div className='col-8'>
                          <Field name='name' component={Input} autoComplete='off' focus />
                        </div>
                      </div>
                      <div className='form-group row mb-2 '>
                        <div className='col-4 d-flex align-items-center justify-content-start'>
                          <label>{t('sheet_view_setting_type')}:</label>
                        </div>
                        <div className='col-8'>
                          <SelectField name='type' readOnly disabled>
                            <option value='list'>{t('sheet_view_type_list')}</option>
                            <option value='table'>{t('sheet_view_type_table')}</option>
                            <option value='table-paging'>
                              {t('sheet_view_type_table_paging')}
                            </option>
                            <option value='table-paging-with-subitems'>
                              {t('sheet_view_type_table_paging_with_subitems')}
                            </option>
                            <option value='calendar'>{t('sheet_view_type_calendar')}</option>
                            <option value='kanban'>{t('sheet_view_type_column')}</option>
                            <option value='dashboard'>{t('sheet_view_type_dashboard')}</option>
                          </SelectField>
                        </div>
                      </div>

                      {isDataView && (
                        <>
                          <div className='form-group row mb-2 '>
                            <div className='col-4 d-flex align-items-start justify-content-start'>
                              <label>{t('sheet_edit_add')}:</label>
                            </div>
                            <div className='col-8'>
                              <Field
                                name='settings.item_addition_mode'
                                component={RadioField}
                                value='dialog'
                                label={t('sheet_view_table_item_addition_dialog_mode')}
                              />
                              <Field
                                name='settings.item_addition_mode'
                                component={RadioField}
                                value='inline'
                                // TODO: Need to implement this option for table pagination in the future
                                disabled={viewEdit?.type === 'table-paging'}
                                label={t('sheet_view_table_item_addition_inline_mode')}
                              />
                            </div>
                          </div>
                          <div className='form-group row mb-2 '>
                            <div className='col-4 d-flex align-items-center justify-content-start'>
                              <label>{t('sheet_view_table_show_line_number')}:</label>
                            </div>
                            <div className='col-8'>
                              <Field name='settings.show_line_number' component={CheckBoxField} />
                            </div>
                          </div>
                        </>
                      )}

                      {view.type === 'kanban' && (
                        <>
                          <div className='form-group row mb-2 mt-4 '>
                            <div className='col-12'>
                              <label>{t('sheet_view_setting_kanban_column')}:</label>
                              <SelectSearchField
                                name='kanban_column'
                                isMulti
                                options={sheet.columns.flatMap((column) => {
                                  if (
                                    (column.type === 'status' || column.type === 'color') &&
                                    !column.deleted &&
                                    !column.hidden
                                  ) {
                                    return {
                                      label: upperFirst(column.name),
                                      value: column.field,
                                      field: column,
                                    };
                                  } else {
                                    return [];
                                  }
                                })}
                              ></SelectSearchField>
                            </div>
                          </div>
                        </>
                      )}

                      {(view.type === 'kanban' || view.type === 'calendar') && (
                        <div className='form-group row mb-2 mt-4'>
                          <div className='col-12'>
                            <label>{t('sheet_view_setting_display_fields')}:</label>
                            <SelectSearchField
                              name='display_fields'
                              isMulti
                              options={sheet.columns?.flatMap((column) => {
                                if (
                                  !column.deleted &&
                                  !column.hidden &&
                                  column.type !== 'approval'
                                ) {
                                  return {
                                    label: upperFirst(column.name),
                                    value: column.field,
                                    field: column,
                                  };
                                } else {
                                  return [];
                                }
                              })}
                            ></SelectSearchField>
                          </div>
                        </div>
                      )}

                      {view.type === 'calendar' && (
                        <>
                          <div className='form-group row mb-2 mt-4 '>
                            <div className='col-12'>
                              <label>{t('sheet_view_setting_date_start_column')}:</label>
                              <SelectSearchField
                                name='column_start_date'
                                isMulti={false}
                                options={sheet.columns.flatMap((column) => {
                                  if (
                                    column.type === 'date' ||
                                    column.type === 'datetime' ||
                                    column.type === 'timeline'
                                  ) {
                                    return {
                                      label: upperFirst(column.name),
                                      value: column.field,
                                      field: column,
                                    };
                                  } else {
                                    return [];
                                  }
                                })}
                              ></SelectSearchField>
                            </div>
                          </div>
                          <div className='form-group row mb-2 mt-4 '>
                            <div className='col-12'>
                              <label>{t('sheet_view_setting_date_end_column')}:</label>
                              <SelectSearchField
                                name='column_end_date'
                                isMulti={false}
                                options={sheet.columns.flatMap((column) => {
                                  if (column.type === 'date' || column.type === 'datetime') {
                                    return {
                                      label: upperFirst(column.name),
                                      value: column.field,
                                      field: column,
                                    };
                                  } else {
                                    return [];
                                  }
                                })}
                              ></SelectSearchField>
                            </div>
                          </div>
                        </>
                      )}

                      {(view.type === 'table' ||
                        view.type === 'table-paging' ||
                        view.type === 'table-paging-with-subitems') && (
                        <SheetViewColumnEdit columns={columns} setColumns={setColumns} />
                      )}

                      <SheetViewPermitted
                        sheetMembers={sheet?.members}
                        permittedUsers={permittedUsers}
                        setPermittedUsers={setPermittedUsers}
                        isPermitted={isPermitted}
                        setIsPermitted={setIsPermitted}
                      />
                    </div>

                    <div className='form-group w-100 d-flex justify-content-center gap-5 bg-light pt-5'>
                      <button
                        type='button'
                        onClick={showDeleteDialog}
                        className='btn btn-outline-danger btn-elevate me-2'
                      >
                        {t('common_delete')}
                      </button>
                      <button
                        type='submit'
                        onClick={handleSubmit}
                        className='btn btn-primary btn-elevate'
                      >
                        {t('common_save')}
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </div>
      <ModalConfirm
        style={{zIndex: 2000}}
        show={showDelete}
        onHide={() => setShowDelete(false)}
        title={t('sheet_view_delete_title')}
        content={t('sheet_view_delete_message')}
        handleExcute={handleDeleteView}
      />
    </>
  );
}
