import {newId} from 'app/common/_helpers/idHelpers';

export const simpleInitSheet = () => {
  return {
    name: 'Bảng 1',
    type: 'sheet',
    views: [
      {
        name: 'Bảng phân trang',
        type: 'table-paging',
        code: 'table-paging-1',
        is_default: true, // set table as a default sheet view
      },
    ],
    groups: [
      {
        title: '',
        id: newId(),
      },
    ],
    roles: simpleInitRoles,
    permissions: [
      {
        subject: {role: 'owner'},
        condition: {},
        action: '*',
        resource: '*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: [],
        action: 'view',
        resource: 'item:*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: {},
        action: 'edit',
        resource: 'item:*',
        effect: 'allow',
      },
    ],
    columns: simpleInitColumns,
  };
};

export const simpleInitColumns = [
  {
    name: 'Tiêu đề',
    field: 'title',
    type: 'text',
    styles: {
      width: '400px',
    },
  },
  {
    name: 'Con người',
    field: 'people',
    type: 'people',
    styles: {
      width: '120px',
    },
  },
  {
    name: 'Trạng thái',
    field: 'status',
    type: 'status',
    styles: {
      width: '120px',
    },
    values: [
      {
        label: 'Trống',
        value: '',
        backgroundColor: '#f3f6f9',
      },
      {
        label: 'Đang thực hiện',
        value: 'in_progress',
        backgroundColor: '#ffa800',
      },
      {
        label: 'Treo',
        value: 'pending',
        backgroundColor: '#8950FC',
      },
      {
        label: 'Vướng mắc',
        value: 'stuck',
        backgroundColor: '#f64e60',
      },
      {
        label: 'Hoàn thành',
        value: 'done',
        backgroundColor: '#1bc5bd',
      },
    ],
    done_values: ['done'],
  },
  {
    name: 'Ngày giờ',
    field: 'date',
    type: 'date',
    styles: {
      width: '110px',
    },
  },
  {
    name: 'Ưu tiên',
    field: 'priority',
    type: 'color',
    styles: {
      width: '100px',
    },
    values: [
      {
        label: 'Không ưu tiên',
        value: '',
        backgroundColor: '#f3f6f9',
      },
      {
        label: 'Nghiêm trọng',
        value: 'critical',
        backgroundColor: '#f64e60',
      },
      {
        label: 'Cao',
        value: 'high',
        backgroundColor: '#ffa800',
      },
      {
        label: 'Trung bình',
        value: 'medium',
        backgroundColor: '#1bc5bd',
      },
      {
        label: 'Thấp',
        value: 'low',
        backgroundColor: '#72efe9',
      },
    ],
  },
  {
    name: 'Phê duyệt',
    type: 'approval',
    styles: {
      width: '500px',
    },
    steps: [
      {
        id: '1',
        step_type: 'serial',
        assignee_role: '1',
        name: 'Tạo',
      },
      {
        id: '2',
        step_type: 'serial',
        assignee_role: '2',
        name: 'Bước duyệt',
        completion_time: 1,
      },
    ],
    allowEnd: null,
    field: 'approval1',
    assignee_role_users: [
      {
        id: '1',
        title: '',
      },
      {
        id: '2',
        title: '',
      },
    ],
  },
  {
    name: 'complete',
    field: 'complete',
    type: 'boolean',
    hidden: true,
    styles: {
      width: '0px',
    },
  },
];

export const simpleInitRoles = {
  OWNER: {
    active: true,
    name: 'OWNER',
    permissions: {
      board: {
        edit: 'all',
      },
      item: {
        view: 'all',
        edit: 'all',
      },
    },
  },
  USER: {
    active: true,
    name: 'USER',
    permissions: {
      item: {
        view: 'all',
        edit: 'all',
      },
    },
  },
};
