import {
  createAppAction,
  createSheetAction,
  deleteAppAction,
  getAppAction,
  getAppsAction,
  updateAppAction,
} from './stores/action';

import {simpleInitApp} from 'app/modules/application/templates/app';
import {simpleInitSheet} from 'app/modules/application/templates/sheet';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {useSession} from 'core/store/hooks';

const useApp = () => {
  const dispatch = useDispatch();
  const {app} = useSelector((state) => state.app, shallowEqual);

  const [isLoading, setIsLoading] = useState(false);

  const [currentApp, setCurrentApp] = useState({});
  const [apps, setApps] = useState([]);
  const {user} = useSession();

  useEffect(() => {
    setCurrentApp(app);
  }, [app]);

  const fetchApps = (callback) => {
    setIsLoading(true);
    const params = {
      filter: {},
    };

    return dispatch(getAppsAction(params)).then((result) => {
      if (result?.data) {
        setApps(result?.data);
      }
      setIsLoading(false);
      callback && callback();
    });
  };

  const fetchApp = (id, callback) => {
    setIsLoading(true);
    return dispatch(getAppAction(id)).then((result) => {
      setIsLoading(false);
      callback && callback();
    });
  };

  const createApp = (callback) => {
    setIsLoading(true);
    createDefaultPage((newPage) => {
      const params = simpleInitApp(newPage);
      params.members = [
        {
          id: user._id,
          title: user.name,
          name: user.name,
          role: ['OWNER'],
        },
      ];

      dispatch(createAppAction(params)).then((result) => {
        if (result?.data) {
          setCurrentApp(result?.data);
        }
        setIsLoading(false);
        callback && callback(result?.data);
      });
    });
  };

  const saveApp = (params, callback) => {
    setIsLoading(true);

    dispatch(updateAppAction(params)).then((result) => {
      if (result?.data) {
        setCurrentApp({...currentApp, ...result?.data});
      }
      setIsLoading(false);
      callback && callback(result?.data);
    });
  };

  const deleteApp = (appId, callback) => {
    setIsLoading(true);
    dispatch(deleteAppAction(appId)).then((res) => {
      if (res.success) {
        callback && callback();
      }
      setIsLoading(false);
    });
  };

  const createDefaultPage = (callback) => {
    const params = simpleInitSheet();
    return dispatch(createSheetAction(params)).then((result) => {
      callback && callback(result?.data);
    });
  };
  const publishApp = (appId, app, callback) => {
    let params = {};

    if (app?.app_publish) {
      params = {
        id: appId,
        app_publish: {...app.app_publish, interface: app?.interface},
      };
    } else {
      params = {
        id: appId,
        app_publish: {interface: app?.interface},
      };
    }

    saveApp(params, () => {
      callback && callback();
    });
  };



  return {
    currentApp,
    setCurrentApp,
    apps,
    isLoading,
    fetchApps,
    fetchApp,
    createApp,
    saveApp,
    deleteApp,
    publishApp,
  };
};

export default useApp;
