import {newId} from 'app/common/_helpers/idHelpers';

export const simpleInitPage = () => {
  return {
    type: 'page',
    views: [
      {
        name: 'List',
        type: 'list',
      },
    ],
    roles: simpleInitRoles.roles,
    permissions: [
      {
        subject: {role: 'owner'},
        condition: {},
        action: '*',
        resource: '*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: [],
        action: 'view',
        resource: '*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: {},
        action: 'edit',
        resource: '*',
        effect: 'allow',
      },
    ],
  };
};

export const simpleInitSheet = (initDefaultView) => {
  return {
    type: 'sheet',
    views: [initDefaultView],
    groups: [
      {
        title: '',
        id: newId(),
      },
    ],
    roles: simpleInitRoles.roles,
    permissions: [
      {
        subject: {role: 'owner'},
        condition: {},
        action: '*',
        resource: '*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: [],
        action: 'view',
        resource: 'item:*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: {},
        action: 'edit',
        resource: 'item:*',
        effect: 'allow',
      },
    ],
    columns: [
      {
        name: 'Tiêu đề',
        field: 'title',
        type: 'text',
        styles: {
          width: '400px',
        },
      },
      {
        name: 'Con người',
        field: 'people',
        type: 'people',
        is_assignee: true,
        styles: {
          width: '210px',
        },
      },
      {
        name: 'Trạng thái',
        field: 'status',
        type: 'status',
        styles: {
          width: '120px',
        },
        values: [
          {
            label: 'Trống',
            value: '',
            backgroundColor: '#f3f6f9',
          },
          {
            label: 'Đang thực hiện',
            value: 'in_progress',
            backgroundColor: '#ffa800',
          },
          {
            label: 'Treo',
            value: 'pending',
            backgroundColor: '#8950FC',
          },
          {
            label: 'Vướng mắc',
            value: 'stuck',
            backgroundColor: '#f64e60',
          },
          {
            label: 'Hoàn thành',
            value: 'done',
            backgroundColor: '#1bc5bd',
          },
        ],
        done_values: ['done'],
      },
      {
        name: 'Ngày giờ',
        field: 'date',
        type: 'date',
        styles: {
          width: '110px',
        },
      },
      {
        name: 'Ưu tiên',
        field: 'priority',
        type: 'color',
        styles: {
          width: '100px',
        },
        values: [
          {
            label: 'Không ưu tiên',
            value: '',
            backgroundColor: '#f3f6f9',
          },
          {
            label: 'Nghiêm trọng',
            value: 'critical',
            backgroundColor: '#f64e60',
          },
          {
            label: 'Cao',
            value: 'high',
            backgroundColor: '#ffa800',
          },
          {
            label: 'Trung bình',
            value: 'medium',
            backgroundColor: '#1bc5bd',
          },
          {
            label: 'Thấp',
            value: 'low',
            backgroundColor: '#72efe9',
          },
        ],
      },
      {
        name: 'Phê duyệt',
        type: 'approval',
        styles: {
          width: '500px',
        },
        steps: [
          {
            id: '1',
            step_type: 'serial',
            assignee_role: '1',
            name: 'Tạo',
            actionCreateApproval: true,
          },
          {
            id: '2',
            step_type: 'serial',
            assignee_role: '2',
            name: 'Bước duyệt',
            completion_time: 1,
            actionReject: true,
            actionReopen: true,
            actionApprove: true,
          },
        ],
        allowEnd: null,
        field: 'approval1',
        assignee_role_users: [
          {
            id: '1',
            title: '',
          },
          {
            id: '2',
            title: '',
          },
        ],
      },
      {
        name: 'complete',
        field: 'complete',
        type: 'boolean',
        hidden: true,
        styles: {
          width: '0px',
        },
      },
    ],
  };
};

export const simpleInitProject = () => {
  return {
    type: 'project',
    views: [
      {
        name: '',
        type: 'table',
        code: 'table1',
      },
      {
        name: 'Dashboard',
        type: 'dashboard',
        code: 'dashboard1',
      },
    ],
    groups: [
      {
        title: '',
        id: newId(),
      },
    ],
    roles: simpleInitRoles.roles,
    permissions: [
      {
        subject: {role: 'owner'},
        condition: {},
        action: '*',
        resource: '*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: [],
        action: 'view',
        resource: 'item:*',
        effect: 'allow',
      },
      {
        subject: {role: 'user'},
        condition: {},
        action: 'edit',
        resource: 'item:*',
        effect: 'allow',
      },
    ],
    columns: [
      {
        name: 'Title',
        field: 'title',
        type: 'text',
        styles: {
          width: '400px',
        },
      },
      {
        name: 'Assignee',
        field: 'people',
        type: 'people',
        styles: {
          width: '100px',
        },
      },
      {
        name: 'Status',
        field: 'status',
        type: 'status',
        styles: {
          width: '100px',
        },
        values: [
          {
            label: '',
            value: '',
            backgroundColor: '#f3f6f9',
          },
          {
            label: 'Working on it',
            value: 'in_progress',
            backgroundColor: '#ffa800',
          },
          {
            label: 'Stuck',
            value: 'stuck',
            backgroundColor: '#f64e60',
          },
          {
            label: 'Pending',
            value: 'Pending',
            backgroundColor: '#8950FC',
          },
          {
            label: 'Done',
            value: 'done',
            backgroundColor: '#1bc5bd',
          },
        ],
        done_values: ['done'],
      },
      {
        name: 'Due Date',
        field: 'date',
        type: 'date',
        styles: {
          width: '90px',
        },
      },
      {
        name: 'priority',
        field: 'priority',
        type: 'color',
        styles: {
          width: '100px',
        },
        values: [
          {
            label: '',
            value: '',
            backgroundColor: '#f3f6f9',
          },
          {
            label: 'Critical',
            value: 'critical',
            backgroundColor: '#ffa800',
          },
          {
            label: 'High',
            value: 'high',
            backgroundColor: '#f64e60',
          },
          {
            label: 'Medium',
            value: 'medium',
            backgroundColor: '#1bc5bd',
          },
          {
            label: 'Low',
            value: 'low',
            backgroundColor: '#1bc5bd',
          },
        ],
      },
      {
        name: 'Timeline',
        field: 'timeline',
        type: 'timeline',
        styles: {
          width: '100px',
        },
      },
      {
        name: 'Dependent On',
        field: 'dependency',
        type: 'dependency',
        styles: {
          width: '100px',
        },
      },
      {
        name: 'complete',
        field: 'complete',
        type: 'boolean',
        hidden: true,
        styles: {
          width: '0px',
        },
      },
    ],
  };
};

export const simpleInitColumnStatus = {
  name: 'status',
  field: 'status',
  type: 'status',
  styles: {
    width: '100px',
  },
  values: [
    {
      label: 'Trống',
      value: '',
      backgroundColor: '#f3f6f9',
    },
    {
      label: 'Đang thực hiện',
      value: 'in_progress',
      backgroundColor: '#ffa800',
    },
    {
      label: 'Treo',
      value: 'pending',
      backgroundColor: '#8950FC',
    },
    {
      label: 'Vướng mắc',
      value: 'stuck',
      backgroundColor: '#f64e60',
    },
    {
      label: 'Hoàn thành',
      value: 'done',
      backgroundColor: '#1bc5bd',
    },
  ],
};

export const simpleInitColumnPriority = {
  name: 'priority',
  field: 'priority',
  type: 'color',
  styles: {
    width: '100px',
  },
  values: [
    {
      label: 'Không ưu tiên',
      value: '',
      backgroundColor: '#f3f6f9',
    },
    {
      label: 'Nghiêm trọng',
      value: 'critical',
      backgroundColor: '#f64e60',
    },
    {
      label: 'Cao',
      value: 'high',
      backgroundColor: '#ffa800',
    },
    {
      label: 'Trung bình',
      value: 'medium',
      backgroundColor: '#1bc5bd',
    },
    {
      label: 'Thấp',
      value: 'low',
      backgroundColor: '#72efe9',
    },
  ],
};

export const simpleInitColumnLabel = {
  name: 'label',
  field: 'label',
  type: 'color',
  styles: {
    width: '100px',
  },
  values: [
    {
      label: 'Trống',
      value: '',
    },
    {
      label: 'Label 1',
      value: '1',
      backgroundColor: '#ffa800',
    },
    {
      label: 'Label 2',
      value: '2',
      backgroundColor: '#f64e60',
    },
    {
      label: 'Label 3',
      value: '3',
      backgroundColor: '#1bc5bd',
    },
  ],
};

export const simpleInitRoles = {
  roles: {
    OWNER: {
      active: true,
      name: 'OWNER',
      permissions: {
        board: {
          edit: 'all',
        },
        item: {
          view: 'all',
          edit: 'all',
        },
      },
    },
    USER: {
      active: true,
      name: 'USER',
      permissions: {
        item: {
          view: 'all',
          edit: 'all',
        },
      },
    },
  },
};
