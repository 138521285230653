import {memo, useEffect, useMemo, useRef, useState} from 'react';
import {NodeToolbar, Position} from 'reactflow';
import './workflowTriggerNode.scss';
import WorkflowHandle from '../handle/WorkflowHandle';
import {useSelector} from 'react-redux';
import {useWorkflow} from '../../hook/workflowHook';
import clsx from 'clsx';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Dropdown} from 'react-bootstrap';
const WorkflowTriggerNode = memo(({id, data, type, isConnectable}) => {
  const {addNode, saveNode} = useWorkflow();
  const {blocks} = useSelector((state) => state.workflow);
  const [showBlockOption, setShowBlockOption] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showActionMenuChangeType, setShowActionMenuChangeType] = useState(false);
  const blockOptions = useMemo(() => blocks.filter((block) => block.type === 'trigger'), [blocks]);
  const nodeRef = useRef();
  const {t} = useTranslate();
  const label = useMemo(() => data?.label && data?.label !== '' ? data?.label : t('workflows.node.node_empty_label'), [data]);

  const actionMenuRef = useRef();

  const onSelectBlockOption = (block) => {
    addNode(id, block);
    setShowBlockOption(false);
    nodeRef.current.click();
  };

  useEffect(() => {
    if (data?.empty) {
      setShowBlockOption(true);
    }
  }, [data]);

  useEffect(() => {
    const outsideClick = (e) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(e.target)) {
        setShowActionMenu(false);
      }
    };
    document.addEventListener('click', outsideClick);
    return () => {
      document.removeEventListener('click', outsideClick);
    }
  }, []);

  const onToggleChangeTypeMenu = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type) {
      await setShowActionMenuChangeType(!showActionMenuChangeType);
    }
  };

  const onChangeNodeBlockType = (e, blockOption) => {
    e.preventDefault();
    e.stopPropagation();
    setShowActionMenuChangeType(false);
    setShowActionMenu(false);
    saveNode(id, {data: {...data, block: blockOption, label: blockOption?.name}});
  };

  const onChangeLabel = (value) => {
    saveNode(id, {data: {...data, label: value}});
  };

  return (
    <>
      <div className='workflow-trigger-node p-3 nowheel nodrag' ref={nodeRef}>
        <div className='workflow-trigger-tag'>
          <div className='workflow-trigger-tag-icon'>
            <i className='la la-bolt text-dark fs-2' />
          </div>
        </div>
        <div
          className={clsx('workflow-trigger-icon', data?.empty && 'workflow-trigger-empty')}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowBlockOption(!showBlockOption);
          }}
        >
          {data?.empty ? (
            <i className={`fa-solid fa-plus fs-2`} />
          ) : (
            <i className='la la-bolt text-white fs-2' />
          )}
        </div>
        <div>
            <input
              className='workflow-trigger-input'
              value={label}
              onChange={(e) => onChangeLabel(e.target.value)}
            />
          </div>
      </div>
      <div
        className='workflow-trigger-action'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowActionMenu(!showActionMenu);
        }}
      >
        <i className='ki ki-bold-more-hor' />
      </div>
      <NodeToolbar position={Position.Right} isVisible={showActionMenu}>
        <div className='p-2 bg-white' ref={actionMenuRef}>
          <div className='workflow-action-menu-item p-2'>
            <i className='la la-pencil-alt' />
            <span>{t('workflows.node.action.edit')}</span>
          </div>
          <div className='workflow-action-menu-item w-100 p-2'>
            <div
              className='d-flex gap-2 align-items-center w-100'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowActionMenuChangeType(!showActionMenuChangeType);
              }}
            >
              <i className='la la-exchange' />
              <span>{t('workflows.node.action.change_type')}</span>
            </div>
            <Dropdown
              className='menu-item p-0'
              align='end'
              drop='end'
              autoclose='true'
              show={showActionMenuChangeType}
              onToggle={onToggleChangeTypeMenu}
            >
              <div className='menu-link p-0' onClick={onToggleChangeTypeMenu}>
                <span className='menu-arrow'></span>
              </div>
              <Dropdown.Menu className='p-2'>
                {showActionMenuChangeType && blockOptions && (
                  <div className='p-3 workflow-node-create-option gap-2 mh-300px overflow-auto'>
                    {blockOptions.map((blockOption) => {
                      return (
                        <div
                          className='workflow-node-create-option-item gap-2'
                          key={blockOption?._id}
                          onClick={(e) => onChangeNodeBlockType(e, blockOption)}
                        >
                          <div>
                            <p className='m-0 fw-bold'>{blockOption?.name}</p>
                            <p className='m-0'>{blockOption?.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </NodeToolbar>
      {showBlockOption && (
        <div
          className='p-3 workflow-trigger-option gap-2 mh-300px overflow-auto'
          onScroll={(e) => e.stopPropagation()}
        >
          {blockOptions.map((blockOption) => {
            return (
              <div
                className='workflow-trigger-option-item gap-2 p-2'
                key={blockOption?._id}
                onClick={() => onSelectBlockOption(blockOption)}
              >
                <div>
                  <p className='m-0 fw-bold'>{blockOption?.name}</p>
                  <p className='m-0'>{blockOption?.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <WorkflowHandle
        type='source'
        position={Position.Bottom}
        id='trigger-bottom'
        style={{bottom: -2, background: '#555'}}
        isConnectable={1}
      />
    </>
  );
});
export default WorkflowTriggerNode;