import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import ItemDetail from './ItemDetail';

const ItemDetailModal = ({
  key,
  open,
  item,
  view,
  sheet,
  permissions,
  onHide,
  onEditItem,
  onDelete,
  onSaveItemAction,
  onUpdateItem,
  onMoveItem,
  readOnly,
  allowChangeGroup,
  onToggleDisplayMode,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const onToggleExpanded = () => {
    setIsFullScreen(!isFullScreen);
  };
  return (
    <Modal
      show={open}
      onHide={onHide}
      centered
      fullscreen={isFullScreen}
      size='lg'
      className='p-0'
      scrollable
      key={`item-detail-${item?._id}`}
    >
        <ItemDetail
          item={item}
          view={view}
          sheet={sheet}
          permissions={permissions}
          onHide={onHide}
          onEditItem={onEditItem}
          onDelete={onDelete}
          onUpdateItem={onUpdateItem}
          onSaveItemAction={onSaveItemAction}
          onMoveItem={onMoveItem}
          readOnly={readOnly}
          allowChangeGroup={allowChangeGroup}
          showDialogControl={false}
          displayProps={{isFullScreen, onToggleDisplayMode, onToggleExpanded}}
        />
    </Modal>
  );
};

export default ItemDetailModal;
