import {isArray} from 'lodash';
import {useDispatch} from 'react-redux';
import {downloadLinkFileAction, uploadFileAction} from 'app/modules/work/sheet/stores/sheetActions';
import clsx from 'clsx';
import {
  Badge,
  Button,
  Dropdown,
  Image,
  Modal,
  ModalBody,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import React, {useEffect, useRef, useState} from 'react';
import {ItemFileAttachmentViewer} from '../../item-detail/ItemFileAttachmentViewer';
import {newId} from 'app/common/_helpers/idHelpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import './fileField.scss';
import {effectiveValue} from 'app/modules/work/helpers';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';

const imageTypes = ['jpg', 'jpeg', 'png'];
const videoTypes = ['mp4', 'mpg'];
const audioTypes = ['mp3'];
const pdfTypes = ['pdf'];
const documentTypes = ['doc', 'docx', 'txt'];
const excelTypes = ['xls', 'xlsx'];
const powerpoint = ['pptx', 'ppt'];
const zip = ['zip', 'rar'];
const txt = ['txt'];
const json = ['json'];

function FileField({value, item, column, onChange, fieldEditable, readOnly, containerClass}) {
  const field = column?.field;
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const currentFiles = effectiveValue(value) || [];
  const inputUploadFile = useRef();
  const widthHidden = useRef(0);
  const [previewFile, setPreviewFile] = useState(null);
  const [showModalAddLink, setShowModalAddLink] = useState(false);
  const [fileRemove, setFileRemove] = useState(null);
  const [linkDownload, setLinkDownload] = useState(0);
  const widthIconFile = 21;
  const numberFileHidden = Math.floor(widthHidden.current / widthIconFile);

  const onAddItemFile = (file) => {
    const uploadFileParams = {
      file: file,
    };
    dispatch(uploadFileAction(uploadFileParams)).then((result) => {
      let newValues = [];
      if (currentFiles && isArray(currentFiles)) {
        newValues = [...currentFiles, result];
      } else {
        newValues.push(result);
      }
      onChange(item, field, value, {value: newValues});
    });
  };

  const onUploadFile = (e) => {
    const files = e.target.files;
    if (!files) return;
    const file = files[0];
    if (file) {
      onAddItemFile(file);
    }
    e.target.value = null;
  };

  const onShowModalRemoveFile = (idFileRemove) => {
    setFileRemove(idFileRemove);
  };

  const onRemoveFile = () => {
    const newListFile = currentFiles?.filter((file) => {
      return file?._id !== fileRemove;
    });
    onChange(item, field, value, {value: newListFile});
    setFileRemove(null);
  };

  const createFileFromLink = (value) => {
    let newValues = [];
    const newFile = {type: 'link', value: value.value, label: value.label, _id: newId()};
    if (currentFiles && isArray(currentFiles)) {
      newValues = [...currentFiles, newFile];
    } else {
      newValues.push(newFile);
    }
    onChange(item, field, value, {value: newValues});
  };

  const handleOpenLink = (link) => {
    if (link) {
      let newTab = window.open();
      if (!link.startsWith('https://')) {
        newTab.location.href = `https://${link}`;
      } else {
        newTab.location.href = link;
      }
    }
  };

  const handlePreviewFile = (file) => {
    if (file.type === 'link') {
      handleOpenLink(file.value);
      return;
    }
    setPreviewFile(file);
  };

  useEffect(() => {
    if (previewFile?._id) {
      const params = {
        'get-content': true,
      };
      dispatch(downloadLinkFileAction(previewFile._id, params)).then((res) => {
        if (res.data?.link) {
          setLinkDownload(res.data?.link);
        }
      });
    }
  }, [previewFile, dispatch]);

  const popover = () => {
    return (
      <div className='p-4 mw-400px'>
        {currentFiles?.length > 0 && (
          <div className='mb-2'>
            {isArray(currentFiles) &&
              currentFiles?.flatMap((file, index) => {
                if (file) {
                  return (
                    <Badge
                      pill
                      key={file?._id || index}
                      bg='light'
                      className='m-1 p-1 align-items-center align-middle'
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewFile({
                          ...file,
                          display_name: file?.original_name || file?.label,
                        });
                      }}
                    >
                      {getIconFile(file?.extension || file?.type)}
                      <span className='ms-1 text-dark h-100'>
                        {file?.original_name || file?.label}
                        {!file?.extension ? '' : `.${file?.extension}`}
                      </span>
                      {fieldEditable && (
                        <button
                          className='btn btn-xs btn-light symbol symbol-30px symbol-circle w-25px h-25px align-middle'
                          onClick={(e) => {
                            e.stopPropagation();
                            onShowModalRemoveFile(file?._id);
                          }}
                        >
                          <i className='las la-times text-muted'></i>
                        </button>
                      )}
                    </Badge>
                  );
                } else {
                  return [];
                }
              })}
          </div>
        )}
        {fieldEditable && !readOnly && (
          <div className=''>
            <Dropdown.Item
              size='xs'
              className='dropdown-item-xs d-flex align-items-center py-2 rounded'
              onClick={(e) => {
                inputUploadFile?.current?.click();
              }}
            >
              <div className='position-relative w-20px h-15px'>
                <i className='las la-paperclip me-2 fs-5 position-absolute'></i>
              </div>
              {t('sheet_column_file_up_from_computer')}
            </Dropdown.Item>
            <Dropdown.Item
              size='xs'
              className='dropdown-item-xs d-flex align-items-center py-2 rounded'
              onClick={(e) => {
                setShowModalAddLink(true);
              }}
            >
              <div className='position-relative w-20px h-15px'>
                <i className='las la-link me-2 fs-5 position-absolute'></i>
              </div>
              {t('sheet_column_file_up_from_link')}
            </Dropdown.Item>
          </div>
        )}
      </div>
    );
  };

  const previewFilePopover = (file) => {
    if (!file) return <></>;
    if (file?.type === 'link') {
      return (
        <div className='bg-info preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type)}
        </div>
      );
    }
    if (imageTypes.includes(file.extension)) {
      return (
        <div className='bg-info preview-file-popover rounded'>
          <Image
            className='img-fluid image-file-preview rounded-left'
            src={file.url || file.link}
          />
        </div>
      );
    }
    if (pdfTypes.includes(file.extension)) {
      return (
        <div className='bg-danger preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type)}
        </div>
      );
    }
    if (zip.includes(file.extension)) {
      return (
        <div className='bg-warning preview-file-popover rounded'>
          {getIconFile(file?.extension || file?.type)}
        </div>
      );
    }
    return (
      <div className='bg-success preview-file-popover rounded'>
        {getIconFile(file?.extension || file?.type)}
      </div>
    );
  };

  const popoverPreviewFile = (file) => {
    return (
      <Popover id='popover-column-file'>
        <Popover.Body>
          <div className='min-h-200px w-250px'>{previewFilePopover(file)}</div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <DropdownPopover renderPopover={popover}>
        <div
          className='w-100 h-100 border-0 p-0 position-relative button-dropdown-link overflow-hidden file-container'
          variant='none'
        >
          <div
            className={`d-flex align-items-center h-100 cursor-pointer w-100 gap-2 file-item ${clsx(
              {
                'justify-content-start': currentFiles?.length > 0,
                'justify-content-center': !currentFiles?.length,
              }
            )}`}
          >
            {fieldEditable && (
              <div className='add-item-file cursor-pointer'>
                <i className='las la-file-medical text-primary fs-2 icon-file-item'></i>
              </div>
            )}
            <div
              className='d-flex list-current-file'
              ref={(e) => {
                widthHidden.current = e?.scrollWidth - e?.offsetWidth;
                if (e?.scrollWidth > e?.offsetWidth) {
                  e?.style &&
                    Object.assign(e?.style, {
                      justifyContent: 'start',
                      paddingLeft: '16px',
                    });
                } else {
                  e?.style &&
                    Object.assign(e?.style, {
                      justifyContent: 'center',
                      paddingLeft: 0,
                    });
                }
              }}
            >
              {/* {currentFiles && currentFiles?.map((item) => {
                return (
                  <div
                    key={item?._id}
                    className='d-flex align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreviewFile({
                        ...item,
                        display_name: item?.original_name || item?.label,
                      });
                    }}
                  >
                    <OverlayTrigger
                      trigger='hover'
                      placement='top'
                      overlay={popoverPreviewFile(item)}
                    >
                      <div>{getIconFile(item?.extension || item?.type)}</div>
                    </OverlayTrigger>
                  </div>
                );
              })} */}
            </div>
            {numberFileHidden ? (
              <div className='number-file-hidden bg-dark text-white'>{numberFileHidden + 1}</div>
            ) : null}
          </div>
        </div>
      </DropdownPopover>
      <input
        ref={inputUploadFile}
        accept='image/*,video/*,audio/*,.doc,.docx,.pdf,.xlsx,.xls,.ppt,.pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.zip,.rar,.7zip,.json'
        type='file'
        className='d-none'
        onChange={onUploadFile}
      ></input>
      <Modal
        className='sheet-item-detail-file-viewer-dialog'
        show={previewFile !== null}
        onHide={() => setPreviewFile(null)}
        size='xl'
      >
        <ModalBody className='p-0'>
          <ItemFileAttachmentViewer
            currentFile={{...previewFile, link: linkDownload}}
          ></ItemFileAttachmentViewer>
        </ModalBody>
      </Modal>
      {showModalAddLink && (
        <ModalAddLinkFile
          show={showModalAddLink}
          onHide={() => setShowModalAddLink(false)}
          onCreateFileFromLink={createFileFromLink}
        />
      )}
      {fileRemove && (
        <ModalConfirm
          style={{zIndex: 10000}}
          show={!!fileRemove}
          onHide={() => setFileRemove(null)}
          title={'Xoá file'}
          content={'Bạn vẫn muốn xoá file này?'}
          handleExcute={onRemoveFile}
        />
      )}
    </>
  );
}

const getIconFile = (type) => {
  if (type === 'link') {
    return <i className='las la-link icon-thumb fs-3'></i>;
  }
  if (imageTypes?.includes(type)) {
    return <i className='fa-regular fa-file-image icon-thumb fs-3'></i>;
  }
  if (videoTypes?.includes(type)) {
    return <i className='fa-regular fa-file-video icon-thumb fs-3'></i>;
  }
  if (audioTypes?.includes(type)) {
    return <i className='fa-regular fa-file-audio icon-thumb fs-3'></i>;
  }
  if (pdfTypes?.includes(type)) {
    return <i className='fa-regular fa-file-pdf icon-thumb fs-3'></i>;
  }
  if (documentTypes?.includes(type)) {
    return <i className='fa-regular fa-file-word icon-thumb fs-3'></i>;
  }
  if (excelTypes?.includes(type)) {
    return <i className='fa-regular fa-file-excel icon-thumb fs-3'></i>;
  }
  if (powerpoint?.includes(type)) {
    return <i className='fa-regular fa-file-powerpoint icon-thumb fs-3'></i>;
  }
  if (zip?.includes(type)) {
    return <i className='las la-file-archive icon-thumb fs-3'></i>;
  }
  if (txt?.includes(type)) {
    return <i className='fa-regular fa-file-lines icon-thumb fs-3'></i>;
  }
  if (json?.includes(type)) {
    return <i className='fa-regular fa-file-code icon-thumb fs-3'></i>;
  }
};

const ModalAddLinkFile = ({show, onHide, onCreateFileFromLink}) => {
  const {t} = useTranslate();
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');

  const onSaveLinkFile = () => {
    onCreateFileFromLink({value: value, label: label});
    onHide();
  };

  return (
    <Modal className='sheet-item-detail-file-viewer-dialog' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sheet_column_file_up_from_link_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center'>
          <img src='https://cdn.monday.com/images/file-as-link/link-choosing-asset.svg' alt='' />
        </div>
        <div className='mt-2 fw-bold'>{t('sheet_column_file_up_from_link_any')}</div>
        <input
          type='text'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder='Ví dụ. pdf, figma, andobe...'
          className='form-control mt-2'
        />
        <div className='mt-2 fw-bold'>{t('sheet_column_file_link_display')}</div>
        <input
          type='text'
          value={label}
          placeholder='Ví dụ. Thiết kế file v1'
          onChange={(e) => setLabel(e.target.value)}
          className='form-control mt-2'
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <Button variant={'primary'} onClick={onSaveLinkFile}>
          {t('common_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileField;
