import React, {useEffect, useState} from 'react';
import {useDrag} from 'react-dnd';
import {
  hasViewSheetColumnPermission,
  hasEditItemColumnPermission,
} from 'app/modules/work/sheet/permission/permissionHelper';
import {useSession} from 'core/store/hooks';
import ItemField from '../../../field/ItemField';
import { hasEditItemPermission } from 'app/modules/work/sheet/permission/permissionHelper';
import { effectiveValue } from 'app/modules/work/helpers';

const DND_ITEM_TYPE = 'item';

export const ItemCard = ({
  item,
  sheet,
  permissions = {},
  itemSelected,
  displayFields,
  onChange,
  onShowDetail,
  isPermitted,
  hasBoardEditPermission,
}) => {
  const [itemEditable, setItemEditable] = useState(false);
  const {user} = useSession();
  useEffect(() => {
    setItemEditable(hasEditItemPermission(permissions, user, item));
  }, [sheet, user, item]);

  const [{opacity}, dragRef] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: {item},
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.1 : 1,
      }),
    }),
    []
  );

  const containerId = `item_` + item._id;

  const columnTitle = sheet?.columns?.find((column) => column.field === 'title');

  return (
    <div
      className={`card mb-2 mb-xl-2  cursor-pointer kanban-item shadow ${
        itemSelected(item) ? 'kanban-item-selected' : ''
      }`}
      ref={isPermitted ? dragRef : null}
      style={{opacity}}
    >
      <div className='card-body p-2' id={containerId}>
        <div className='d-flex flex-stack mb-2 align-items-start'>
          <div className='flex-grow-1' onClick={(e) => e.stopPropagation()}>
            {itemEditable && hasEditItemColumnPermission(user, columnTitle) ? (
              <textarea
                className='form-control form-control-xs item-text-input'
                defaultValue={effectiveValue(item?.title)}
                onBlur={(e) => onChange(item, 'title', effectiveValue(item?.title), e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }} //Disable new line
              ></textarea>
            ) : (
              <span>{effectiveValue(item?.title)}</span>
            )}
          </div>
          <ItemMenu onShowDetail={() => onShowDetail(item)} />
        </div>
        <div className='d-flex flex-stack flex-wrap'>
          {displayFields.map((field) => {
            const hasViewColumnPermission = hasViewSheetColumnPermission(user, field);
            if (
              (!hasViewColumnPermission && !hasBoardEditPermission) ||
              field.deleted ||
              field.hidden
            ) {
              return <React.Fragment key={item._id + field.field} />;
            } else {
              const column = sheet?.columns?.find((column) => column.field === field?.field);
              return (
                <div
                  key={item._id + field.field}
                  className={`w-100 p-1 kanban-field-container ${
                    !hasViewColumnPermission && hasBoardEditPermission ? 'bg-gray-300' : ''
                  }`}
                >
                  <div className={`kanban-field-container`}>
                    <div
                      className='kanban-field-name text-capitalize text-truncate'
                      style={{minWidth: '80px'}}
                    >
                      {column.name}
                    </div>
                    <div className={`kanban-field-value ms-3 border h-30px ms-2`}>
                      {hasViewColumnPermission && (
                        <ItemField
                          value={item[field.field]}
                          item={item}
                          column={column}
                          sheet={sheet}
                          onChange={onChange}
                          fieldEditable={itemEditable && hasEditItemColumnPermission(user, column)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div className='d-flex my-1'></div>
        </div>
      </div>
    </div>
  );
};

const ItemMenu = ({onShowDetail}) => {
  return (
    <div>
      <button
        type='button'
        className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'
        onClick={onShowDetail}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <i className='las la-expand-arrows-alt'></i>{' '}
      </button>
    </div>
  );
};
