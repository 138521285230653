import React, {useContext, useState, useEffect, useLayoutEffect, useRef} from 'react';
import {Accordion, AccordionContext, useAccordionButton} from 'react-bootstrap';
import {constantForFormula, formulaParser, parseError, supportedFormula} from './FormulaParser';
import './formulaEditor.scss';
import {isEmpty} from 'lodash';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import {capitalizeFirstLetter} from 'app/common/_helpers/FunctionHelper';
import { useTranslate } from 'core/i18n/i18nProvider';

function FormulaEditor({formulaValue, onSave, metaColumns, show}) {
  const [formulaDescription, setFormulaDescription] = useState();
  const [isShowRecommendTooltip, setIsShowRecommendTooltip] = useState(false);
  const [formula, setFormula] = useState('');
  const [formulaColumn, setFormulaColumn] = useState([]);
  const [focusColumn, setFocusColumn] = useState(0);
  const [startFilterColumn, setStartFilterColumn] = useState(false)
  const [formulaError , setFormulaError] = useState(null)
  const editor = document.getElementById('editor-textare')
  const {t} = useTranslate()

  const getEditorFormulaValue = () => {
    let newFormulaValue = formulaValue
    metaColumns.forEach(item => {
      newFormulaValue = newFormulaValue.replaceAll(item?.field, capitalizeFirstLetter(item?.name))
    })
    setFormula(newFormulaValue)
  }

  useLayoutEffect(() => {
    if(!show){
      getEditorFormulaValue()
    }
  },[show])

  useLayoutEffect(() => {
    if(formulaValue){
      getEditorFormulaValue()
    }
  },[formulaValue])

  const moveCursor = () => {
    editor.focus()
    if(editor && editor?.value && editor.value.length){
      editor.selectionStart = editor.selectionEnd = editor.value.length
    }
  }

  const createNewFormula = (text) => {
    let newFormula = formula;
    let cursorPosition = editor.selectionStart
    let beforeText = formula.substring(0,cursorPosition)
    let afterText = formula.substring(cursorPosition)
    newFormula = beforeText + text + afterText
    setFormula(newFormula)
    moveCursor()
  }

  const onClickFormulaFunction = (formulaFunction) => {
    createNewFormula(formulaFunction?.name + '()')
  };

  const onClickFormulaConstant = (formulaConstant) => {
    createNewFormula(formulaConstant?.name)
  };

  const onClickFormulaColumn = (formulaColumn) => {
    let text = '{' + capitalizeFirstLetter(formulaColumn?.name) + '}'
    createNewFormula(text)
  };

  const onClickFormulaColumnRecommend = (formulaColumn) => {
    let text = capitalizeFirstLetter(formulaColumn?.name) + '}'
    createNewFormula(text)
    setIsShowRecommendTooltip(false);
  };

  const onHoverFormulaFunctionAndConstant = (data) => {
    setFormulaDescription(data);
  };

  const getRenderColumns = () => {
    let newFormulaColumn = metaColumns;
    newFormulaColumn = newFormulaColumn.filter((item) => !item?.hidden && !item?.deleted);
    return newFormulaColumn
  }

  useEffect(() => {
    if (isShowRecommendTooltip) {
      if (isEmpty(formula) || !formula.includes('{')) {
        setIsShowRecommendTooltip(false);
      }
    }
  }, [formula]);

  const handleFilterColumn = (newValue) => {
    let filterValue = newValue
    let indexOfBeginFilterValue = newValue.lastIndexOf('{')
    filterValue = filterValue.slice(indexOfBeginFilterValue + 1)
    if(isEmpty(filterValue)){
      setFormulaColumn(getRenderColumns());
    }else{
      const newFormulaColumn = getRenderColumns().filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase())
      )
      setFormulaColumn(newFormulaColumn);
    }
  }

  const onChangFormulaInput = (value) => {
    setFormula(value);
    if(startFilterColumn){
      handleFilterColumn(value)
    }
  };

  const onEditorKeyDown = (e) => {
    const key = e.key;
    const avaibleColumnLength = formulaColumn.length
    if (isShowRecommendTooltip && key === 'Tab') {
      e.preventDefault();
      setIsShowRecommendTooltip(false)
    }
    if (key === 'Enter') {
      if(isShowRecommendTooltip){
        e.preventDefault();
        const selectedColumn = formulaColumn[focusColumn];
        onClickFormulaColumnRecommend(selectedColumn);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowDown') {
      e.preventDefault();
      if (focusColumn < avaibleColumnLength - 1) {
        setFocusColumn(focusColumn + 1);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowUp') {
      e.preventDefault();
      if (focusColumn > 0) {
        setFocusColumn(focusColumn - 1);
      }
    }
    if (isShowRecommendTooltip && key === 'ArrowRight') {
      setIsShowRecommendTooltip(false);
    }
    if (isShowRecommendTooltip && key === 'ArrowLeft') {
      setIsShowRecommendTooltip(false);
    }
    if (isShowRecommendTooltip && key === 'Backspace') {
      const deleteCharacter = formula.slice(-1)
      if(deleteCharacter === '{'){
        setIsShowRecommendTooltip(false);
      }
    }
    if (key === '{') {
      setIsShowRecommendTooltip(true);
    }
    if (key === '}') {
      setIsShowRecommendTooltip(false);
    }
  };

  useEffect(() => {
    setFormulaColumn(getRenderColumns())
    if(isShowRecommendTooltip){
      setStartFilterColumn(true)
    }else{
      setStartFilterColumn(false)
    }
  },[isShowRecommendTooltip])

  useEffect(() => {
    setFocusColumn(0)
  },[formulaColumn])

  useEffect(() => {
    setFormulaColumn(getRenderColumns())
  },[metaColumns])

  const validateFormula = () => {
    let formulaParse = formula
    metaColumns.forEach(item => {
      let text = `{${capitalizeFirstLetter(item?.name)}}`
      formulaParse = formulaParse.replaceAll(text,'1')
    })
    const result = formulaParser.parse(formulaParse)
    if(result.error){
      setFormulaError(parseError[result.error])
      return false
    }else{
      setFormulaError(null)
      return true
    }
  }

  const onSaveFormula = () => {
    if(!validateFormula()) return
    let newFormulaValue = formula
    metaColumns.forEach(item => {
      if(!item?.hidden && !item.deleted){
        let name = `{${capitalizeFirstLetter(item?.name)}}`
        let field = `{${item?.field}}`
        newFormulaValue = newFormulaValue.replaceAll(name, field)
      }
    })
    onSave(newFormulaValue)
  }

  return (
    <div className='p-2'>
      <RecommendToolTip
        show={isShowRecommendTooltip}
        columns={formulaColumn}
        onClickFormulaColumnRecommend={onClickFormulaColumnRecommend}
        focusColumn={focusColumn}
        onHide={() => setIsShowRecommendTooltip(false)}
      />
      <Editor
        textareaId='editor-textare'
        value={formula}
        onValueChange={(code) => onChangFormulaInput(code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        autoFocus
        className='w-100 h-150px border rounded'
        onKeyDown={onEditorKeyDown}
      />
      {formulaError && <div className='w-100 text-danger'>{t(`${formulaError}`)}</div>}
      <div className='d-flex gap-2 w-100 mt-2'>
        <div className='w-50 min-w-200px'>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <AccordionCustomToggle eventKey='0'>{t('formula.column')}</AccordionCustomToggle>
              <Accordion.Body
                className='d-flex flex-column overflow-auto p-3'
                style={{maxHeight: '150px'}}
              >
                {formulaColumn.map((item, index) => (
                  <div
                    className='p-2 rounded cursor-pointer formula-item'
                    key={index}
                    onClick={() => onClickFormulaColumn(item)}
                    onMouseEnter={() =>
                      onHoverFormulaFunctionAndConstant({
                        ...item,
                        description: `${t('formula.add_from_column')} ${capitalizeFirstLetter(item?.name)}`,
                      })
                    }
                  >
                    <i className='las la-columns me-2' />
                    <span className='fw-bold text-capitalize'>{item?.name}</span>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <AccordionCustomToggle eventKey='1'>{t('formula.function')}</AccordionCustomToggle>
              <Accordion.Body
                className='d-flex flex-column overflow-auto p-3'
                style={{maxHeight: '150px'}}
              >
                {supportedFormula.map((item, index) => (
                  <div
                    className='p-2 rounded cursor-pointer formula-item'
                    key={index}
                    onClick={() => onClickFormulaFunction(item)}
                    onMouseEnter={() => onHoverFormulaFunctionAndConstant(item)}
                  >
                    <i className='ki ki-code me-2' />
                    <span className='fw-bold'>{item?.name}</span>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <AccordionCustomToggle eventKey='2'>{t('formula.constants')}</AccordionCustomToggle>
              <Accordion.Body
                className='d-flex flex-column overflow-auto p-3 '
                style={{maxHeight: '150px'}}
              >
                {constantForFormula.map((item, index) => (
                  <div
                    className='p-2 rounded cursor-pointer formula-item'
                    key={index}
                    onClick={() => onClickFormulaConstant(item)}
                    onMouseEnter={() => onHoverFormulaFunctionAndConstant(item)}
                  >
                    <i className='ki ki-code me-2' />
                    <span className='fw-bold'>{item?.name}</span>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className='w-50 bg-white rounded border p-2 min-w-200px'>
          {formulaDescription && (
            <>
              <span className='fw-bold text-capitalize'>{formulaDescription?.name}</span>
              <div>{t(`${formulaDescription?.description}`)}</div>
              <div className='mt-2 fw-bold'>{t('formula.example')}</div>
              <div>{formulaDescription?.example}</div>
            </>
          )}
        </div>
      </div>
      <div className='d-flex mt-2 w-100 justify-content-end'>
        <button className='btn btn-primary btn-sm' onClick={onSaveFormula}>{t('formula.save_formula')}</button>
      </div>
    </div>
  );
}

function AccordionCustomToggle({children, eventKey}) {
  const decoratedOnClick = useAccordionButton(eventKey);
  const {activeEventKey} = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div className={`p-3 cursor-pointer`} onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <i className='fas fa-angle-down me-2' />
      ) : (
        <i className='fas fa-angle-right me-2' />
      )}
      {children}
    </div>
  );
}

function RecommendToolTip({show, columns, onClickFormulaColumnRecommend, focusColumn, onHide}) {
  const recommendTooltipRef = useRef();

  useLayoutEffect(() => {
    const selected = recommendTooltipRef?.current?.querySelector('.formula-item-focus');
    if (selected) {
      selected?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [focusColumn]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (recommendTooltipRef.current && !recommendTooltipRef.current.contains(event.target)) {
        onHide();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [recommendTooltipRef]);
  
  if (!show) return <></>;
  return (
    <>
      <div className='d-none tooltip'></div>
      <div
        ref={recommendTooltipRef}
        className='tooltip-content bg-white rounded p-2 h-200px shadow overflow-auto'
      >
        {columns.map((item, index) => (
          <div
            className={`p-2 rounded cursor-pointer formula-item ${
              index === focusColumn && 'formula-item-focus'
            }`}
            key={index}
            onClick={() => onClickFormulaColumnRecommend(item)}
          >
            <i className='las la-columns me-2' />
            <span className='fw-bold text-capitalize'>{item?.name}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default FormulaEditor;
