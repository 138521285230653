/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {emitter} from 'core/helpers/EventEmitter';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {cloneDeep} from 'lodash';
import SheetAddColumn from '../../sheet-config/column/SheetAddColumn';
import '../../header/sheetHeader.scss';
import '../../field/checkbox/checkbox-style.scss';

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className='btn btn-clean btn-hover-light-primary btn-xs btn-icon'
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});

export const HeaderFormatter = ({
  column,
  editable,
  onEdit,
  onDelete,
  onToggleColumnPermitted,
  onOpenPermittedEditColumn,
  onEditFormula,
  onUpdateValidation,
  onSaveColumn,
  onOpenUsersCanViewColumnDialog,
  style,
}) => {
  const onEditColumnValidation = () => {
    emitter.emit('edit-validation', column?.column, onUpdateValidation);
  };
  const {t} = useTranslate();
  const isViewPermitted = column?.column?.is_view_permitted || false;
  const [subItemPlacement, setSubItemPlacement] = useState('right');
  const headerMenuRef = useRef();
  const handleClickLimitShowColumn = () => {
    const newColumn = {...column?.column, is_view_permitted: !isViewPermitted};
    onSaveColumn && onSaveColumn(newColumn);
    if (!isViewPermitted) {
      const _column = cloneDeep(column);
      _column.column.is_view_permitted = !isViewPermitted;
      onOpenUsersCanViewColumnDialog(_column);
    }
  };

  const handleSubItemPlacement = (element) => {
    const windowBreakPoint = window.innerWidth / 2 + 100;
    if (windowBreakPoint < element.getBoundingClientRect().x) {
      setSubItemPlacement('left');
    } else {
      setSubItemPlacement('right');
    }
  };

  useLayoutEffect(() => {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        handleSubItemPlacement(headerMenuRef?.current);
      });
    });
    if (headerMenuRef?.current) {
      observer.observe(headerMenuRef?.current, {
        root: document,
        rootMargin: '0px',
        threshold: '1.0',
      });
      handleSubItemPlacement(headerMenuRef?.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [headerMenuRef]);

  return (
    <div
      className='d-flex align-items-center justify-content-between'
      style={style}
      ref={headerMenuRef}
    >
      <span className='flex-grow-1 flex-shrink-1 sheet-column-header-label py-2'>
        <span className={`text-opt-secondary fw-bolder ${editable ? 'ms-4' : 'ms-0'}`}>
          {column.text}
        </span>
        {column?.column?.is_permitted && <i className='las la-lock ms-2'></i>}
      </span>
      {editable && (
        <div className='w-25px'>
          <Dropdown className='dropdown-inline sheet-column-header-tool' align='end'>
            <Dropdown.Toggle id='dropdown-toggle-top' as={DropdownCustomToggler}>
              <i className='ki ki-bold-more-hor me-4' />
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu dropdown-menu-xs dropdown-menu-right px-2 w-200px'>
              <ul className='menu menu-hover menu-column'>
                {editable && column?.column?.type === 'formula' && (
                  <li className='menu-item'>
                    <a href='#' onClick={onEditFormula} className='menu-link rounded-sm text-dark'>
                      <span className='menu-text'>
                        <i className='la la-pen me-2' />
                        <span className='fw-normal'>{t('sheet_table_view_edit_formula')}</span>
                      </span>
                    </a>
                  </li>
                )}
                {editable && (
                  <li className='menu-item'>
                    <a href='#' onClick={onEdit} className='menu-link rounded-sm text-dark'>
                      <span className='menu-text'>
                        <i className='la la-pen me-2' />
                        <span className='fw-normal'>{t('sheet_table_view_edit_column')}</span>
                      </span>
                    </a>
                  </li>
                )}
                {editable && (
                  <li className='menu-item bg-hover-light position-relative menu-item-parent sheet-header-menu'>
                    <a href='#' className='menu-link rounded-sm text-dark'>
                      <span className='menu-text'>
                        <i className='la la-cog me-2' />
                        <span className='fw-normal'>
                          {t('sheet_table_view_edit_column_setting')}
                        </span>
                      </span>
                    </a>
                    <ul
                      className={`menu menu-column menu-hover p-3 ${
                        subItemPlacement === 'right' ? 'sub-menu-item' : 'sub-menu-item left'
                      } shadow rounded bg-body`}
                    >
                      <li className='menu-item bg-hover-light p-0 rounded'>
                        <a
                          href='#'
                          className='menu-link p-2 text-dark'
                          onClick={onEditColumnValidation}
                        >
                          <span className='menu-text'>
                            <i className='la la-cog me-2' />
                            <span className='fw-normal'>
                              {t('sheet_table_view_edit_column_setting_validation')}
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
                {editable && (
                  <li className='menu-item'>
                    <a
                      href='#'
                      onClick={onToggleColumnPermitted}
                      className='menu-link rounded-sm text-dark'
                    >
                      <span className='menu-text'>
                        {column?.column?.is_permitted ? (
                          <>
                            <i className='las la-unlock me-2'></i>
                            <span className='fw-normal'>
                              {t('sheet_table_view_permitted_unlock')}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className='las la-lock me-2'></i>
                            <span className='fw-normal'>
                              {t('sheet_table_view_permitted_lock')}
                            </span>
                          </>
                        )}
                      </span>
                    </a>
                  </li>
                )}
                {column?.column?.is_permitted && editable && (
                  <li className='menu-item'>
                    <a
                      href='#'
                      onClick={onOpenPermittedEditColumn}
                      className='menu-link rounded-sm text-dark'
                    >
                      <span className='menu-text'>
                        <i className='las la-user-edit me-2'></i>
                        <span className='fw-normal'>{t('sheet_table_view_permitted')}</span>
                      </span>
                    </a>
                  </li>
                )}
                {/*setting limit view column*/}
                <li className='menu-item'>
                  <a
                    href='#'
                    onClick={handleClickLimitShowColumn}
                    className='menu-link rounded-sm text-dark'
                  >
                    <span className='menu-text'>
                      <i className={`las ${isViewPermitted ? 'la-eye' : 'la-eye-slash'} me-2`}></i>
                      <span className='fw-normal'>
                        {t(
                          isViewPermitted
                            ? 'sheet_table_view_disable_limit_access_column'
                            : 'sheet_table_view_enable_limit_access_column'
                        )}
                      </span>
                    </span>
                  </a>
                </li>
                {isViewPermitted && (
                  <li className='menu-item'>
                    <a
                      href='#'
                      onClick={() => onOpenUsersCanViewColumnDialog(column)}
                      className='menu-link rounded-sm text-dark'
                    >
                      <span className='menu-text'>
                        <i className='la la-users me-2'></i>
                        <span className='fw-normal'>{t('sheet_table_view_people_can_access')}</span>
                      </span>
                    </a>
                  </li>
                )}

                {editable && (
                  <li className='menu-item'>
                    <a href='#' onClick={onDelete} className='menu-link rounded-sm text-dark'>
                      <span className='menu-text'>
                        <i className='la la-trash me-2' />
                        <span className='fw-normal'>{t('sheet_table_view_delete')}</span>
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export const HeaderAddColumnFormatter = ({column, colIndex, onAdd}) => {
  const [show, setShow] = useState(false);
  const {t} = useTranslate();

  const onToggle = () => {
    setShow(!show);
  };

  const onSubmit = (data) => {
    onAdd && onAdd(data);
    setShow(false);
  };

  return (
    <div className='w-100 d-flex align-items-center justify-content-left'>
      <Dropdown show={show} onToggle={onToggle}>
        <Dropdown.Toggle variant='none'>
          <OverlayTrigger
            key='add-column'
            placement='top'
            overlay={
              <Tooltip
                className='tooltip-inverse tooltip-add-new-column'
                id='tooltip-add-new-column'
              >
                {t('sheet_table_view_add_column')}
              </Tooltip>
            }
          >
            <i className='icon-sm ki ki-plus' />
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SheetAddColumn onSubmit={onSubmit} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const HeaderCheckboxColumnFormatter = (
  column,
  colIndex,
  {selectAll, checked, totalItemCheck, totalItemInGroup}
) => {
  return (
    <>
      <label className='checkbox checkbox-single field-custom-checkbox'>
        <input type='checkbox' className='w-full h-full' onChange={selectAll} checked={checked} />
        <span />
      </label>
    </>
  );
};

export function ColumnHeaderMenu() {
  return (
    <>
      {/*begin::menugation*/}
      <ul className='menu menu-hover'>
        <li className='menu-header font-weight-bold py-5'>
          <span className='font-size-lg'>Choose Label:</span>
          <i
            className='flaticon2-information icon-md text-muted'
            data-toggle='tooltip'
            data-placement='right'
            title='Click to learn more...'
          ></i>
        </li>
        <li className='menu-separator mb-3 opacity-70'></li>
        <li className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-text'>
              <span className='label label-xl label-inline label-light-success'>Customer</span>
            </span>
          </a>
        </li>
        <li className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-text'>
              <span className='label label-xl label-inline label-light-danger'>Partner</span>
            </span>
          </a>
        </li>
        <li className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-text'>
              <span className='label label-xl label-inline label-light-warning'>Suplier</span>
            </span>
          </a>
        </li>
        <li className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-text'>
              <span className='label label-xl label-inline label-light-primary'>Member</span>
            </span>
          </a>
        </li>
        <li className='menu-item'>
          <a href='#' className='menu-link'>
            <span className='menu-text'>
              <span className='label label-xl label-inline label-light-dark'>Staff</span>
            </span>
          </a>
        </li>
        <li className='menu-separator mt-3 opacity-70'></li>
        <li className='menu-footer pt-5 pb-4'>
          <a className='btn btn-clean font-weight-bold btn-sm' href='#'>
            <i className='ki ki-plus icon-sm'></i>
            Add new
          </a>
        </li>
      </ul>
      {/*end::menugation*/}
    </>
  );
}
