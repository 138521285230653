import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import './linkField.scss';
import {useTranslate} from 'core/i18n/i18nProvider';
import DropdownPopover from 'app/common/_partials/dropdowns/DropdownPopover';
function LinkField({value, item, column, onChange, fieldEditable, readOnly, containerClass}) {
  const field = column?.field;
  const {t} = useTranslate();
  const [link, setLink] = useState('');
  const [label, setLabel] = useState('');

  const onBlur = (value, type) => {
    if (type === 'link') {
      if (value !== value?.value) {
        onChange(item, field, value, {value: value, label: label});
      }
    } else {
      if (value !== value?.label) {
        onChange(item, field, value, {value: link, label: value});
      }
    }
  };

  const handleRemoveLink = (e) => {
    if (value?.value) {
      setLink('');
      e.stopPropagation();
      onChange(item, field, value, {value: null, label: null});
    }
  };

  const handleOpenLink = (e) => {
    if (value?.value) {
      e.stopPropagation();
      let newTab = window.open();
      if (!value.value.startsWith('https://')) {
        newTab.location.href = `https://${value?.value}`;
      } else {
        newTab.location.href = value?.value;
      }
    }
  };

  useEffect(() => {
    setLabel(value?.label);
    setLink(value?.value);
  }, [value]);

  const popover = () => {
    return (
      <div className='w-300px p-5'>
        <div className='fw-bold fs-4'>{t('sheet.formatter.field.link_link')}</div>
        <div className='mt-2'>{t('sheet.formatter.field.link_paste_or_type')}</div>
        <input
          type='text'
          value={link}
          onChange={(e) => setLink(e.target.value)}
          onBlur={(e) => onBlur(e.target.value, 'link')}
          className='form-control form-control-xs mt-2'
        />
        <div className='mt-2'>{t('sheet.formatter.field.link_display')}</div>
        <input
          type='text'
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          defaultValue={value?.label}
          onBlur={(e) => onBlur(e.target.value, 'label')}
          className='form-control form-control-xs mt-2'
        />
      </div>
    );
  };

  if (fieldEditable && !readOnly) {
    return (
      <DropdownPopover renderPopover={popover}>
        <div
          className={clsx(
            'w-100 border-0 p-0 position-relative button-dropdown-link',
            containerClass
          )}
          variant='none'
        >
          <div
            onClick={handleOpenLink}
            className='text-primary link-value link-limit-text-1 h-25px'
          >
            {value?.label || value?.value}
          </div>
          {value?.value && (
            <div className='icon-remove-link' onClick={handleRemoveLink}>
              <i className='la la-times'/>
            </div>
          )}
        </div>
      </DropdownPopover>
    );
  }

  return (
    <div
      className={clsx('w-100 border-0 p-0 position-relative button-dropdown-link', containerClass)}
    >
      <div onClick={handleOpenLink} className='text-primary link-value link-limit-text-1 h-25px'>
        {value?.label || value?.value}
      </div>
    </div>
  );
}

export default LinkField;
