import 'react-datepicker/dist/react-datepicker.css';
import './itemDetail.scss';

import {Card, CardBody} from 'app/common/_partials/controls';
import {Dropdown, Modal, ModalBody, Nav, Tab} from 'react-bootstrap';
import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {
  hasEditItemColumnPermission,
  hasEditItemPermission,
  hasSheetPermission,
  hasViewSheetColumnPermission,
  sheetActions,
} from '../../permission/permissionHelper';

import ApprovalDetailField from '../field/approval/ApprovalDetailField';
import ItemAuditListView from './ItemAuditList';
import ItemContent from './ItemContent';
import ItemField from '../field/ItemField';
import {ItemFileAttachmentViewer} from './ItemFileAttachmentViewer';
import ItemFileView from './ItemFile';
import ItemUpdateAdd from './ItemUpdateAdd';
import ItemUpdateView from './ItemUpdate';
import {downloadLinkFileAction} from '../../stores/sheetActions';
import {effectiveValue} from '../../../helpers';
import {useDispatch} from 'react-redux';
import {useItemDetail} from './ItemDetailHook';
import {useSession} from 'core/store/hooks';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import {useValidation} from '../../validation/useValidation';
import {validationErrorCode} from '../../validation/constants';
import {cloneDeep} from 'lodash';
import ModalConfirm from 'app/common/_partials/controls/ModalConfirm';
import Textarea from 'app/common/_partials/controls/Textarea';
import CustomToggle from 'app/common/_partials/controls/CustomToggle';
import copy from 'clipboard-copy';
import clsx from 'clsx';

const ItemDetail = ({
  item,
  sheet,
  permissions = {},
  view,
  onHide,
  onEditItem,
  onDelete,
  onUpdateItem,
  onSaveItemAction,
  onMoveItem,
  readOnly = false,
  allowChangeGroup = false,
  showDialogControl = true,
  displayProps = {},
}) => {
  const {onToggleExpanded, onToggleAlign, onToggleDisplayMode, isFullScreen} = displayProps ?? {};
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {getValidationErrorMessage} = useValidation({});

  const {
    itemAudits,
    itemUpdates,
    addItemUpdate,
    saveItemUpdate,
    deleteItemUpdate,
    itemFiles,
    addItemFile,
    currentFile,
    setCurrentFile,
    fetchItemFiles,
  } = useItemDetail(item);

  const disableFieldColumn = ['title'];
  const disableColumn = [];

  const [selectedTab, setSelectedTab] = useState('update');
  const [itemEditable, setItemEditable] = useState(false);
  const [itemUpdateToDelete, setItemUpdateToDelete] = useState(null);
  const [fileUpdateToDelete, setFileUpdateToDelete] = useState(null);
  const {user} = useSession();
  const configurable = hasSheetPermission(sheet, user, sheetActions.config);
  const fileUploadRef = useRef(null);
  const itemContentRef = useRef(null);
  const sheetColumns = useMemo(() => {
    let columns = sheet?.columns ?? [];
    if (view?.columns) {
      let listColumnsByView = [];
      view.columns.forEach((item) => {
        let columnData = columns.find((column) => column.field === item.field);
        if (columnData) {
          listColumnsByView.push({
            ...columnData,
            hidden: item.hidden,
          });
        }
      });
      let listColumns = columns.filter(
        (item) => !listColumnsByView.find((column) => column.field === item.field)
      );
      listColumns = listColumns.map((item) => {
        return {...item, hidden: false};
      });
      columns = [...listColumnsByView, ...listColumns];
    }
    return columns ?? [];
  }, [sheet?.columns, view?.columns]);
  useEffect(() => {
    let _itemEditable = hasEditItemPermission(permissions, user, item);
    setItemEditable(_itemEditable);
  }, [permissions, user, item]);

  const onSaveItem = (item, field, oldValue, newValue, callback) => {
    if (oldValue !== newValue) {
      if (onUpdateItem) {
        const defaultCallback = (res) => {
          if (res?.data?.success) {
            snackNotification.showSuccess(t('common_update_success'));
          } else {
            if (res?.data?.code === validationErrorCode) {
              snackNotification.showError(
                res?.data?.message
                  ? getValidationErrorMessage(res?.data?.message)
                  : t('common_update_error')
              );
            } else {
              snackNotification.showError(
                res?.data?.message ? t(res?.data?.message) : t('common_update_error')
              );
            }
          }
        };
        onUpdateItem(item, field, oldValue, newValue, callback ?? defaultCallback);
      } else {
        //saveItemInternal(item, field, oldValue, newValue);
      }
    }
  };

  const onAddItemUpdate = (values) => {
    addItemUpdate && addItemUpdate(values);
  };

  const onDeleteItemUpdateInit = (itemUpdate) => {
    setItemUpdateToDelete(itemUpdate);
  };

  const onDeleteItemUpdateConfirm = () => {
    if (itemUpdateToDelete) {
      deleteItemUpdate(itemUpdateToDelete, () => {
        setItemUpdateToDelete(null);
      });
    }
  };

  const onDeleteFileUpdateConfirm = () => {
    if (fileUpdateToDelete) {
      deleteItemUpdate(fileUpdateToDelete, () => {
        setFileUpdateToDelete(null);
        fetchItemFiles();
      });
    }
  };

  const onSaveItemTitle = (e) => {
    const initialValue = effectiveValue(item?.title);
    const newValue = e.target.value;

    if (initialValue === newValue) {
      return;
    }

    onUpdateItem &&
      onUpdateItem(item, 'title', effectiveValue(item?.title), newValue, () => {
        snackNotification.showSuccess(t('common_save_success'));
      });
  };

  const onSaveItemDescription = ({content}, callback) => {
    onUpdateItem &&
      onUpdateItem(item, 'content', item.content, content, () => {
        snackNotification.showSuccess(t('common_save_success'));
        callback && callback();
      });
  };

  const onUploadFile = (e) => {
    const files = e.target.files;
    if (!files) return;
    const file = files[0];
    onAddItemFile(file);
    e.target.value = null;
  };

  const onAddItemFile = (file) => {
    addItemFile(file);
  };

  const onViewFile = (itemFile) => {
    const params = {
      'get-content': true,
    };
    dispatch(downloadLinkFileAction(itemFile._id, params)).then((res) => {
      if (res.data?.link) {
        setCurrentFile({...itemFile, link: res.data?.link});
      }
    });
  };

  const columnTitle = sheet?.columns?.find(
    (column) => !column?.deleted && column.field === 'title'
  );

  const sheetGroupsIncludingDefault = useMemo(() => {
    if (sheet?.groups) {
      const _sheetGroups = cloneDeep(sheet.groups);
      return [
        ..._sheetGroups,
        {
          id: 'default',
          title: t('sheet_empty_group'),
        },
      ];
    }

    return null;
  }, [sheet?.groups, t]);

  const group = useMemo(() => {
    if (sheetGroupsIncludingDefault) {
      return sheetGroupsIncludingDefault?.find((aGroup) => aGroup?.id === item?.group);
    }

    return null;
  }, [sheetGroupsIncludingDefault, item?.group]);

  const renderFieldControl = (column) => {
    return (
      <ItemField
        value={item?.[column?.field]}
        item={item}
        sheet={sheet}
        column={column}
        mode='detail'
        onChange={onSaveItem}
        fieldEditable={itemEditable && hasEditItemColumnPermission(user, column, item)}
      />
    );
  };

  const renderField = (column) => {
    const hasViewColumnPermission = hasViewSheetColumnPermission(user, column);
    if (
      !hasViewColumnPermission ||
      column.deleted ||
      disableFieldColumn.includes(column.field) ||
      disableColumn.includes(column.type) ||
      column.field === 'complete'
    ) {
      return null;
    }
    if (column.type === 'approval') {
      return (
        <ApprovalDetailField
          key={column.field}
          column={column}
          item={item}
          onChange={onSaveItem}
          members={sheet?.members}
          onAction={onSaveItemAction}
          valueField={item?.[column?.field]}
        />
      );
    }

    return (
      <div className='item-detail-field'>
        <div className='item-detail-field-label text-capitalize text-truncate'>
          <p>{column?.name}</p>
        </div>
        <div className='item-detail-field-value'>
          <ItemField
            value={item?.[column?.field]}
            item={item}
            sheet={sheet}
            column={column}
            mode='detail'
            onChange={onSaveItem}
            fieldEditable={itemEditable && hasEditItemColumnPermission(user, column, item)}
          />
        </div>
      </div>
    );
  };

  const onOpenUpdate = () => {
    setSelectedTab('update');
    itemContentRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  };

  const onOpenActivityLog = () => {
    setSelectedTab('activity_log');
    itemContentRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  };

  const onShareLink = () => {
    copy(document.URL).then(() => {
      snackNotification.showSuccess(t('common_copy_success'));
    });
  };

  return (
    <>
      <div className='item-detail-container'>
        <div className='item-detail-header'>
          <div className='d-flex'>
            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={() => onHide && onHide()}
            >
              <span className='icon-close fs-icon' />
            </div>

            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={() => onToggleExpanded && onToggleExpanded()}
            >
              {isFullScreen ? (
                <i className='la la-compress-arrows-alt fs-icon' />
              ) : (
                <i className='la la-expand-arrows-alt fs-icon' />
              )}
            </div>
            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={() => onToggleDisplayMode && onToggleDisplayMode()}
            >
              <span className='icon-switch fs-icon' />
            </div>
            {showDialogControl && (
              <>
                <div
                  className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
                  onClick={() => onToggleAlign && onToggleAlign(false)}
                >
                  <span className='icon-arrowup fs-icon' />
                </div>
                <div
                  className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
                  onClick={() => onToggleAlign && onToggleAlign(true)}
                >
                  <span className='icon-arrow-down fs-icon' />
                </div>
              </>
            )}
          </div>
          <div className='d-flex'>
            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={onShareLink}
            >
              <span className='icon-share fs-icon' />
            </div>
            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={onOpenUpdate}
            >
              <span className='icon-message fs-icon' />
            </div>
            <div
              className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'
              onClick={onOpenActivityLog}
            >
              <span className='icon-clock fs-icon' />
            </div>
            <div className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'>
              <span className='icon-favourites fs-icon' />
            </div>
            {!readOnly && itemEditable && (
              <Dropdown autoclose='true'>
                <Dropdown.Toggle as={CustomToggle}>
                  <div className='d-flex justify-content-center align-items-center p-1 cursor-pointer hover-secondary'>
                    <span className='icon-more fs-icon' />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-2'>
                  <div
                    className='w-100 d-flex justify-content-start align-items-center p-1 cursor-pointer hover-secondary w-50 gap-2'
                    onClick={() => onDelete(item)}
                  >
                    <span className='icon-trash fs-icon' />
                    <span>{t('sheet_item_detail_update_delete')}</span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className='item-detail-title'>
          {!readOnly &&
          itemEditable &&
          columnTitle &&
          hasEditItemColumnPermission(user, columnTitle) ? (
            <Textarea
              readOnly={!itemEditable}
              className='h1 w-100'
              placeholder='Nhập item'
              defaultValue={effectiveValue(item?.title)}
              onBlur={onSaveItemTitle}
              textEditable
              key={`title_${item?._id}`}
            />
          ) : (
            <span className='h1 w-100'>{effectiveValue(item?.title)}</span>
          )}
        </div>
        <div className={clsx('item-detail-main', isFullScreen && 'item-detail-main-full')}>
          <div className='item-detail-field'>
            <div className='item-detail-field-label text-capitalize text-truncate'>
              <p>{t('sheet_item_detail_comment')}</p>
            </div>
            <div className='item-detail-description'>
              <ItemContent
                item={item}
                updateContent={onSaveItemDescription}
                editable={itemEditable && !readOnly}
              />
            </div>
          </div>
          {sheetColumns?.map((column, index) => (
            <Fragment key={index}>{renderField(column)}</Fragment>
          ))}
          {allowChangeGroup && (
            <div className='item-detail-field'>
              <div className='item-detail-field-label text-capitalize text-truncate'>
                <p>{t('sheet_item_detail_group')}</p>
              </div>
              <div className='item-detail-field-value'>
                <Dropdown>
                  <Dropdown.Toggle
                    size='sm'
                    variant='none'
                    className='text-truncate text-start p-0'
                  >
                    <span>{group?.title}</span>
                  </Dropdown.Toggle>
                  {itemEditable && (
                    <Dropdown.Menu>
                      {sheetGroupsIncludingDefault &&
                        sheetGroupsIncludingDefault.map((group) => {
                          if (group?.id !== item?.group) {
                            return (
                              <Dropdown.Item
                                className={`table-column-color-option text-dark`}
                                key={group?.id}
                                size='xs'
                                onClick={() => {
                                  onEditItem({...item, group: group?.id});
                                  onMoveItem(item, null, null, group?.id);
                                }}
                              >
                                <div className='h-25px py-1'>{group?.title || ''}</div>
                              </Dropdown.Item>
                            );
                          } else {
                            return <React.Fragment key={group?.id} />;
                          }
                        })}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>
            </div>
          )}
          <Tab.Container activeKey={selectedTab} onSelect={setSelectedTab}>
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between' ref={itemContentRef}>
              <Nav
                as='ul'
                role='tablist'
                className='nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1'
              >
                <Nav.Item as='li'>
                  <Nav.Link
                    eventKey='update'
                    className={`nav-link ${selectedTab === 'update' ? 'active fw-bold' : ''}`}
                  >
                    {t('sheet_item_detail_update')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                  <Nav.Link
                    eventKey='file'
                    className={`nav-link ${selectedTab === 'file' ? 'active fw-bold' : ''}`}
                  >
                    {t('sheet_item_detail_file')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                  <Nav.Link
                    eventKey='activity_log'
                    className={`nav-link ${selectedTab === 'activity_log' ? 'active fw-bold' : ''}`}
                  >
                    {t('sheet_item_detail_activity_log')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {/*end::Header*/}

            {/*begin::Content*/}
            <div className='offcanvas-content'>
              <div className='tab-content'>
                <div
                  id='kt_quick_panel_updates'
                  role='tabpanel'
                  className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                    selectedTab === 'update' ? 'active show' : ''
                  }`}
                >
                  <div className='mb-15 d-flex flex-column gap-5'>
                    {!readOnly && (
                      <ItemUpdateAdd addItemUpdate={onAddItemUpdate} item={item}></ItemUpdateAdd>
                    )}
                    {itemUpdates &&
                      itemUpdates.map((itemUpdate) => {
                        return (
                          <div className='mt-2' key={itemUpdate._id}>
                            <ItemUpdateView
                              itemUpdate={itemUpdate}
                              saveItemUpdate={saveItemUpdate}
                              deleteItemUpdate={onDeleteItemUpdateInit}
                              readOnly={readOnly}
                            ></ItemUpdateView>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div
                  id='kt_quick_panel_file'
                  role='tabpanel'
                  className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                    selectedTab === 'file' ? 'active show' : ''
                  }`}
                >
                  <div>
                    <Card className='item-detail-dialog-card'>
                      <CardBody className='p-0'>
                        <div className='ps-1 d-flex flex-wrap align-items-center gap-3'>
                          {!readOnly && itemEditable && (
                            <div
                              className='sheet-item-file-thumb-add-new rounded d-flex align-items-center justify-content-center cursor-pointer'
                              onClick={() => fileUploadRef?.current?.click()}
                            >
                              <input
                                type='file'
                                className='d-none'
                                onChange={onUploadFile}
                                accept='image/*,video/*,audio/*,.doc,.docx,.pdf,.xlsx,.xls,.ppt,.pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.zip,.rar,.7zip,.json'
                                ref={fileUploadRef}
                              ></input>
                              <i className='fa-solid fa-plus icon-add-image'></i>
                            </div>
                          )}

                          {itemFiles &&
                            itemFiles.map((itemFile) => {
                              return (
                                <div className='position-relative' key={itemFile?._id}>
                                  {itemEditable && !readOnly && (
                                    <div
                                      className='position-absolute top-0 end-0 p-1'
                                      onClick={() => setFileUpdateToDelete(itemFile)}
                                    >
                                      <i className='las la-times-circle text-dark cursor-pointer w-20px h-20px fs-3'></i>
                                    </div>
                                  )}
                                  <ItemFileView
                                    itemFile={itemFile}
                                    viewFile={onViewFile}
                                  ></ItemFileView>
                                </div>
                              );
                            })}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div
                  id='kt_quick_panel_activity_log'
                  role='tabpanel'
                  className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                    selectedTab === 'activity_log' ? 'active show' : ''
                  }`}
                >
                  <div className='mb-15'>
                    <ItemAuditListView itemAudits={itemAudits} sheet={sheet} />
                  </div>
                </div>
              </div>
            </div>
            {/*end::Content*/}
          </Tab.Container>
        </div>
      </div>
      <Modal
        className='sheet-item-detail-file-viewer-dialog'
        show={currentFile !== null}
        onHide={() => setCurrentFile(null)}
        size='xl'
      >
        <ModalBody className='p-0'>
          <ItemFileAttachmentViewer
            files={itemFiles}
            currentFile={currentFile}
          ></ItemFileAttachmentViewer>
        </ModalBody>
      </Modal>
      <ModalConfirm
        style={{zIndex: 1400}}
        show={itemUpdateToDelete !== null}
        onHide={() => setItemUpdateToDelete(null)}
        title={t('sheet_item_delete_update_confirm_title')}
        content={t('sheet_item_delete_update_confirm')}
        handleExcute={onDeleteItemUpdateConfirm}
      />
      <ModalConfirm
        style={{zIndex: 1400}}
        show={fileUpdateToDelete !== null}
        onHide={() => setFileUpdateToDelete(null)}
        title={t('sheet_item_delete_file_confirm_title')}
        content={t('sheet_item_delete_file_confirm')}
        handleExcute={onDeleteFileUpdateConfirm}
      />
    </>
  );
};

export default ItemDetail;
