import {getIncomers, useReactFlow} from 'reactflow';
import {getLayoutedElements, worflowNodeTypes, workflowCreateNodeDefault} from '../workflowHelper';
import {get, uniqueId} from 'lodash';
import {useDispatch} from 'react-redux';
import {
  createNodeAction,
  deleteNodeAction,
  getWorkflowNodesAction,
  updateNodeAction,
} from '../stores/actions';
import {useParams} from 'react-router-dom';

export const useWorkflow = () => {
  const NODE_WIDTH = 300;
  const NODE_HEIGHT = 75;
  const {getEdges, setEdges, getNodes, setNodes, fitView} = useReactFlow();
  const dispatch = useDispatch();
  const {id: workflowId} = useParams();

  const addNode = async (sourceId, block) => {
    let newNodes = getNodes();
    let newEdges = getEdges();
    let type = block?.type;
    let newNode = get(workflowCreateNodeDefault, type);

    if (newNode) {
      const sourceNode = newNodes.find((node) => node.id === sourceId);
      let newSourceNode = {
        ...sourceNode,
        data: {...newNode?.data, ...sourceNode, block: block, label: block?.name, empty: false},
        type: newNode?.type,
      };

      //create node and receive id from server
      let incomers = getIncomers(newSourceNode, newNodes, newEdges);
      let incomer = incomers[0];
      let branch = '';
      if(incomer){
        let currentEdge = newEdges.find(
          (edge) => edge.target === sourceNode.id && edge.source === incomer.id
        );
        
        let incomerBranches = incomer?.data?.block?.branches
        branch = incomerBranches.find(branch => branch.name === currentEdge.label)?.value
      }
      const nodeCreateData = {
        workflow_id: workflowId,
        block: block,
        config: newSourceNode?.data?.config ?? {},
        previous: incomer?.id,
        previous_branch: branch ?? 'true',
        label: newSourceNode?.data?.label ?? '',
        type: newSourceNode?.type,
      };
      const res = await dispatch(createNodeAction(nodeCreateData));
      if (res && res?.data) {
        newSourceNode.id = res?.data?._id;
      }
      //update node id
      newNodes = newNodes.map((node) => {
        if (node.id === sourceId) {
          return newSourceNode;
        }
        return node;
      });

      newEdges = newEdges.map((edge) => {
        if (edge?.source === sourceId) {
          return {...edge, source: newSourceNode?.id, style: {}};
        } else if (edge?.target === sourceId) {
          return {...edge, target: newSourceNode?.id, style: {}};
        } else {
          return edge;
        }
      });
      //create temporary node and create new edge to connect
      let branches = block?.branches ?? [];

      branches.forEach((branch, index) => {
        let tempNodeId = uniqueId();
        newNodes.push({
          ...get(workflowCreateNodeDefault, 'temp'),
          id: tempNodeId,
          position: {x: 0, y: 0},
        });
        newEdges.push({
          id: uniqueId(),
          label: branch?.name,
          source: newSourceNode?.id,
          target: tempNodeId,
          type: 'workflowEdge',
          style: {
            strokeDasharray: [5, 10],
          },
        });
      });
      const result = await getLayoutedElements(newNodes, newEdges);
      setEdges(result?.edges);
      setNodes(result?.nodes);
      setTimeout(() => {
        fitView({padding: 1});
      }, 100);
    }
  };

  const removeNode = (nodeId) => {
    dispatch(deleteNodeAction(nodeId)).then((res) => {
      if (res.success) {
        dispatch(getWorkflowNodesAction({workflow_id: workflowId}));
      }
    });
  };

  const saveNode = (nodeId, newNode) => {
    // update node
    let newNodes = getNodes();
    newNodes = newNodes.map((node) => {
      if (node.id === nodeId) {
        return {...node, ...newNode};
      }
      return node;
    });
    setNodes(newNodes);
    // save node to server
    return dispatch(updateNodeAction(nodeId, newNode?.data));
  };

  const changeNodeType = (nodeId, type) => {
    setNodes((prev) => prev.map((node) => ({...node, type: worflowNodeTypes[type]})));
  };

  return {
    addNode,
    removeNode,
    saveNode,
    changeNodeType,
    getEdges,
    setEdges,
    getNodes,
    setNodes,
    NODE_WIDTH,
    NODE_HEIGHT,
  };
};
