import {
  addItemsAndSubItemsAction,
  addMultipleSheetItemsAction,
  addSheetItemAction,
  copyColumnAction,
  deleteSheetItemAction,
  deleteSheetItemsAction,
  doActionAction,
  downloadLinkExportExcelAction,
  getSheetAction,
  getSheetItemsAction,
  moveItemsAction,
  updateMultipleItemsAction,
  updateSheetAction,
  updateSheetItemAction,
  updateSheetItemsAction,
} from './stores/sheetActions';
import {cloneDeep, get, isEmpty, isEqual, isFunction, isObject, set, values} from 'lodash';
import {getInsertPos, orderGap, sortArrByKey} from 'core/helpers/orderArr';
import {
  hasSheetBoardPermission,
  hasViewSheetColumnPermission,
} from 'app/modules/work/sheet/permission/permissionHelper';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect, useMemo, useState} from 'react';

import FileSaver from 'file-saver';
import {getRangeValue} from 'core/helpers/dateRangeHelpers';
import {newId} from 'app/common/_helpers/idHelpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import {hasEditItemPermission, hasEditItemColumnPermission} from './permission/permissionHelper';
import {getUserSheetPermissionAction} from '../stores/actions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {requestGeoLocation} from 'app/common/_helpers/RequestGeoLocation';

export const useSheetData = ({id, sheet, setSheet, view, user, isRemote = false}) => {
  const sheetId = id;
  const [sheetItems, setSheetItems] = useState([]);
  const [sheetGroups, setSheetGroups] = useState([]);
  const [sheetItemsByParent, setSheetItemsByParent] = useState([]);
  //Raw items, before calculating permissions, use for staging step
  const [fetchingItems, setFetchingItems] = useState(null);
  //Items with pre calculated permissions
  const [sheetItemList, setSheetItemListBase] = useState([]);
  const allUsers = useSelector((state) => state.core.allUsers, shallowEqual);
  const [viewItems, setViewItems] = useState([]);
  const [viewItemsByGroups, setViewItemsByGroups] = useState({});
  const snackNotification = useSnackNotification();

  const [groupByColumn, setGroupByColumn] = useState(null);
  const [viewItemsByColumns, setViewItemsByColumns] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const tableOptions = {
    pageNumber: 1,
    pageSize: 50,
  };
  const [paging, setPagingBase] = useState(tableOptions);

  const [userViewableColumns, setUserViewableColumns] = useState([]);
  const [requestItemIdsToFetch, setRequestItemIdsToFetch] = useState([]);

  const [userSheetPermissions, setUserSheetPermissions] = useState(null);
  const [permissionInitialized, setPermissionInitialized] = useState(false);

  const {t} = useTranslate();

  useEffect(() => {
    const _sheetGroups = [];
    // Some items may be in the deleted group. so we should not get groups from sheet.groups
    const allGroupIds = Object.keys(viewItemsByGroups) || [];
    const emptyGroup = {
      id: 'empty',
      title: t('sheet_empty_group'),
    };

    allGroupIds.forEach((groupId, idxGroup) => {
      let group = sheet?.groups?.find((i) => i?.id === groupId) || emptyGroup;
      _sheetGroups.push(group);
    });

    setSheetGroups(_sheetGroups);
  }, [viewItemsByGroups]);

  useEffect(() => {
    const hasBoardEdit = hasSheetBoardPermission(userSheetPermissions, user, 'board.edit');
    let columns = sheet?.columns || [];

    columns = columns.filter((column) => {
      if (!column?.is_view_permitted || hasBoardEdit) {
        return true;
      }
      return hasViewSheetColumnPermission(user, column);
    });

    setUserViewableColumns(columns);
  }, [sheet, user]);

  const setPaging = useCallback((nextPaging) => {
    setPagingBase((prevPaging) => {
      if (isFunction(nextPaging)) {
        nextPaging = nextPaging(prevPaging);
      }

      if (isEqual(prevPaging, nextPaging)) {
        return prevPaging;
      }

      return nextPaging;
    });
  }, []);

  const [filter, setFilterBase] = useState(view?.rule_filters);
  const setFilter = useCallback((nextFilter) => {
    setFilterBase((prevFilter) => {
      if (isFunction(nextFilter)) {
        nextFilter = nextFilter(prevFilter);
      }
      if (isEqual(prevFilter, nextFilter)) {
        return prevFilter;
      }

      return nextFilter;
    });
  }, []);

  useEffect(() => {
    if (view && view?.rule_filters) {
      setFilter(view?.rule_filters);
    }
  }, [view]);

  const applyFilter = (f, filterValue) => {
    let _newFilters = {...filter};

    let _convertedFilterValue = {...filterValue};

    if (!_newFilters[f.field]) {
      _newFilters[f.field] = {
        ...f,
        value: _convertedFilterValue,
      };
    } else {
      _newFilters[f.field] = {
        ..._newFilters[f.field],
        value: _convertedFilterValue,
      };
    }
    setFilter(_newFilters);
  };

  const filterActive = useMemo(() => {
    let _filterActive = false;
    if (filter) {
      Object.values(filter).forEach((filter) => {
        if (filter?.value?.in?.length > 0) {
          _filterActive = true;
        } else if (filter?.type === 'date' && filter?.value?.start && filter?.value?.end) {
          _filterActive = true;
        }
      });
    }

    return _filterActive;
  }, [filter]);

  const resetFilters = () => {
    setFilter({});
  };

  const applySearch = (search) => {
    setPagingBase({...paging, pageNumber: 1});

    setSearchTerm(search);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      //Fetch permission before fetching data for sheet
      fetchSheet();
      fetchPermission();
    }
  }, [id]);

  const fetchPermission = () => {
    dispatch(getUserSheetPermissionAction({sheetId: sheetId})).then((res) => {
      if (res) {
        setUserSheetPermissions(res);
      }
    });
  };

  const fetchSheet = () => {
    if (setSheet) {
      dispatch(getSheetAction({id: id})).then((result) => {
        if (result?.data?.data) {
          let _sheet = result.data.data;
          let editable = true;
          if (_sheet.master_page && _sheet.master_page._id !== sheetId) {
            editable = false;
          }
          setSheet({..._sheet, editable: editable});
          setSheetGroups(_sheet.groups);
        }
      });
    }
  };

  useEffect(() => {
    //Calculate permissions once sheetItems and permission fetched. This should be run only ince
    if (userSheetPermissions && fetchingItems) {
      //Recalculate item permissions
      setSheetItemList(fetchingItems);
    }
  }, [userSheetPermissions, fetchingItems]);

  const setSheetItemList = (items) => {
    //Calculate item permission to improve performance. Don't have to calculate for each field
    let itemsWithPermissions = items.map((item) => {
      return {
        ...item,
        userHasEditItemPermission: hasEditItemPermission(userSheetPermissions, user, item),
      };
    });
    setSheetItemListBase(itemsWithPermissions);
  };

  //Process and replace filter with current user
  const prepareFilter = useMemo(() => {
    if (filter) {
      let _filters = cloneDeep(filter);
      Object.keys(_filters).forEach((field, value) => {
        if (_filters[field].type === 'people' && _filters[field].value.in?.includes('current')) {
          _filters[field].value.in = _filters[field].value.in.map((inValue) => {
            if (inValue === 'current') {
              return user._id;
            } else {
              return inValue;
            }
          });
        }
        if (_filters[field].type === 'approval') {
          Object.keys(_filters[field].value).forEach((key) => {
            if (key === 'people' && _filters[field].value?.[key]?.in?.includes('current')) {
              _filters[field].value[key].in = _filters[field].value[key].in.map((inValue) => {
                if (inValue === 'current') {
                  return user._id;
                } else {
                  return inValue;
                }
              });
            }
          });
        }
      });
      return _filters;
    } else {
      return {};
    }
  }, [filter]);

  //Use this method to handle some case when handling socket event that would not read state
  //If call fetchSheetItem directly, sheetItemList would be empty
  //Put request into a queue in case many requests together
  const requestFetchSheetItem = (itemId) => {
    setRequestItemIdsToFetch((prev) => [itemId, ...prev]);
  };

  useEffect(() => {
    if (requestItemIdsToFetch?.length > 0) {
      let tempRequestItemIdsToFetch = cloneDeep(requestItemIdsToFetch);
      let itemIdToFetch = tempRequestItemIdsToFetch.pop();
      fetchSheetItem(itemIdToFetch);
    }
  }, [requestItemIdsToFetch]);

  //Fetch single sheet item and update to state
  const fetchSheetItem = (itemId) => {
    const fetchParams = {
      id: sheetId,
      itemIds: [itemId],
    };
    if (searchTerm) {
      fetchParams.q = searchTerm;
    }

    if (view) {
      fetchParams.filter = prepareFilter;
    }

    dispatch(getSheetItemsAction(fetchParams)).then((result) => {
      if (result?.data?.data?.[0]) {
        let returnItem = result.data.data?.[0];

        let tempSheetItemList = cloneDeep(sheetItemList);
        let existing = tempSheetItemList.filter((x) => x._id === itemId);
        if (existing?.length > 0) {
          let _sheetItems = tempSheetItemList.map((item) => {
            return item._id === returnItem._id ? returnItem : item;
          });
          setFetchingItems(_sheetItems);
        } else {
          const updatedSheetItemList = isRemote
            ? [returnItem, ...tempSheetItemList]
            : [...tempSheetItemList, returnItem]; // always add the new item to the end if 'isRemote' is false
          setFetchingItems(updatedSheetItemList);
        }
        const tempRequestItemIdsToFetch = cloneDeep(requestItemIdsToFetch);
        tempRequestItemIdsToFetch.pop();
        setRequestItemIdsToFetch(tempRequestItemIdsToFetch);
      } else {
        // Handle sheet item deleted events
        // When no item found, remove the item from sheetItemList
        setFetchingItems((prev) => prev.filter((sheetItem) => sheetItem?._id !== itemId));
      }
    });
  };

  const fetchSheetItems = useCallback(() => {
    if (isRemote) {
      const fetchParams = {
        id: id,
        paging: {pageSize: paging.pageSize, pageNumber: paging.pageNumber},
      };
      if (searchTerm) {
        fetchParams.q = searchTerm;
      }

      if (view) {
        fetchParams.filter = prepareFilter;
      }

      dispatch(getSheetItemsAction(fetchParams)).then((result) => {
        if (result?.data?.count) {
          setTotalItems(result.data.count);
        }
        if (result?.data?.data) {
          const items = result.data.data;
          setFetchingItems(items);
        }
      });
    } else {
      const fetchParams = {
        id: id,
      };
      dispatch(getSheetItemsAction(fetchParams)).then((result) => {
        if (result?.data?.count) {
          setTotalItems(result.data.count);
        }
        if (result?.data?.data) {
          const items = result.data.data;
          const _itemsByGroup = {};
          items?.forEach((item) => {
            if (item.group) {
              _itemsByGroup[item.group] = _itemsByGroup[item.group]
                ? [..._itemsByGroup[item.group], item]
                : [item];
            }
          });
          setSheetItems(_itemsByGroup);
          setFetchingItems(items);
        }
      });
    }
  }, [paging, id, isRemote, filter, dispatch, searchTerm]);

  useEffect(() => {
    if (sheetItemList) {
      const _itemsByParent = {};
      sheetItemList.forEach((item) => {
        if (item?.parent_item_id) {
          _itemsByParent[item?.parent_item_id] = _itemsByParent[item?.parent_item_id]
            ? [..._itemsByParent[item?.parent_item_id], item]
            : [item];
        }
      });
      setSheetItemsByParent(_itemsByParent);
    }
  }, [sheetItemList]);

  const addItemQuick = async (params, callback) => {
    const {group = 'default', title, parentItemId, user} = params;

    const _items = {...sheetItems};
    const arrPos = sheetItemList.map((item) => item?.pos || orderGap);
    const pos = getInsertPos(arrPos, arrPos.length - 1); // insert to end

    let addItemParams = {
      ...params,
      sheetId: sheetId,
      title: {value: title},
      pos,
    };

    if (parentItemId) {
      addItemParams.parent_item_id = parentItemId;
    }

    const handleItemFieldPromises = [];

    const handleDefaultFieldValue = async (column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(addItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
        if (column.type === 'people') {
          if (column.default === 'current_user') {
            set(addItemParams, column.field, {
              value: [{name: user?.name, id: user?._id, _id: user?._id}],
            });
          }
          if (column.default === 'default_users') {
            set(addItemParams, column?.field, {value: column.default_users});
          }
        }

        if (column.type === 'status') {
          set(addItemParams, column.field, {value: column.default});
        }
      }

      if (column.type === 'geolocation') {
        const geolocation = await requestGeoLocation(t, snackNotification.showError);
        set(addItemParams, column.field, {value: geolocation});
      }
    };

    // populate default value
    sheet.columns.forEach((column) => {
      handleItemFieldPromises.push(handleDefaultFieldValue(column));
    });

    // Allow item creation even if the user denies geolocation permission.
    await Promise.allSettled(handleItemFieldPromises);

    dispatch(addSheetItemAction(addItemParams)).then((result) => {
      if (!_items[group]) {
        _items[group] = [];
      }
      _items[group].push(result.data.data);
      setSheetItems(_items);
      setSheetItemList([...sheetItemList, result.data.data]);
      callback && callback(result.data.data);
    });
  };

  const addItemFull = (item, user, callback) => {
    const _items = cloneDeep(sheetItems);
    const itemGroup = item.group ? item.group : sheetGroups[0]?.id;

    let addItemParams = {
      sheetId: sheetId,
      title: {value: item.title},
      group: itemGroup,
    };

    // populate default value
    sheet.columns.forEach((column) => {
      if (column.default && column.default !== '') {
        if (column.type === 'date') {
          if (column.default === 'today') {
            set(addItemParams, column.field, {value: new Date().getTime() / 1000});
          }
        }
        if (column.type === 'people') {
          if (column.default === 'current_users') {
            set(addItemParams, column.field, {
              value: [{name: user.name, id: user._id, _id: user._id}],
            });
          }
          if (column.default === 'default_users') {
            set(addItemParams, column.field, {value: column.default_users});
          }
        }
        if (column.type === 'status') {
          if (column.default) {
            set(addItemParams, column.field, {value: column.default});
          }
        }
      }
    });

    sheet.columns.forEach((column) => {
      if (item[column.field]) {
        addItemParams[column.field] = {
          value: item[column.field].value ?? null,
          label: item[column.field].label ?? null,
        };
      }
    });

    // if creating a new item via the dialog, should add position to item
    if (!addItemParams?.pos) {
      const currentListItems = values(_items).reduce((acc, current) => acc.concat(current), []);
      const arrPos = currentListItems.map((item) => item?.pos || 0);
      const pos = getInsertPos(arrPos, arrPos?.length - 1);

      addItemParams['pos'] = pos;
    }

    dispatch(addSheetItemAction(addItemParams)).then((result) => {
      if (result?.data?.success) {
        if (!_items[itemGroup]) {
          _items[itemGroup] = [];
        }
        _items[itemGroup].push(result.data.data);
        setSheetItems(_items);
        setSheetItemList([result.data.data, ...sheetItemList]);
      }
      callback && callback(result.data);
    });
  };

  const addItems = (group = 'default', items, callback) => {
    const _newItems = [];
    const arrPos = sheetItemList.map((item) => item?.pos);
    let pos = getInsertPos(arrPos, arrPos.length);
    items.forEach((item) => {
      pos += orderGap;
      _newItems.push({
        title: {value: item},
        group: group,
        pos,
      });
    });

    dispatch(
      addMultipleSheetItemsAction({
        sheetId: sheetId,
        items: _newItems,
      })
    ).then((res) => {
      fetchSheetItems();
      setPaging(tableOptions);
      callback && callback(res);
    });
  };

  const updateItem = (item, field, oldValue, newValue, callback) => {
    let updateItemParams = {
      sheetId: sheetId,
      itemId: item._id,
    };
    let newFieldValue = null;
    let apiProtectedField = false;
    const column = sheet?.columns?.find((column) => column?.field === field);

    if (
      column?.type === 'status' &&
      column?.done_values &&
      column?.done_values?.includes?.(newValue.value)
    ) {
      apiProtectedField = true;
    }

    if (isObject(newValue) && newValue.hasOwnProperty('value')) {
      newFieldValue = newValue;
    } else {
      newFieldValue = {
        value: newValue,
      };
    }

    updateItemParams[field] = newFieldValue;

    let _items = {...sheetItems};
    _items[item.group] = sheetItems[item.group]?.map((it) => {
      if (it._id !== item._id) {
        return it;
      } else {
        let updatedItem = {...it};
        if (field.startsWith('fields.')) {
          let subFieldName = field.replace('fields.', '');
          if (!updatedItem['fields']) {
            updatedItem['fields'] = {};
          }
          updatedItem['fields'][subFieldName] = newFieldValue;
        } else {
          updatedItem[field] = newFieldValue;
          if (apiProtectedField) {
            updatedItem['complete'] = {value: true};
          }
        }
        return updatedItem;
      }
    });
    let _sheetListItems = [...sheetItemList];

    for (var i = 0; i < _sheetListItems.length; i++) {
      if (_sheetListItems[i]._id === item._id) {
        _sheetListItems[i][field] = newFieldValue;
        if (apiProtectedField) {
          _sheetListItems[i]['complete'] = {value: true};
        }
        break;
      }
    }

    setSheetItemList(_sheetListItems);
    setSheetItems(_items);
    dispatch(updateSheetItemAction(updateItemParams)).then((result) => {
      if (isRemote) {
        fetchSheetItems();
      }
      callback && callback(result);
    });
  };

  const updateItems = (itemCheck, field, oldValue, newValue, callback) => {
    let listItemIdUpdate = Object.keys(itemCheck).reduce((listId, groupKey) => {
      return listId.concat(itemCheck[groupKey]);
    }, []);

    const column = sheet?.columns?.find((column) => column.field === field);

    //check permission
    listItemIdUpdate = listItemIdUpdate.filter((item) => {
      const sheetItem = sheetItemList.find((itemInList) => itemInList?._id === item);
      return (
        hasEditItemColumnPermission(user, column, sheetItem) &&
        hasEditItemPermission(userSheetPermissions, user, sheetItem)
      );
    });

    const params = {
      itemIds: listItemIdUpdate,
      sheetId: sheetId,
    };

    let newFieldValue = null;
    if (isObject(newValue) && newValue.hasOwnProperty('value')) {
      newFieldValue = newValue;
    } else {
      newFieldValue = {
        value: newValue,
      };
    }

    params[field] = newFieldValue;
    dispatch(updateSheetItemsAction(params)).then((res) => {
      fetchSheetItems();
      callback && callback(res);
    });
  };

  const doAction = (params, callback) => {
    dispatch(doActionAction(params)).then((res) => {
      // update count of my request in the workspace menu
      callback && callback(res);
    });
  };

  /**
   * @desc add value to multiple items (upgrade from updateItems)
   * @param selectedIds the list id of items will be affected
   * @param field using for array column like: people
   * @param value 1 item of column value like: {id:1,name:'demo',_id:1}
   * @param callback
   */
  const addValueToItems = (selectedIds, field, value, callback) => {
    const selectedItems = sheetItemList.filter((i) => selectedIds.includes(i._id));
    const _selectedItems = cloneDeep(selectedItems);

    let newSheetItems = _selectedItems.map((oldItem) => {
      const item = {_id: oldItem?._id};
      let attrValues = oldItem[field]?.value || [];
      if (isEmpty(attrValues) || !attrValues.find((i) => i?.id === value?.id)) {
        // check duplicate
        attrValues.push(value);
      }
      set(item, `${field}.value`, attrValues);
      return item;
    });

    newSheetItems = filterChangedSheetItems(sheetItemList, newSheetItems, field);
    dispatch(updateMultipleItemsAction(sheetId, {items: newSheetItems})).then((res) => {
      fetchSheetItems();
      callback && callback(res);
    });
  };

  const addValueToItem = (item, field, value, callback) => {
    let newSheetItemList = [...sheetItemList];
    let updateItemParams = {
      sheetId: sheetId,
      itemId: item._id,
    };
    let newValue = item[field]?.value ?? [];
    if (!newValue.find((element) => element?.id === value?.id)) {
      newValue.push(value);
    }
    updateItemParams[field] = {value: newValue};

    for (var i = 0; i < newSheetItemList.length; i++) {
      if (newSheetItemList[i]._id === item._id) {
        newSheetItemList[i][field] = {value: newValue};
        break;
      }
    }
    setSheetItemList(newSheetItemList);

    dispatch(updateSheetItemAction(updateItemParams)).then((result) => {
      if (isRemote) {
        fetchSheetItems();
      }
      callback && callback(result);
    });
  };

  /**
   * @desc remove value to multiple items (upgrade from updateItems)
   * @param selectedIds the list id of items will be affected
   * @param field using for array column like: people
   * @param value 1 item of column value like: {id:1,name:'demo',_id:1}
   * @param callback
   */
  const removeValueInItems = (selectedIds, field, value, callback) => {
    const selectedItems = sheetItemList.filter((i) => selectedIds.includes(i._id));

    let newSheetItems = selectedItems.map((i) => {
      const item = {_id: i?._id};
      let attrValue = i[field]?.value || [];

      attrValue = attrValue.filter((i) => i.id !== value.id);

      set(item, `${field}.value`, attrValue);
      return item;
    });

    newSheetItems = filterChangedSheetItems(sheetItemList, newSheetItems, field);

    dispatch(updateMultipleItemsAction(sheetId, {items: newSheetItems})).then((res) => {
      fetchSheetItems();
      callback && callback(res);
    });
  };

  const removeValueInItem = (item, field, value, callback) => {
    let newSheetItemList = [...sheetItemList];
    let updateItemParams = {
      sheetId: sheetId,
      itemId: item._id,
    };
    let newValue = item[field]?.value ?? [];
    newValue = newValue.filter((element) => element?.id !== value?.id);
    updateItemParams[field] = {value: newValue};

    for (var i = 0; i < newSheetItemList.length; i++) {
      if (newSheetItemList[i]._id === item._id) {
        newSheetItemList[i][field] = {value: newValue};
        break;
      }
    }
    setSheetItemList(newSheetItemList);

    dispatch(updateSheetItemAction(updateItemParams)).then((result) => {
      if (isRemote) {
        fetchSheetItems();
      }
      callback && callback(result);
    });
  };

  const filterChangedSheetItems = (oldItems, newItems, updateField) => {
    return newItems.filter((newItem) => {
      const oldItem = oldItems.find((oldItem) => oldItem._id === newItem._id);
      return !isEqual(oldItem?.[updateField], newItem?.[updateField]);
    });
  };

  const deleteItem = (item, callback) => {
    let deleteItemParams = {
      sheetId: sheetId,
      itemId: item._id,
    };

    dispatch(deleteSheetItemAction(deleteItemParams)).then(() => {
      fetchSheetItems();
      callback && callback();
    });
  };

  const moveItem = (item, currentIndex, toIndex, group, callback) => {
    let newItems = {...viewItemsByGroups};
    if (item.group !== group && group) {
      //Remove item from the source group
      newItems[item.group] = newItems[item.group]?.filter((x) => {
        return x._id !== item._id;
      });
      //Insert item to the target group and also update the item.group
      if (!newItems[group]) {
        newItems[group] = [{...item, group: group}];
      } else {
        if (toIndex) {
          newItems[group].splice(toIndex, 0, {...item, group: group});
        } else {
          newItems[group].push({...item, group: group});
        }
      }
      //Change item group in sheet item list
      let newSheetItemList = cloneDeep(sheetItemList);
      newSheetItemList = newSheetItemList.map((sheetItem) => {
        if (sheetItem?._id === item?._id) return {...sheetItem, group: group};
        return sheetItem;
      });

      setSheetItemList(newSheetItemList);
      setSheetItems(newItems);
      setViewItemsByGroups(newItems);
      let updateItemParams = {
        sheetId: sheetId,
        itemId: item._id,
        group: group,
      };
      dispatch(updateSheetItemAction(updateItemParams)).then(() => callback && callback());
    } else {
      newItems = newItems?.[group] || [...sheetItemList];
      const arrPos = newItems.map((item) => item?.pos || 0);

      const currentIndex = newItems.findIndex((e) => e?._id === item?._id);
      const isMoveDown = toIndex > currentIndex;
      const nextIndex = isMoveDown ? toIndex + 1 : toIndex - 1;

      const pos = getInsertPos(arrPos, toIndex, nextIndex);
      dispatch(
        updateSheetItemAction({
          sheetId: sheetId,
          itemId: item._id,
          pos,
        })
      ).then(() => {
        item.pos = pos;
        handleMoveItem(item);
        callback && callback();
      });
    }
  };

  const handleMoveItem = (newRow) => {
    let _items = cloneDeep(sheetItemList);
    const itemIndex = sheetItemList.findIndex((i) => i._id === newRow._id);
    if (itemIndex !== -1) {
      _items[itemIndex] = newRow;
      _items = sortArrByKey(_items, 'pos');
      setSheetItemList(_items);
    }
  };

  const addGroupWithItems = (groupsAndItems, callback) => {
    //Generate Id
    let _groupAndItemsToCreate = groupsAndItems.map((group) => {
      return {
        id: newId(),
        title: group.title,
        items: group.items,
      };
    });

    let _newGroups = _groupAndItemsToCreate.map((group) => {
      return {
        id: group.id,
        title: group.title,
      };
    });

    const arrPos = sheetItemList.map((item) => item.pos);
    let pos = getInsertPos(arrPos, arrPos.length);

    let _newItems = [];
    _groupAndItemsToCreate.forEach((group) => {
      group.items?.forEach((item) => {
        pos += orderGap;
        _newItems.push({
          title: {value: item.title},
          group: group.id,
          pos,
        });
      });
    });

    const sheetGroups = sheet.groups ? [...sheet.groups, ..._newGroups] : [_newGroups];
    const updateParams = {
      id: sheetId,
      groups: sheetGroups,
    };

    dispatch(updateSheetAction(updateParams)).then((result) => {
      dispatch(
        addMultipleSheetItemsAction({
          sheetId: sheetId,
          items: _newItems,
        })
      ).then(() => {
        fetchSheetItems();
        callback && callback();
      });
    });
  };

  const moveItems = (group, sheet, selectedItems, columnsMapping, callback) => {
    const listItemIdMove = Object.keys(selectedItems).reduce((listId, groupKey) => {
      if (groupKey !== group.id) {
        return listId.concat(selectedItems[groupKey]);
      }
      return listId;
    }, []);

    const params = {
      itemIds: listItemIdMove,
      oldSheetId: sheetId,
      newSheetId: sheet._id,
      groupId: group.id,
      columnsMapping: columnsMapping,
    };

    dispatch(moveItemsAction(params)).then((res) => {
      fetchSheetItems();
      callback && callback();
    });
  };

  const removeItems = (selectedItems, callback) => {
    const listItemIdRemove = Object.keys(selectedItems).reduce((listId, groupKey) => {
      return listId.concat(selectedItems[groupKey]);
    }, []);

    const params = {
      itemIds: listItemIdRemove,
      sheetId: sheetId,
    };

    dispatch(deleteSheetItemsAction(params)).then((res) => {
      fetchSheetItems();
      callback && callback(res);
    });
  };

  const removeSubItems = (selectedItems, callback) => {
    const listItemIdRemove = Object.keys(selectedItems).reduce((listId, groupKey) => {
      return listId.concat(selectedItems[groupKey]);
    }, []);

    const subSheetId = sheet?.sub_boards?.[0];
    if (subSheetId) {
      const params = {
        itemIds: listItemIdRemove,
        sheetId: subSheetId,
      };

      dispatch(deleteSheetItemsAction(params)).then((res) => {
        callback && callback(res);
      });
    }
  };

  const addItemsAndSubItems = (items, callback) => {
    if (sheet.groups?.length > 0) {
      const itemsToAdd = [];
      const firstGroupId = sheet.groups?.[0]?.id;
      const arrPos = sheetItemList.map((item) => item.pos);
      let pos = getInsertPos(arrPos, arrPos.length); //insert to end
      items.forEach((item) => {
        let itemToAdd = cloneDeep(item);
        delete itemToAdd.created_by;
        delete itemToAdd.created_at;
        delete itemToAdd.updated_at;
        delete itemToAdd._id;
        itemToAdd['sheet'] = sheetId;
        itemToAdd['group'] = firstGroupId;
        itemToAdd.subItems = [];
        itemToAdd.pos = pos;
        pos = pos * 2;
        if (item.subItems) {
          let subItemsToAdd = cloneDeep(item.subItems);
          subItemsToAdd.forEach((subItemsToAdd) => {
            delete subItemsToAdd.created_by;
            delete subItemsToAdd.created_at;
            delete subItemsToAdd.updated_at;
            delete subItemsToAdd._id;
            itemToAdd.subItems.push(subItemsToAdd);
          });
        }
        itemsToAdd.push(itemToAdd);
      });
      const params = {
        items: itemsToAdd,
      };
      dispatch(addItemsAndSubItemsAction(sheetId, params)).then((res) => {
        if (res.data?.success) {
          fetchSheetItems();
          callback && callback({success: res.data.success});
        }
      });
    } else {
      callback({error: 'no_group'});
    }
  };

  const getGroupFilterValues = useCallback(() => {
    let groupFilterValues = filter?.group?.value?.in;
    let newGroupFilterValues = [];
    if (!isEmpty(groupFilterValues)) {
      groupFilterValues.forEach((filterValue) => {
        let value = filterValue === '$first' ? sheet?.groups[0]?.id : filterValue;
        if (!newGroupFilterValues.includes(value)) {
          newGroupFilterValues.push(value);
        }
      });
    }
    return newGroupFilterValues;
  }, [filter?.group, sheet?.groups]);

  useEffect(() => {
    if (sheetItemList) {
      let _viewItems = [];
      let _viewItemsByGroups = {};
      const sheetGroups = sheet?.groups || [];

      if (sheet && sheet?.groups) {
        sheetGroups.forEach((group) => (_viewItemsByGroups[group?.id] = []));
      }

      let _searchTermLowercase = searchTerm?.toLowerCase() || '';
      if (!isRemote) {
        _viewItems = [];

        const approvalFilter = (activeFilter, item) => {
          let passFilter = true;
          Object.keys(activeFilter.value).forEach((key) => {
            let itemColumnValue = get(item, activeFilter.field);

            if (key === 'status' && activeFilter?.value?.[key]?.in?.length) {
              const currentUserIds = itemColumnValue?.approval?.map((user) => user?.id);
              if (
                itemColumnValue?.status === 'pending' &&
                activeFilter.value[key].in.includes('approve') &&
                currentUserIds.includes(user?._id)
              ) {
                //Pass
              } else if (
                itemColumnValue?.status === 'pending' &&
                activeFilter.value[key].in.includes('pending') &&
                itemColumnValue?.acceptedDate
              ) {
                //Pass
              } else {
                if (
                  !activeFilter.value[key].in.includes('pending') &&
                  activeFilter.value[key].in.includes(itemColumnValue?.status)
                ) {
                  //Pass
                } else {
                  passFilter = false;
                }
              }
            }
            if (key === 'people') {
              const listPeople = itemColumnValue?.value?.flatMap((step) => step.assignees || []);
              const effectiveFilterIn = activeFilter.value[key].in.map((i) => {
                if (i === 'current') {
                  return user._id;
                } else {
                  return i;
                }
              });

              const userInItem = listPeople?.find((person) =>
                effectiveFilterIn.includes(person._id || person.id)
              );

              if (activeFilter.value[key].in?.length === 0 || userInItem) {
                //Pass
              } else {
                passFilter = false;
              }
            }
            if (key === 'step') {
              const listStepsId = itemColumnValue?.value?.map((step) => step.id);

              const columnApproval = sheet?.columns?.find(
                (column) => column.field === activeFilter.field
              );
              const firstStepId = columnApproval?.steps?.[0]?.id;

              if (
                (!listStepsId && activeFilter?.value?.[key]?.in?.includes(firstStepId)) ||
                activeFilter?.value?.[key]?.in?.length === 0
              ) {
                //Pass
              } else {
                const hasStepId = listStepsId?.some((stepId) =>
                  activeFilter?.value?.[key]?.in?.includes(stepId)
                );
                if (hasStepId) {
                  //Pass
                } else {
                  passFilter = false;
                }
              }
            }
          });

          return passFilter;
        };

        sheetItemList.forEach((item) => {
          let passFilter = true;

          if (_searchTermLowercase !== '') {
            if (item.title?.hasOwnProperty('value')) {
              if (!item.title.value.toLowerCase().includes(_searchTermLowercase)) {
                passFilter = false;
              }
            } else if (item?.title) {
              if (!item.title.toLowerCase().includes(_searchTermLowercase)) {
                passFilter = false;
              }
            }
          }

          if (filter) {
            Object.values(filter).forEach((activeFilter) => {
              if (activeFilter.value?.in?.length > 0) {
                if (activeFilter.field === 'complete') {
                  let itemColumnValue = get(item, activeFilter.field);
                  if (activeFilter.value.in.includes(itemColumnValue?.value)) {
                  } else if (
                    itemColumnValue?.value === undefined &&
                    activeFilter.value.in.includes(false)
                  ) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.type === 'status' || activeFilter.type === 'color') {
                  let itemColumnValue = get(item, activeFilter.field);
                  if (activeFilter.value.in.includes(itemColumnValue?.value || '')) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.type === 'people') {
                  let itemColumnValue = get(item, activeFilter.field);
                  let effectiveFilterIn = activeFilter.value.in.map((i) => {
                    if (i === 'current') {
                      return user._id;
                    } else {
                      return i;
                    }
                  });
                  let itemColumnValueMatchs = itemColumnValue?.value?.filter((i) => {
                    if (i.iid && !i.id) {
                      const user = allUsers?.find((user) => user.iid === i.iid);
                      return effectiveFilterIn?.includes(user._id);
                    }
                    return effectiveFilterIn.includes(i._id);
                  });
                  if (itemColumnValueMatchs?.length > 0) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.type === 'department') {
                  let itemColumnValue = get(item, activeFilter.field);
                  let effectiveFilterIn = activeFilter.value.in.map((i) => {
                    return i;
                  });
                  let itemColumnValueMatchs = itemColumnValue?.value?.filter((i) => {
                    return effectiveFilterIn.includes(i._id);
                  });
                  if (itemColumnValueMatchs?.length > 0) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.type === 'date') {
                  let itemColumnValue = get(item, activeFilter.field);
                  let activeFilterValue = activeFilter?.value?.in?.map((item, index) => {
                    return getRangeValue(
                      item,
                      activeFilter?.value?.type,
                      activeFilter?.value?.days
                    );
                  });
                  let hasValidValue = activeFilterValue?.find(
                    (item) =>
                      itemColumnValue?.value >= item?.startDate &&
                      itemColumnValue?.value <= item?.endDate
                  );
                  //support old data type
                  let hasValidValueInOldType = activeFilterValue?.find(
                    (item) => itemColumnValue >= item?.startDate && itemColumnValue <= item?.endDate
                  );
                  if (hasValidValue || hasValidValueInOldType) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.field === 'group') {
                  let itemColumnValue = get(item, activeFilter.field);
                  let groupFilterValues = getGroupFilterValues();
                  if (groupFilterValues.includes(itemColumnValue || '')) {
                    //Pass
                  } else if (
                    groupFilterValues.includes('empty') &&
                    !sheetGroups.includes(itemColumnValue)
                  ) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                } else if (activeFilter.type === 'reference') {
                  let itemColumnValue = get(item, activeFilter.field);
                  let effectiveFilterIn = activeFilter.value.in.map((i) => {
                    return i;
                  });
                  if (effectiveFilterIn.includes(itemColumnValue?.value)) {
                    //Pass
                  } else {
                    passFilter = false;
                  }
                }
              }
              if (activeFilter.type === 'approval') {
                const passFilterApproval = approvalFilter(activeFilter, item);
                if (passFilterApproval) {
                  //Pass
                } else {
                  passFilter = false;
                }
              }
            });
          }
          if (passFilter) {
            _viewItems.push(item);
          }
        });
      } else {
        _viewItems = [...sheetItemList];
      }

      sheetGroups.forEach((group) => {
        const itemInGroup = _viewItems.filter((i) => i?.group === group?.id);
        _viewItemsByGroups[group?.id] = itemInGroup;
      });

      const groupIds = sheetGroups.map((i) => i?.id) || [];
      _viewItems.forEach((viewItem) => {
        if (viewItem) {
          if (!groupIds.includes(viewItem?.group)) {
            if (_viewItemsByGroups['empty']) {
              _viewItemsByGroups['empty'].push(viewItem);
            } else {
              _viewItemsByGroups['empty'] = [viewItem];
            }
          }
        }
      });

      if (filter && filter?.group) {
        let groupFilterValues = getGroupFilterValues();
        if (groupFilterValues && !isEmpty(groupFilterValues)) {
          Object.keys(_viewItemsByGroups).forEach((key) => {
            if (!groupFilterValues.includes(key)) {
              delete _viewItemsByGroups[key];
            }
          });
        }
      }
      setViewItems(_viewItems);
      setViewItemsByGroups(_viewItemsByGroups);
    }
  }, [sheetItemList, filter, searchTerm, sheet, sheet?.groups]);

  useEffect(() => {
    if (isRemote && searchTerm) {
      fetchSheetItems();
    }
  }, [searchTerm]);

  useEffect(() => {
    let _itemsByGroupByColumnValue = {'': []};
    if (groupByColumn && viewItems) {
      if (groupByColumn) {
        for (var i = 0; i < groupByColumn.values?.length; i++) {
          _itemsByGroupByColumnValue[groupByColumn.values[i].value] = [];
        }
      }

      for (var j = 0; j < viewItems.length; j++) {
        let itemKanbanValue =
          get(viewItems[j], groupByColumn.field)?.value || get(viewItems[j], groupByColumn.field);
        if (itemKanbanValue && _itemsByGroupByColumnValue[itemKanbanValue]) {
          _itemsByGroupByColumnValue[itemKanbanValue].push(viewItems[j]);
        } else if (
          get(viewItems[j], groupByColumn.field) === null ||
          get(viewItems[j], groupByColumn.field)?.value === null ||
          get(viewItems[j], groupByColumn.field)?.value === '' ||
          get(viewItems[j], groupByColumn.field) === undefined ||
          get(viewItems[j], groupByColumn.field)?.value === undefined
        ) {
          _itemsByGroupByColumnValue[''].push(viewItems[j]);
        }
      }
    }
    setViewItemsByColumns(_itemsByGroupByColumnValue);
  }, [viewItems, groupByColumn]);

  const exportExcel = () => {
    let params = {
      filter: filter,
    };
    if (view?.type === 'table-paging' || view?.type === 'table-paging-with-subitems') {
      //has paging key with no pageSize and pageNumber -> get all and sort by created_at
      params.paging = {};
    }
    dispatch(downloadLinkExportExcelAction(sheetId, params)).then((res) => {
      if (res?.data?.link) {
        FileSaver.saveAs(res?.data?.link);
      }
    });
  };

  const tools = {
    doAction: (action, options) => {
      const {listCopyItem, callback} = options;
      const params = {
        items: listCopyItem,
      };
      switch (action) {
        case 'copyColumn':
          params.items = params?.items.filter((item) => {
            // must using sheetItem full column,  updateItem data not contain people columns => can not check permission
            const sheetItem = sheetItemList.find((itemInList) => itemInList?._id === item?._id);
            return hasEditItemPermission(userSheetPermissions, user, sheetItem);
          });

          dispatch(copyColumnAction(sheetId, params)).then((res) => {
            fetchSheetItems();
            callback && callback();
          });
          break;
        default:
          break;
      }
    },
  };

  return {
    sheet,
    fetchSheet,
    requestFetchSheetItem,
    fetchSheetItems,
    sheetItems,
    sheetItemList,
    sheetItemsByParent,
    addItemQuick,
    addItemFull,
    addItems,
    updateItem,
    updateItems,
    doAction,
    addValueToItems,
    removeValueInItems,
    deleteItem,
    moveItem,
    moveItems,
    removeItems,
    removeSubItems,
    exportExcel,
    addGroupWithItems,
    paging,
    setPaging,
    filter,
    setFilter,
    applyFilter,
    resetFilters,
    activeFilters: filter,
    filterActive,
    applySearch,
    tools,
    totalItems,
    viewItems,
    viewItemsByGroups,
    setGroupByColumn,
    sheetGroups,
    viewItemsByColumns,
    addItemsAndSubItems,
    userViewableColumns,
    addValueToItem,
    removeValueInItem,
    setSheetItemList,
  };
};
